import React from 'react';
import s from "../LegalAndPrivacy.module.css";
import {brandConfig} from '../../../../config/config';

const ThirdPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_text}>
                Last updated: May 25, 2023
            </div>

            <div className={s.point_text}>
                {brandConfig.companyName}. (“us”, “we”, or “our”, “Company”), а legal entity established under the laws of Canada,
                with its registered office at 4 Robert Speck Parkway, Mississauga ON L4Z 1S1, corporation number:
                1474250-8 takes all possible efforts to secure your personal data and preserve its confidentiality.
            </div>

            <div className={s.point_text}>
                By this Privacy Policy (“Policy”) we want to inform you concerning the rights you have when access
                {brandConfig.companyWebsite}/ (“Website”) or use the Services, regarding the manner in which you provide us
                the consent for your personal data procession as well as particular purposes of procession.
            </div>

            <div className={s.point_text}>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy.
            </div>

            <div className={s.point_title}>
                1. General Considerations
            </div>

            <div className={s.point_text}>
                This Policy is developed in order to provide you details regarding the types of personal data that we
                collect, store or process; the purposes of such operations and other relevant matters that arise when
                you use the Services and interact with us and our affiliates as well as when using this Website.
            </div>

            <div className={s.point_text}>
                <b>
                    By continuing to use the Website or Services, you agreed with this Policy and allowed us to collect,
                    store and process your personal data in accordance with this Policy for all purposes defined herein.
                    If you do not agree with this Policy in general or with any part of it, you should not access the
                    Website or use the Services.
                </b>
            </div>

            <div className={s.point_text}>
                <b>
                    You should be aware regarding the following important matters:
                </b>
                <ul>
                    <li>
                        what personal data we collect, store and process;
                    </li>
                    <li>
                        how you can make your impact on operations we carry out with your personal data;
                    </li>
                    <li>
                        in what manner you can enjoy your rights provided hereunder or by the applicable legislation;
                    </li>
                    <li>
                        the steps we take to ensure your personal data is handled in a safe and secure manner;
                    </li>
                    <li>
                        how you can access the personal information we retain and how to keep it accurate;
                    </li>
                    <li>
                        how to contact us with any privacy concerns.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                <div className={s.underline}>
                    Please read this Policy carefully.
                </div>
            </div>

            <div className={s.point_title}>
                2. Interpretation
            </div>

            <div className={s.point_text}>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </div>

            <div className={s.point_title}>
                3. Definitions
            </div>

            <div className={s.point_text}>
                For the purposes of this Privacy Policy: <br/><br/>
                <b>Account</b> means a unique account created for you to access our Service or parts of our
                Service. <br/><br/>
                <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a
                party, where
                "control" means ownership of 25% or more of the shares, equity interest or other securities entitled to
                vote for election of directors or other managing authority. <br/><br/>
                <b>Cookies</b> are small files that are placed on your computer, mobile device or any other device by a
                website, containing the details of your browsing history on that website among its many uses. <br/><br/>
                <b>Country</b> refers to: Ontario, Canada <br/><br/>
                <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital
                tablet. <br/><br/>
                <b>Personal Data</b> is any information that relates to an identified or identifiable
                individual. <br/><br/>
                <b>Service</b> refers to the Website. <br/><br/>
                <b>Service Provider</b> means any natural or legal person who processes the data on behalf of the
                Company. It
                refers to third-party companies or individuals employed by the Company to facilitate the Service, to
                provide the Service on behalf of the Company, to perform services related to the Service or to assist
                the Company in analyzing how the Service is used. <br/><br/>
                <b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or
                from
                the Service infrastructure itself (for example, the duration of a page visit).
            </div>

            <div className={s.point_title}>
                4. Personal Data That We Collect
            </div>

            <div className={s.point_text}>
                While using our Service or Website, we may ask you to provide us with certain personally identifiable
                information that can be used to contact or identify you. Personally identifiable information may
                include, but is not limited to: <br/><br/>
                First name and last name <br/>
                Phone number and other communication information <br/>
                Address, State, Province, ZIP/Postal code, City <br/>
                Usage Data <br/>
                Information about your financial status including your employment information and history, references,
                annual income, assets, liabilities, and credit history <br/>
                Business name, business address, business telephone number, business email address, name(s) of owner(s),
                officer(s) and director(s); industry type and financial status (for business clients) <br/>
                Other information about you that you give us by filling in forms on the Websites or necessary by default
                to use Services.
            </div>

            <div className={s.point_text}>
                Personal data may be collected from you directly, from your interactions with us, and from other sources
                outside our organization, including from government agencies and registries, law enforcement
                authorities, public records, credit reporting agencies, other financial institutions, service providers,
                partners or third parties, agents and other organizations with whom you have arrangements or in
                accordance with their respective terms and conditions and/or privacy policies, or any other source, with
                your consent or as permitted or required by law.
            </div>

            <div className={s.point_text}>
                This information may be obtained by telephone, in writing or electronically.
            </div>

            <div className={s.point_text}>
                You authorize the collection of personal data from these sources, and where applicable, you authorize
                these sources to provide us your personal data.
            </div>

            <div className={s.point_text}>
                Usage Data is collected automatically when using the Service.
            </div>

            <div className={s.point_text}>
                Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that you visit, the time and date of your visit,
                the time spent on those pages, unique device identifiers and other diagnostic data.
            </div>

            <div className={s.point_text}>
                When you access the Service by or through a mobile device, we may collect certain information
                automatically, including, but not limited to, the type of mobile device you use, your mobile device
                unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other diagnostic data.
            </div>

            <div className={s.point_text}>
                We may also collect information that your browser sends whenever you visit our Service or when you
                access the Service by or through a mobile device.
            </div>

            <div className={s.point_title}>
                5. Tracking Technologies and Cookies
            </div>

            <div className={s.point_text}>
                We use Cookies and similar tracking technologies to track the activity on our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information
                and to improve and analyze our Service. The technologies we use may include:
                <ul>
                    <li>
                        <b>Cookies or Browser Cookies</b>. A cookie is a small file placed on your Device. You can
                        instruct
                        your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you
                        do not accept Cookies, you may not be able to use some parts of our Service. Unless you have
                        adjusted your browser setting so that it will refuse Cookies, our Service may use Cookies.
                    </li>
                    <li>
                        <b>Web Beacons</b>. Certain sections of our Service and our emails may contain small electronic
                        files
                        known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                        permit the Company, for example, to count users who have visited those pages or opened an email
                        and for other related website statistics (for example, recording the popularity of a certain
                        section and verifying system and server integrity).
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                We use Cookies to provide you with services available through the Website and to enable you to use some
                of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without
                the Cookies, the services that you have asked for cannot be provided. Also, we use Functionality
                Cookies. These Cookies allow us to remember choices you make when you use the Website, such as
                remembering your login details or language preference. The purpose of these Cookies is to provide you
                with a more personal experience and to avoid you having to re-enter your preferences every time you use
                the Website.
            </div>

            <div className={s.point_text}>
                6. Use of Your Personal Data
            </div>

            <div className={s.point_text}>
                We use your personal data in order to:
                to identify you <br/>
                to provide you with the full scope of information regarding us as well as concerning our Website
                operation <br/>
                to provide and maintain our Service, including to monitor the usage of our Service <br/>
                to manage your Account <br/>
                to market a product or service, either directly or through a service provider, partner or third
                party <br/>
                to perform a contract with us <br/>
                to contact you <br/>
                to send you notifications concerning Services <br/>
                to provide you with news, special offers and general information about other goods, services and events
                which we offer that are similar to those that you have already purchased or enquired about unless you
                have opted not to receive such information <br/>
                to manage your requests: to attend and manage your requests to us <br/>
                to administer, improve our Website by data analysis, testing, statistical and research operations as
                well as developing new options <br/>
                to ensure that our Website content is presented in the most effective manner; <br/>
                to provide you with all of our Website functionality and ensure its security <br/>
                to fulfill our obligations under the applicable legislation <br/>
                to perform any obligatory actions that may be required by any applicable law or any authorized state
                body <br/>
                to detect, investigate and prevent unauthorized activities and fraudulent transactions.
            </div>

            <div className={s.point_text}>
                Some information such as IP-address and other technical data does not reveal your specific identity.
                Such information is needed to maintain the security and operation of our Website and Services and may be
                collected automatically. Apart from this, we collect and store your personal data in secured registers.
                Please note that we do not amend, disclose or transfer your personal data.
            </div>

            <div className={s.point_title}>
                7. How We Store Your Personal Data
            </div>

            <div className={s.point_text}>
                We will store your personal data within the period of your use of the Services or the Website and up to
                your request to erase it, unless we have an obligation to save and keep it according to the applicable
                legislation. We will keep some of your personal data despite the demand to erase it or restrict its
                processing if it’s requirement by the applicable law or necessary for the performing our legal duties or
                submission, pursuing, or defending a claim.
            </div>

            <div className={s.point_text}>
                We perform required measures to ensure that your information is stored and processed securely in line
                with this Policy and the applicable legislation.
            </div>

            <div className={s.point_title}>
                8. Sharing Your Personal Data
            </div>

            <div className={s.point_text}>
                We may share Your personal information in the following situations:
                <ul>
                    <li>
                        <b>With Service Providers</b>: We may share your personal information with Service Providers to
                        monitor and analyze the use of our Service, to contact you. You acknowledge that some of our
                        Service Providers may be located outside of Canada and as such your personal data may be
                        accessible to governmental, national security and regulatory authorities in accordance with the
                        laws of these jurisdictions.
                    </li>
                    <li>
                        <b>For business transfers</b>: We may share or transfer your personal information in connection
                        with,
                        or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all
                        or a portion of our business to another company.
                    </li>
                    <li>
                        <b>With Affiliates</b>: We may share your information with our affiliates, in which case we will
                        require those affiliates to honor this Privacy Policy. Affiliates include our parent company and
                        any other subsidiaries, joint venture partners or other companies that we control or that are
                        under common control with us.
                    </li>
                    <li>
                        <b>With business partners</b>: We may share your information with our business partners to offer
                        you
                        certain products, services or promotions.
                    </li>
                    <li>
                        <b>With your consent</b>: We may disclose your personal information for any other purpose with
                        your
                        consent.
                    </li>
                    <li>
                        <b>With our employees</b>: We may share your data with our employees, agents or professional
                        consultants.
                    </li>
                    <li>
                        <b>With financial institutions</b>: We may share your data with financial institutions to
                        process
                        payments or to verify information such as your identity and income and with payment card
                        networks to operate or administer the payment card system that supports the products, services
                        or accounts you may have with us.
                    </li>
                    <li>
                        <b>With authorities</b>: We may share your data in response to a judicial or administrative
                        orders,
                        subpoena, warrant or other demand or request we believe to be valid from governments,
                        regulators, courts and law enforcement authorities in Canada or other jurisdictions or countries
                        as well as to meet requests for information from regulators.
                    </li>
                    <li>
                        <b>To apply with applicable law</b>: We may share Your data to satisfy legal, audit, insurance,
                        self-regulatory and regulatory requirements applicable to us.
                    </li>
                    <li>
                        <b>
                            To prevent, detect or suppress financial abuse, fraud, money laundering, cyber threats,
                            criminal activity, protect our assets and interests, assist us with any internal or external
                            investigation into potentially illegal or suspicious activity or manage, defend or settle
                            any actual or potential loss.
                        </b>
                    </li>
                </ul>
            </div>

            <div className={s.point_title}>
                9. Transfer of Your Personal Data
            </div>

            <div className={s.point_text}>
                Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to — and maintained on — computers located outside of your state, province, country
                or other governmental jurisdiction where the data protection laws may differ than those from your
                jurisdiction.
            </div>

            <div className={s.point_text}>
                Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to that transfer.
            </div>

            <div className={s.point_text}>
                The Company will take all steps reasonably necessary to ensure that your data is treated securely and in
                accordance with this Privacy Policy and no transfer of your personal data will take place to an
                organization or a country unless there are adequate controls in place including the security of your
                data and other personal information.
            </div>

            <div className={s.point_title}>
                10. Your Rights
            </div>

            <div className={s.point_text}>
                With regard to the scope of operations indicated in this Policy, you have the following rights:
                <ul>
                    <li>
                        to access your personal data
                    </li>
                    <li>
                        to restrict the processing of personal data if (і) the processing of your personal data is
                        unlawful; (іі) we do not need longer your personal data to process it
                    </li>
                    <li>
                        to get a copy of your personal data and receive your personal data in a machine-readable format
                    </li>
                    <li>
                        to object the unlawful use of your personal data
                    </li>
                    <li>
                        to withdraw your consent
                    </li>
                    <li>
                        to correct your personal data
                    </li>
                    <li>
                        other rights in accordance with applicable law
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                You are responsible for advising us of any inaccuracies or changes to your personal data (such as a
                change of address, telephone numbers, current address, marital status, etc.). If any of your personal
                data changes or you discover inaccuracies in our data, you are required to advise Us so we can update
                our records. Please be prepared to verify your identity when updating or changing your personal data.
            </div>

            <div className={s.point_text}>
                Subject to contractual and legal restrictions as well as reasonable notice, you may request access to,
                correction or deletion of the personal data we hold about you at any time by submitting a written
                request to the Chief compliance Officer. Please be prepared to verify your identity upon making such a
                request. We will respond to your request within 30 days of receiving all necessary information or advise
                you if we require additional time.
            </div>

            <div className={s.point_text}>
                Please note that we may not be able to provide you access to your personal data in certain situations
                (e.g., if disclosing the information would reveal confidential commercial information or information
                about a third party). If this is the case, we will inform you of the reasons that we were unable to
                provide you access.
            </div>

            <div className={s.point_text}>
                Subject to legal, regulatory, and contractual requirements, you can refuse or withdraw your consent to
                our collection, use or disclosure of your personal data at any time by giving us reasonable notice. In
                certain circumstances, your consent cannot be withdrawn. For example, you may not withdraw your consent
                where our collection, use and sharing are permitted or required by law, is required to ensure we have to
                correct and up-to-date information about you, such as current address or is necessary to manage our
                business including the sharing of information when we assign our rights to others for business
                transactions.
            </div>

            <div className={s.point_text}>
                Also note that we may need to retain certain information when we have a legal obligation or lawful basis
                to do so.
            </div>

            <div className={s.point_title}>
                11. Disclosure of Your Personal Data
            </div>

            <div className={s.point_text}>
                Business Transactions
            </div>

            <div className={s.point_text}>
                If the Company is involved in a merger, acquisition or asset sale, your personal data may be
                transferred. We will provide notice before your personal data is transferred and becomes subject to a
                different Privacy Policy.
            </div>

            <div className={s.point_text}>
                Law enforcement
            </div>

            <div className={s.point_text}>
                Under certain circumstances, the Company may be required to disclose your personal data if required to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).
            </div>

            <div className={s.point_text}>
                Other legal requirements
            </div>

            <div className={s.point_text}>
                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
                <ul>
                    <li>
                        Comply with a legal obligation
                    </li>
                    <li>
                        Protect and defend the rights or property of the Company
                    </li>
                    <li>
                        Prevent or investigate possible wrongdoing in connection with the Service
                    </li>
                    <li>
                        Protect the personal safety of users of the Service or the public
                    </li>
                    <li>
                        Protect against legal liability
                    </li>
                </ul>
            </div>

            <div className={s.point_title}>
                12. Security of Your Personal Data
            </div>

            <div className={s.point_text}>
                We have implemented administrative, technological, and physical safeguards to protect the security of
                your personal data under our control, regardless of the format in which it is held, against unauthorized
                access, disclosure, use or modification.
            </div>

            <div className={s.point_text}>
                We train our staff on the importance of protecting the confidentiality of your information, and
                regularly confirm this expectation in writing. We authorize staff to have access to your personal data
                only on a need-to-know basis to perform their job functions.
            </div>

            <div className={s.point_text}>
                To the extent that a service provider requires access to your personal data for an authorized purpose,
                we require the service provider to implement similar safeguards to protect the security of your personal
                data.
            </div>

            <div className={s.point_text}>
                We audit our procedures and security measures regularly to help ensure that they are being properly
                administered and that they remain effective and appropriate to the sensitivity of the information.
            </div>

            <div className={s.point_text}>
                The security of your personal data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
                acceptable means to protect your personal data, we cannot guarantee its absolute security.
            </div>

            <div className={s.point_title}>
                13. Children's Privacy
            </div>

            <div className={s.point_text}>
                Our Service does not address anyone under the age of 18. We do not knowingly collect personally
                identifiable information from anyone under the age of 18. If you are a parent or guardian and you are
                aware that your child has provided us with personal data, please contact us. If We become aware that we
                have collected personal data from anyone under the age of 18 without verification of parental consent,
                we take steps to remove that information from our servers.
            </div>

            <div className={s.point_title}>
                14. Links to Other Websites
            </div>

            <div className={s.point_text}>
                Our Service may contain links to other websites that are not operated by us. If you click on a
                third-party link, you will be directed to that third party's site. We strongly advise you to review the
                Privacy Policy of every site you visit.
            </div>

            <div className={s.point_text}>
                We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third-party sites or services.
            </div>

            <div className={s.point_text}>
                Please be aware that your personal data may be disclosed upon request of the competent authority
                (public, government, regulatory, judicial, and law enforcement) in a strict compliance with the
                procedure foreseen by the applicable legislation.
            </div>

            <div className={s.point_title}>
                15. Changes to this Privacy Policy
            </div>

            <div className={s.point_text}>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page.
            </div>

            <div className={s.point_text}>
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.
            </div>

            <div className={s.point_text}>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
            </div>

            <div className={s.point_title}>
                16.	Contact Us
            </div>

            <div className={s.point_text}>
                If you have any questions about this Privacy Policy, you can send written request or contact us by e-mail:
            </div>

            <div className={s.point_text}>
                Chief compliance Officer <br/>
                {brandConfig.companyName} <br/>
                4 Robert Speck Parkway Mississauga ON L4Z 1S1 <br/>
                Telephone: 437-370-4397 <br/>
                Email: {brandConfig.companyMainEmailAddress}
            </div>

        </div>
    );
};

export default ThirdPanel;
