import {createSlice} from "@reduxjs/toolkit";

type CardTransactionState = {
    cardTransactionsData: Array<ICardTransaction>,
    filters: IFilters
}

export interface IFilters {
    period?: string | null,
    currency: string,
}

export interface ICardTransaction {
    amount: number,
    date: string,
    currency: string,
    description: string | null,
}

const initialState: CardTransactionState = {
    cardTransactionsData: [],
    filters: {
        period: null, currency: '',
    },
}


export const cardTransactionsSlice = createSlice({
    name: 'cardTransactions',
    initialState,
    reducers: {
        setCardTransactionsData(state, action) {
            state.cardTransactionsData = action.payload
        }, setFilters(state, action) {
            state.filters = action.payload
        }, clearCardTransactionSlice(state) {
            state.cardTransactionsData = []
            state.filters = {
                period: null, currency: ''
            }
        }
    }
})

export const {setCardTransactionsData, setFilters} = cardTransactionsSlice.actions;

export default cardTransactionsSlice.reducer
