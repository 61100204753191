import React from 'react';
import {Collapse} from "antd";
import s from "./LegalAndPrivacy.module.css";
import styles from "../../../styles/styles.module.css";
import minus from "../../../assets/newImg/publicPages/faq/minus.svg";
import plus from "../../../assets/newImg/publicPages/faq/plus.svg";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import {motion} from "framer-motion";
import FirstPanel from "./FirstPanel/FirstPanel";
import SecondPanel from "./SecondPannel/SecondPanel";
import ThirdPanel from "./ThirdPanel/ThirdPanel";
import FourthPanel from "./FourthPanel/FourthPanel";
import FifthPanel from "./FifthPanel/FifthPanel";
import SixthPanel from "./SixthPanel/SixthPanel";
import SeventhPanel from "./SeventhPanel/SeventhPanel";
import EighthPannel from "./EighthPannel/EighthPannel";

const {Panel} = Collapse;

const LegalAndPrivacy = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>


                    <div className={s.top_block}>
                        <div className={s.content}>
                            <div className={s.title}>
                                Legal & Privacy
                            </div>

                            <div className={s.description}>
                                Please read these policies carefully in their entirety before using the website or any
                                service offered therein. By using the website or otherwise accessing or using our services,
                                you agree to the Policies and Procedures mentioned below. If you do not accept them or
                                any provision herein, do not access the site or use the services.
                            </div>

                            <div className={s.collapse_block}>
                                <Collapse
                                    className={s.my_collapse}
                                    expandIconPosition={"end"}
                                    bordered={false}
                                    expandIcon={({isActive}) => isActive ?
                                        <img className={s.icon_minus} src={minus} alt=""/> :
                                        <img src={plus} className={s.icon_plus} alt=""/>}
                                    style={{
                                        background: "transparent",
                                        alignItems: 'center',
                                    }}
                                >
                                    <Panel header={"Anti Money Laundering & Sanctions Policy"} className={s.panelStyle}
                                           key={"1"}>
                                        <FirstPanel/>
                                    </Panel>

                                    <Panel header={"Customer Complaint Handling Procedures"} className={s.panelStyle}
                                           key={"2"}>
                                        <SecondPanel/>
                                    </Panel>

                                    <Panel header={"Privacy and security of personal information policy"}
                                           className={s.panelStyle}
                                           key={"3"}>
                                        <ThirdPanel/>
                                    </Panel>

                                    <Panel header={"New Client Onboarding and Know Your Client (KYC) Refresh Process"}
                                           className={s.panelStyle}
                                           key={"4"}>
                                        <FourthPanel/>
                                    </Panel>

                                    <Panel header={"Compliance Management Framework"} className={s.panelStyle}
                                           key={"5"}>
                                        <FifthPanel/>
                                    </Panel>

                                    <Panel header={"CASL Policy"} className={s.panelStyle}
                                           key={"6"}>
                                        <SixthPanel/>
                                    </Panel>

                                    <Panel header={"Website Terms of Service"} className={s.panelStyle}
                                           key={"7"}>
                                        <SeventhPanel/>
                                    </Panel>

                                    <Panel header={"Merchant Acceptance Policy"} className={s.panelStyle}
                                           key={"8"}>
                                        <EighthPannel/>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>

                    <div className={s.contact_us_form_wrapper}>
                        <ContactUsForm/>
                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default LegalAndPrivacy;
