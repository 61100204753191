import React, {useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import s from '../../../../styles/styles.module.css'
import KycPersonal from "./KycPersonal/KycPersonal";
import {motion} from 'framer-motion';
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import {useAppSelector} from "../../../../hooks/redux";
import {useNavigate} from "react-router-dom";

const Settings = () => {

    const user = useAppSelector(state => state.userReducer.user)
    const navigate = useNavigate()

    useEffect(() => {
        if (user?.userData?.accountType?.includes('personal')) {
            return
        }
        navigate("/personal_account/business/settings")
    },[navigate, user?.userData?.accountType])

    return (
        <div style={{height: '100%'}}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Tabs>
                    <TabList className={s.tabstitle_block} >
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>KYC</Tab>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Profile Settings</Tab>
                    </TabList>

                    <TabPanel>
                        <KycPersonal/>
                    </TabPanel>
                    <TabPanel>
                        <ProfileSettings/>
                    </TabPanel>
                </Tabs>
            </motion.div>
        </div>
    );
};

export default Settings;
