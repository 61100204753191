import React, {useState} from 'react'
import s from './AccountStatement.module.css'
import {StatementTable} from "./StatementTable/StatementTable";
import {StatementForm} from "./StatementForm/StatementForm";
import DownloadStatement from "./DownloadStatement/DownloadStatement";

export const AccountStatement = () => {

    const [dataForStatement, setDataForStatement] = useState<any>()

    return (

        <div className={s.accountStatementWrapper}>
            <StatementForm setDataForStatement={setDataForStatement}/>
            <DownloadStatement dataForStatement={dataForStatement}/>
            <StatementTable/>
        </div>

    )
}
