import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {motion} from "framer-motion";
import s from "../CurrencyExchange.module.css";
import {Formik, useFormikContext} from "formik";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import * as yup from "yup";
import {
    setCurrencyExchangeAmount,
    setCurrencyExchangeToAmount,
    setCurrencyExchangeFrom,
    setCurrencyExchangeResult, setCurrencyExchangeTo
} from "../../../../../store/reducers/UserSlice";
import useDebounce from "../../../../../hooks/useDebounce";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../../../../store/reducers/TransactionsSlice/selector";
import {
    sendCurrencyExchangeAmount,
    sendCurrencyExchangeAmountReverse
} from "../../../../../store/reducers/ActionCreators";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    setExchangeData: Dispatch<SetStateAction<any>>
}

const SetCurrencyByAccount = (props: any) => {

    const {values, setFieldValue} = useFormikContext();

    const dispatch = useAppDispatch()

    useEffect(() => {
        props.accountsForSelect.map(async (account: any) => {
            // @ts-ignore
            if (account.number === values?.accNumberFrom) {
                await dispatch(setCurrencyExchangeAmount(''))
                await dispatch(setCurrencyExchangeResult(''))
                await dispatch(setCurrencyExchangeFrom(account.currency))
                setFieldValue('forAmountCurrency', account.currency)
                setFieldValue('amount', '')
                setFieldValue('toAmount', '')
            }
        })
        // @ts-ignore
    }, [values.accNumberFrom, dispatch, props.accountsForSelect, setFieldValue])

    useEffect(() => {
        props.accountsForSelect.map(async (account: any) => {
            // @ts-ignore
            if (account.number === values?.accNumberTo) {
                await dispatch(setCurrencyExchangeAmount(''))
                await dispatch(setCurrencyExchangeResult(''))
                await dispatch(setCurrencyExchangeTo(account.currency))
                setFieldValue('getAmountCurrency', account.currency)
                setFieldValue('amount', '')
                setFieldValue('toAmount', '')
            }
        })
        // @ts-ignore
    }, [values.accNumberTo, dispatch, props.accountsForSelect, setFieldValue])

    useEffect(() => {

        // @ts-ignore
        if (!!values.amount && values?.amount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.forAmountCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.getAmountCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(values.amount))
        }

        // @ts-ignore
        if (!!values.toAmount && values?.toAmount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.getAmountCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.forAmountCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmount(values.toAmount))
        }
        // @ts-ignore
    }, [values.amount, values.toAmount, dispatch, values.forAmountCurrency, values.getAmountCurrency])

    useEffect(() => {
        // @ts-ignore
        if (values.amount === '') {
            dispatch(setCurrencyExchangeResult(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(''))
        }
        // @ts-ignore
    }, [values.amount, dispatch])

    useEffect(() => {
        // @ts-ignore
        if (values.toAmount === '') {
            dispatch(setCurrencyExchangeResult(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmount(''))
        }
        // @ts-ignore
    }, [values.toAmount, dispatch])

    return null;
};

const validationSchema = yup.object({
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {toAmount} = this.parent;
                if (!toAmount) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
    toAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {amount} = this.parent;
                if (!amount) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
})

const ExchangeStepOne = (props: PropsType) => {

    const accountsForSelect = useSelector(selectAccountsByFilter)
    const currencyExchange = useAppSelector(state => state.userReducer.currencyExchange)
    const currencyExchangeResult = useAppSelector(state => state.userReducer.currencyExchangeResult)
    const dispatch = useAppDispatch()

    const debouncedValue = useDebounce(currencyExchange, 1500);

    useEffect(() => {

        const handleRequest = (debouncedValue: any) => {
            if (debouncedValue.amount !== '') {
                const tempData = {...debouncedValue}
                delete tempData.toAmount
                dispatch(sendCurrencyExchangeAmount(tempData))
                return
            }
            if (debouncedValue.toAmount !== '') {
                const tempData = {...debouncedValue, amount: currencyExchange.toAmount}
                delete tempData.toAmount
                dispatch(sendCurrencyExchangeAmountReverse(tempData))
                return
            }
        }

        handleRequest(debouncedValue)
    }, [debouncedValue, currencyExchange.toAmount, dispatch])

    useEffect(() => {

        return () => {
            dispatch(setCurrencyExchangeAmount(''))
            dispatch(setCurrencyExchangeResult(''))
            dispatch(setCurrencyExchangeFrom(''))
            dispatch(setCurrencyExchangeTo(''))
        }

    }, [dispatch])

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <Formik
                    initialValues={{
                        accNumberFrom: '',
                        accNumberTo: '',
                        amount: '',
                        forAmountCurrency: '',
                        toAmount: '',
                        getAmountCurrency: '',
                        type: "exchange"
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const tempData = {
                            ...values,
                            result: currencyExchangeResult
                        }
                        props.setExchangeData([tempData])
                        props.setCurrent(1)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit} className={s.form}>
                            <div className={s.flex}>
                                <div className={s.dropdown_block}>
                                    <div className={s.input_label}>
                                        from account
                                    </div>
                                    <CustomDropdownForAccounts
                                        id={'accNumberFrom'}
                                        name={'accNumberFrom'}
                                        items={accountsForSelect}
                                        disableZeroBalance={true}
                                    />
                                </div>
                                <div className={s.dropdown_block}>
                                    <div className={s.input_label}>
                                        to account
                                    </div>
                                    <CustomDropdownForAccounts
                                        id={'accNumberTo'}
                                        name={'accNumberTo'}
                                        items={accountsForSelect}
                                    />
                                </div>
                            </div>
                            <div className={s.flex}>
                                <div className={s.amount_block}>
                                    <div className={s.amount_input}>
                                        <div className={s.input_label}>
                                            For
                                        </div>
                                        <MyInput
                                            disabled={!!values.toAmount}
                                            id={'amount'}
                                            name={'amount'}
                                            value={(currencyExchangeResult && !values.amount) ? currencyExchangeResult : values.amount}
                                            onChange={handleChange}
                                        />
                                      {errors.amount && touched.amount &&
                                          <div className={s.error_message}>{errors.amount}</div>}
                                    </div>
                                    <div className={s.currency_input_block}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id={'forAmountCurrency'}
                                            name={'forAmountCurrency'}
                                            value={values.forAmountCurrency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={s.amount_block}>
                                    <div className={s.amount_input}>
                                        <div className={s.input_label}>
                                            Get
                                        </div>
                                        <MyInput
                                            disabled={!!values.amount}
                                            id='toAmount'
                                            name='toAmount'
                                            value={(currencyExchangeResult && !values.toAmount) ? currencyExchangeResult : values.toAmount}
                                            onChange={handleChange}
                                        />
                                      {errors.toAmount && touched.toAmount &&
                                          <div className={s.error_message}>{errors.toAmount}</div>}
                                    </div>
                                    <div className={s.currency_input_block}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id={'getAmountCurrency'}
                                            name={'getAmountCurrency'}
                                            value={values.getAmountCurrency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.button_wrapper}>
                                <div className={s.button_block}>
                                    <MyBtn title={'Continue'} type={'submit'}/>
                                </div>
                            </div>
                            <SetCurrencyByAccount
                                accountsForSelect={accountsForSelect}
                                selectedAccountCurrencyGet={currencyExchange.to}
                                selectedAccountCurrencyFor={currencyExchange.from}
                            />
                        </form>

                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default ExchangeStepOne;
