import React from "react";
import s from "./Dashboard.module.css";

import CardsWidget from "./CardsWidget/CardsWidget";
import AccountsWidget from "./AccountsWidget/AccountsWidget";
import TransactionsWidget from "./TransactionsWidget/TransactionsWidget";

const Dashboard = () => {
    return (
        <>
            <div className={s.widgetContainer}>
                <div className={s.widgetWrapper}>
                    <AccountsWidget />
                </div>
                <div className={s.widgetWrapper}>
                    <CardsWidget />
                </div>
            </div>
            <div className={s.widgetContainer}>
                <div className={s.widgetWrapperFullWidth}>
                    <TransactionsWidget />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
