import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { appConfig } from './config/config';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = setupStore();
export const persistor = persistStore(store);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <StrictMode>
                    <GoogleReCaptchaProvider 
                        reCaptchaKey={appConfig.reCaptchaKey}
                    >
                        <App />
                    </GoogleReCaptchaProvider>
                </StrictMode>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

reportWebVitals();
