// RecipientsDetails.js
import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";


 interface FormValues {
    name?: string;
    recipientsIban?: string;
    recipientsBank?: string;
    recipientsSwiftBic?: string;
    // Add other form fields as necessary
}

 interface FormikProps {
    values: FormValues;
    errors: Partial<FormValues>;
    touched: Partial<{[K in keyof FormValues]: boolean}>;
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const GbpBank: React.FC<FormikProps>  = ({ values, errors, touched, handleChange }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>Recipient’s Details</div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* COMPANY NAME</div>
                    <MyInput
                        id="name"
                        name="name"
                        touched={touched.name}
                        value={values.name}
                        onChange={handleChange}
                        isError={errors.name}
                    />
                    {errors.name && touched.name && <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* IBAN</div>
                    <MyInput
                        id="recipientsIban"
                        name="recipientsIban"
                        touched={touched.recipientsIban}
                        value={values.recipientsIban}
                        onChange={handleChange}
                        isError={errors.recipientsIban}
                    />
                    {errors.recipientsIban && touched.recipientsIban && <div className={s.error_message}>{errors.recipientsIban}</div>}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* BANK NAME</div>
                    <MyInput
                        id="recipientsBank"
                        name="recipientsBank"
                        touched={touched.recipientsBank}
                        value={values.recipientsBank}
                        onChange={handleChange}
                        isError={errors.recipientsBank}
                    />
                    {errors.recipientsBank && touched.recipientsBank && <div className={s.error_message}>{errors.recipientsBank}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* BIC CODE</div>
                    <MyInput
                        id="recipientsSwiftBic"
                        name="recipientsSwiftBic"
                        touched={touched.recipientsSwiftBic}
                        value={values.recipientsSwiftBic}
                        onChange={handleChange}
                        isError={errors.recipientsSwiftBic}
                    />
                    {errors.recipientsSwiftBic && touched.recipientsSwiftBic && <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                </div>
            </div>
        </div>
    );
};

export default GbpBank;
