import * as yup from "yup";


export const validationSchema = yup.object().shape({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
    recipientsCountry: yup.string().required('Required field'),
    recipientsAccountNumber: yup.string().required('Required field').max(40, 'max 40 symbols'),
    recipientsCity: yup.string().required('Required field').max(50, 'max 50 symbols'),
    recipientsZipCode: yup.string().required('Required field').max(15, 'max 15 symbols'),
    recipientsBankZipCode: yup.string().required('Required field').max(15, 'max 15 symbols'),
    fullName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBank: yup.string().required('Required field').max(60, 'max 60 symbols'),
    recipientsBankCountry: yup.string().required('Required field'),
    recipientsBankAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
    recipientsBankCity: yup.string().required('Required field').max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
        const { purpose } = this.parent;
        if (!purpose) return value !== undefined && value !== null && value !== '';
        return true
    },
    }),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    recipientsSwiftBic: yup.string().required('Required field').max(12, 'max 12 symbols'),
    recipientsAddress: yup.string().required('Required field').max(100, 'max 100 symbols'),
    email: yup.string().email("invalid format"),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
},
    [ [ 'reference', 'purpose' ] ]);

/*
export const validationSchemaNGNBank = yup.object({
    payerAccount: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),

    lastName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBankCountry: yup.string().required('Required field'),
    reference: yup.string().required('Required field').max(100, 'max 100 symbols'),
    purpose: yup.string().required('Required field').max(100, 'max 100 symbols'),
    amount: yup.string()
        .matches(/^[0-9/,]+$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
})*/

// Common fields that appear in multiple schemas
const commonFields = {
    payerAccount: yup.string().required('Required field'),
    paymentTypeByRegionForm: yup.string().required('Required field'),
    recipientsBankCountry: yup.string().required('Required field'),
    paymentRegions: yup.string().required('Required field'),
    firstName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    lastName: yup.string().required('Required field').max(90, 'max 90 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    email: yup.string().email("invalid format"),
    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
};

export const validationSchemaAza  = yup.object({
    ...commonFields,
});

export const validationSchemaACH  = yup.object({
    payerAccount: yup.string().required('Required field'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    email: yup.string().email("invalid format"),
    paymentDate: yup.string().required('Required field'),
    institutionNumber: yup.string().required('Required field'),
    fullName: yup.string().required('Required field'),
    transitNumber: yup.string().required('Required field'),
    recipientsAccountNumber: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),

});
export const validationNO  = yup.object({


});

export const validationSchemaBACS  = yup.object({
    sortCode: yup.string().required('Required field'),
    recipientsAccountNumber: yup.string().required('Required field'),

    recipientsIban: yup.string().required('Required field'),
    recipientsSwiftBic: yup.string().required('Required field'),
    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    payerAccount: yup.string().required('Required field'),
    email: yup.string().email("invalid format"),
});

export const validationSchemaNGNBank = yup.object({
    ...commonFields,
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaNGNMobile = yup.object({
    ...commonFields,
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols")
});

export const validationSchemaNGNUSDBank = yup.object({
    ...commonFields,
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols")
});

export const validationSchemaGHSBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaMobile = yup.object({
    ...commonFields,
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    mobileProvider:  yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaCash = yup.object({
    ...commonFields,
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols")
});

export const validationSchemaUGXBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().max(90, 'max 90 symbols'),
    identityCardId: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaUGXMobile = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    mobileProvider:  yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaEUR = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsIban: yup.string().required('Required field'),
    recipientsBank: yup.string().required('Required field'),
    recipientsSwiftBic: yup.string().required('Required field')
});

export const validationSchemaUSDBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field'),
    bankAccountType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    recipientsBank: yup.string().required('Required field'),
    routingNumber: yup.string().required('Required field').max(9, 'max 9 symbols'),
    recipientsSwiftBic: yup.string().required('Required field')
});

export const validationSchemaMADCash = yup.object({
    ...commonFields,
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaXOFCash = yup.object({
    ...commonFields,
    recipientCountry: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    cashProvider: yup.string().required('Required field').max(90, 'max 90 symbols'),
});

export const validationSchemaXOFMobile = yup.object({
    ...commonFields,
    recipientCountry: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    mobileProvider: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaXBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsIban: yup.string().required('Required field'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaZARBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    postalCode: yup.string().max(90, 'max 90 symbols'),
    email: yup.string().required('Required field').email("invalid format"),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    recipientsAccountNumber: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
});

export const validationSchemaBWPBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    recipientsAccountNumber: yup.string().required('Required field').max(90, 'max 90 symbols'),
    recipientsBank: yup.string().required('Required field').max(90, 'max 90 symbols'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaPix = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    pixKeyType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    pixKeyValue: yup.string().required('Required field').max(90, 'max 40 symbols'),
    identityCardId: yup.string().required('Required field').max(90, 'max 40 symbols'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaTed = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    city: yup.string().required('Required field').max(90, 'max 90 symbols'),
    postalCode: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    branchCodeTED: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankAccountType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field').max(40, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaZMWBank = yup.object({
    ...commonFields,
    middleName: yup.string().max(90, 'max 90 symbols'),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    branchCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaKESBank = yup.object({
    ...commonFields,
    senderIdType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    senderIdNumber: yup.string().required('Required field'),
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    identityCardType: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    identityCardId: yup.string().max(40, 'max 40 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(90, 'max 90 symbols'),
    transferReason: yup.object({
        name: yup.string(),
        value: yup.string(),
    }),
    relationshipToSender: yup.string(),
});

export const validationSchemaKESMobile = yup.object({
    ...commonFields,
    senderIdType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    senderIdNumber: yup.string().required('Required field'),
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    city: yup.string().max(90, 'max 90 symbols'),
    identityCardType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    identityCardId: yup.string().required('Required field').max(40, 'max 40 symbols'),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    mobileProvider: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    relationshipToSender: yup.string(),
});

export const validationSchemaEGPBank = yup.object({
    ...commonFields,
    senderIdType: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    senderIdNumber: yup.string().required('Required field'),
    middleName: yup.string().max(90, 'max 90 symbols'),
    street: yup.string().required('Required field').max(90, 'max 90 symbols'),
    bankCode: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
    bankAccount: yup.string().required('Required field').max(40, 'max 40 symbols'),
    phoneNumber: yup.string()
        .required('Required field')
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .max(20, "max 20 symbols"),
    transferReason: yup.object({
        name: yup.string().required('Required field'),
        value: yup.string(),
    }),
});

export const validationSchemaInterac  = yup.object({
    beneficiaryName: yup.string().required('Required field').max(90, "max 90 symbols"),
    beneficiaryEmail: yup.string().required('Required field').email("invalid format"),
    securityQuestion: yup.string().required('Required field').max(90, "max 90 symbols"),
    securityAnswer: yup.string().required('Required field').max(90, "max 90 symbols"),

    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        )
        .test(
            'max-amount',
            'Maximum Transaction Size: $10000.0',
            (value, context) => {

                const isMaxValue = () => {
                    const numberValue = Number(value)
                    return numberValue > 10000
                }

                if (isMaxValue()) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }

                return true;
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    payerAccount: yup.string().required('Required field'),
});

export const validationSchemaEFT  = yup.object({
    firstName: yup.string().required('Required field').max(90, "max 90 symbols"),
    beneficiaryEmail: yup.string().required('Required field').email("invalid format"),
    lastName: yup.string().required('Required field').max(90, "max 90 symbols"),
    institutionNumber: yup.string().required('Required field').max(90, "max 90 symbols"),
    transitNumber: yup.string().required('Required field').max(90, "max 90 symbols"),
    recipientsAccountNumber: yup.string().required('Required field').max(90, "max 90 symbols"),

    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        )
        .test(
            'max-amount',
            'Maximum Transaction Size: $10000.0',
            (value, context) => {

                const isMaxValue = () => {
                    const numberValue = Number(value)
                    return numberValue > 10000
                }

                if (isMaxValue()) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }

                return true;
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    payerAccount: yup.string().required('Required field'),
});

export const validationSchemaBill  = yup.object({
    firstName: yup.string().required('Required field').max(90, "max 90 symbols"),
    beneficiaryEmail: yup.string().required('Required field').email("invalid format"),
    lastName: yup.string().required('Required field').max(90, "max 90 symbols"),
    payeeName: yup.string().required('Required field').max(90, "max 90 symbols"),
    payeeCode: yup.string().required('Required field').max(90, "max 90 symbols"),
    payeeAccountNumber: yup.string().required('Required field').max(90, "max 90 symbols"),

    paymentDate: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        )
        .test(
            'max-amount',
            'Maximum Transaction Size: $10000.0',
            (value, context) => {

                const isMaxValue = () => {
                    const numberValue = Number(value)
                    return numberValue > 10000
                }

                if (isMaxValue()) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }

                return true;
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols'),
    reference: yup.string().test({message: 'One of the fields is required',
        test: function (value) {
            const { purpose } = this.parent;
            if (!purpose) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    purpose: yup.string().test({
        message: 'One of the fields is required',
        test: function (value) {
            const {reference} = this.parent;
            if (!reference) return value !== undefined && value !== null && value !== '';
            return true
        },
    }).max(100, 'max 100 symbols'),
    payerAccount: yup.string().required('Required field'),
});
