import React from 'react';

const WithdrawalSettings = () => {
    return (
        <div>
            WithdrawalSettings
        </div>
    );
};

export default WithdrawalSettings;
