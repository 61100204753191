import React, { Dispatch, SetStateAction } from "react";
import FileInput from "../FileInput/FileInput";
import classnames from "classnames";
import type { FileTypes } from "../GenerateKycForm/Constants/KycConstants";
import { useAppSelector } from "../../hooks/redux";

type PropsType = {
    s: any;
    documentType: string;
    title: string;
    iconUrl: string;
    createModeMissingDoc: string[];
    editModeMissingDoc: string[];
    descriptionItems?: string[] | any;
    descriptionText?: string;
    documents: any;
    allowedFileTypes: FileTypes[];
    maxFileSize: number;
    isRequired: boolean;
    note?: string;
    setDocuments: Dispatch<SetStateAction<any>>;
};

const DocumentBlock = (props: PropsType) => {
    const {
        s,
        documentType,
        title,
        iconUrl,
        createModeMissingDoc,
        editModeMissingDoc,
        descriptionItems,
        descriptionText,
        documents,
        allowedFileTypes,
        maxFileSize,
        isRequired,
        note,
        setDocuments,
    } = props;

    const kycList = useAppSelector((state) => state.userReducer.user.kycList);

    return (
        <React.Fragment>
            <div className={s.add_doc_block}>
                <div className={s.content_block}>
                    <div>
                        <img src={iconUrl} alt="Document Icon" />
                    </div>
                    <div>
                        <div
                            className={classnames(s.title, {
                                [s.doc_error]:
                                    isRequired &&
                                    !documents[documentType] &&
                                    (createModeMissingDoc.includes(
                                        documentType
                                    ) ||
                                        editModeMissingDoc.includes(
                                            documentType
                                        )),
                            })}
                        >
                            {isRequired && (
                                <span className={s.required}>* </span>
                            )}
                            {title}
                        </div>
                        {descriptionText && (
                            <div className={s.add_block_text}>
                                {descriptionText}
                            </div>
                        )}
                        <ul className={s.passport_spread_list}>
                            {descriptionItems &&
                                descriptionItems.map(
                                    (item: string, index: number) => (
                                        <li key={index}>
                                            <span className={s.text_color}>
                                                {item}
                                            </span>
                                        </li>
                                    )
                                )}
                            <li>
                                <span className={s.text_color}>
                                    Allowed file types:&nbsp;
                                    {allowedFileTypes
                                        .map((type) =>
                                            type.replace(".", "").toUpperCase()
                                        )
                                        .join(", ")}
                                    .
                                </span>
                            </li>
                            <li>
                                <span className={s.text_color}>
                                    Maximum file size: {maxFileSize}Mb.
                                </span>
                            </li>
                            {note && <div className={s.attention}>{note}</div>}
                        </ul>
                    </div>
                </div>

                <FileInput
                    s={s}
                    documents={documents}
                    key={documentType}
                    inputId={documentType}
                    allowedTypes={allowedFileTypes}
                    identifier={documentType}
                    maxFileSize={maxFileSize}
                    kycList={kycList && kycList}
                    onChange={(event) => {
                        // @ts-ignore
                        setDocuments({
                            ...documents,
                            // @ts-ignore
                            [documentType]: event.currentTarget.files[0],
                        });
                    }}
                    onImageClick={() => {
                        // @ts-ignore
                        document.getElementById(documentType).value = "";
                        setDocuments({ ...documents, [documentType]: "" });
                    }}
                />
            </div>
            <div className={s.line}></div>
        </React.Fragment>
    );
};

export default DocumentBlock;
