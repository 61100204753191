import React from 'react';
import efficient from "../../../../assets/newImg/publicPages/services/moneyTransfers/efficient.svg";
import global from "../../../../assets/newImg/publicPages/services/moneyTransfers/global.svg";
import personalized from "../../../../assets/newImg/publicPages/services/moneyTransfers/personalized.svg";
import styles from "../../../../styles/styles.module.css";
import ServicesHeaderBlock from "../../../../components/ServicesHeaderBlock/ServicesHeaderBlock";
import imageSrc from "../../../../assets/newImg/publicPages/services/moneyTransfers/main.png";
import s from "./MoneyTransfers.module.css";
import SettingsCard from "../../../../components/SettingsCard/SettingsCard";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import support from "../../../../assets/newImg/publicPages/services/moneyOrders/help_money_orders.svg";
import ContactUsForm from "../../../../components/ContactUsForm/ContactUsForm";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';
import {brandConfig} from '../../../../config/config';

const MoneyTransfers = () => {

    const title = "Seamless Money Transfers"
    const navigate = useNavigate()
    const cards = [
        {
            icon: efficient,
            title: 'Efficient and Cost-Effective:',
            text: 'We understand the importance of efficiency and cost-effectiveness when it comes to money transfers. ' +
                'Our streamlined process and competitive rates ensure that your funds are transferred swiftly and at a ' +
                'favorable rate. Say goodbye to lengthy procedures and excessive fees — experience the convenience of ' +
                `seamless money transfers with ${brandConfig.companyNameSimple}.`
        },
        {
            icon: global,
            title: 'Global Reach:',
            text: 'Our extensive network allows us \n' +
                'to facilitate money transfers to various destinations worldwide. Whether you\'re sending money to family ' +
                'overseas, conducting business transactions, or supporting loved ones studying abroad, our global reach ' +
                'ensures that your funds reach their intended recipients securely and efficiently.'
        },
        {
            icon: personalized,
            title: 'Personalized Support:',
            text: 'We believe in providing personalized support for all your money transfer needs. Our knowledgeable team ' +
                'is ready to assist you with any questions or concerns, ensuring that you have a smooth and hassle-free ' +
                'experience. We\'re here to guide you through the process and offer tailored solutions to meet your ' +
                'specific requirements.'
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
            <div className={styles.wrapper}>

                <ServicesHeaderBlock title={title} imageSrc={imageSrc}/>

                <div className={s.page_description}>
                    At {brandConfig.companyNameSimple}, we strive to make money transfers seamless and hassle-free, enabling you to send and
                    receive funds with ease, whether domestically or internationally.
                </div>

                <div className={s.cards_block}>
                    {
                        cards.map((card) => {
                            return (
                                <SettingsCard icon={card.icon} title={card.title} text={card.text}/>
                            )
                        })
                    }
                </div>

                <div className={s.button_wrapper}>
                    <div className={s.button_block}>
                        <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                    </div>
                </div>

                <div className={s.support_wrapper}>
                    <div className={s.container_support}>
                        <div>
                            <div className={s.support_subtitle}>
                                Competitive rates, expert assistance, efficiency and reliability
                            </div>
                            <div className={s.support_title}>
                                We are committed to <span className={s.underline}> exceeding </span> your expectations
                            </div>
                            <div className={s.support_text}>
                                Our goal is to provide you with an effortless money order service that meets your financial
                                needs with ease and efficiency.
                            </div>
                        </div>
                        <div className={s.img}>
                            <img src={support} alt=""/>
                        </div>
                    </div>

                    <div className={s.support_text_mobile}>
                        Our goal is to provide you with an effortless money order service that meets your financial
                        needs with ease and efficiency.
                    </div>

                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default MoneyTransfers;
