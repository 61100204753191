import {zeroFill} from "./ZeroFill";

export const toHumanDate = (date: number) => {
    let dateFormat = new Date(date * 1000);
    let day = dateFormat.getDate() > 9  ? dateFormat.getDate() : `0${dateFormat.getDate()}`
    let month = dateFormat.getMonth() + 1 > 9 ? dateFormat.getMonth() + 1 : `0${dateFormat.getMonth() + 1}`
    let humanDate = dateFormat.getFullYear() + "-" + month + "-" + day
    let time = zeroFill(dateFormat.getUTCHours(), 2) + ':' + zeroFill(dateFormat.getUTCMinutes(),2) + ':' + zeroFill(dateFormat.getUTCSeconds(), 2)

    return {
        date:  humanDate,
        time : time
    }
}




