import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SlickSlider from "react-slick";
import React, {useState} from 'react';
import s from './Slider.module.css'

import {ReactComponent as ChangeItem} from '../../assets/newImg/publicPages/change_card_icon.svg';
import classnames from "classnames";
import Icon from "@ant-design/icons";
import ReviewsRating from "../ui/ReviewsRating/ReviewsRating";
import { brandConfig } from "../../config/config";

const Slider = () => {
    const cards = [
        {
            number: 1,
            name: "Samantha Roberts",
            avatar: "/sliderImg/samantha.svg",
            text: `“My partnership with ${brandConfig.companyNameSimple} is built on trust, transparency and a shared vision of success. \n` +
                'They are passionate about redefining the finance industry. Their team of seasoned professionals combined ' +
                'with a dedication to personalized  service, sets them apart.”',
            rating: 4

        },
        {
            number: 2,
            name: "David Mitchell",
            avatar: "/sliderImg/david.svg",
            text: `“Since joining ${brandConfig.companyNameSimple}, my banking experience has been nothing short of exceptional. The team's ` +
                'commitment to building genuine relationships and understanding my unique needs has made all the difference. ' +
                `Unlike other institutions where I often felt like just another customer, ${brandConfig.companyNameSimple} treats me like a valued` +
                ' partner on my financial journey. Their dedication, professionalism, and personalized service have surpassed \n' +
                'my expectations.”',
            rating: 5
        },
        {
            number: 3,
            name: "Benjamin Lee",
            avatar: "/sliderImg/benjamin.svg",
            text: `“Choosing ${brandConfig.companyNameSimple} has been one of the best decisions I've made for my financial well-being. Their ` +
                'team of highly qualified professionals takes the time to understand my unique circumstances and craft ' +
                'tailored solutions that align with my goals. The personalized care and attention I receive make me feel' +
                ` valued as a client. With ${brandConfig.companyNameSimple}, I have found a trusted partner that genuinely cares about my financial` +
                ' success."',
            rating: 5
        },
        {
            number: 4,
            name: "Sophia Nguyen",
            avatar: "/sliderImg/sophia.svg",
            text: `"${brandConfig.companyNameSimple} has truly exceeded my expectations in every aspect. The expertise they bring to the table ` +
                `is evident in the comprehensive solutions they provide. ${brandConfig.companyNameSimple} is more than just a finance institution; ` +
                'they are a team of professionals who are passionate about helping their clients reach new heights. I am ' +
                'grateful to have them by my side on my financial journey."',
            rating: 4
        },
        {
            number: 5,
            name: "Olivia Bennett",
            avatar: "/sliderImg/olivia.svg",
            text: `"I can't express enough gratitude for the outstanding service from ${brandConfig.companyNameSimple} side. From the very ` +
                'first interaction, I knew I was in capable hands."',
            rating: 5
        },
    ]

    const isMobile = () => {
        return window.innerWidth < 768
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile() ? 1 : 1.8,
        slidesToScroll: 1,

    };

    const [sliderRef, setSliderRef] = useState(null)

    return (
        <div className={classnames("container", s.web)}>

            <SlickSlider ref={setSliderRef} {...settings} className={s.reviews_table}>
                {cards.map((card) => <div key={card.number}
                                          className={classnames(s.active, s.card)}>
                    <div className={s.person_block}>
                        <img src={card.avatar} alt=""/>
                        <span className={s.person_name}>{card.name}</span>
                    </div>
                    <div className={s.review}>
                        {card.text}
                    </div>
                    <div className={s.stars_block}>
                        <ReviewsRating rating={card.rating} starCount={5}/>
                    </div>

                </div>)}
            </SlickSlider>

            <div className={s.toggle_icons_wrapper}>
                <div className={s.reviews_title}>What our dear clients say</div>
                <div className={s.change_items_icons_block}>
                    <div onClick={sliderRef?.slickPrev}>
                        <Icon className={s.inactive_button} component={() => <ChangeItem/>}/>
                    </div>
                    <div onClick={sliderRef?.slickNext}>
                        <Icon className={s.active_button} component={() => <ChangeItem/>}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;
