import React from 'react';
import styles from "../../../../styles/styles.module.css"
import s from "./VirtualCurrencies.module.css"
import ContactUsForm from "../../../../components/ContactUsForm/ContactUsForm";
import ServicesHeaderBlock from "../../../../components/ServicesHeaderBlock/ServicesHeaderBlock";
import imageSrc from "../../../../assets/newImg/publicPages/services/virtualCurrencies/virtual_currencies_main.svg"
import onboarding from "../../../../assets/newImg/publicPages/services/virtualCurrencies/onboarding.svg"
import secure from "../../../../assets/newImg/publicPages/services/virtualCurrencies/secure.svg"
import bitcoin from "../../../../assets/newImg/publicPages/services/virtualCurrencies/bitcoin.svg"
import book from "../../../../assets/newImg/publicPages/services/virtualCurrencies/book.svg"
import bankCards from "../../../../assets/newImg/publicPages/services/virtualCurrencies/cards.svg"
import raiting from "../../../../assets/newImg/publicPages/services/virtualCurrencies/raiting.svg"
import support from "../../../../assets/newImg/publicPages/services/support.svg"
import SettingsCard from "../../../../components/SettingsCard/SettingsCard";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {motion} from 'framer-motion';
import {useNavigate} from "react-router-dom";
import {brandConfig} from '../../../../config/config';

const VirtualCurrencies = () => {

    const title = "Embracing the World of Virtual Currencies"
    const navigate = useNavigate()
    const cards = [
        {
            icon: onboarding,
            title: 'Seamless Onboarding:',
            text: 'Getting started with virtual currencies can be overwhelming, but we make the onboarding process ' +
                'seamless and user-friendly. Our team will guide you through the necessary steps, ensuring a smooth ' +
                'transition into the world of digital assets. Whether you\'re a seasoned investor or new to cryptocurrencies, ' +
                'we provide the support and resources you need to navigate this exciting realm.'
        },
        {
            icon: secure,
            title: 'Secure and Reliable Trading:',
            text: `When it comes to trading virtual currencies, security and reliability are paramount. At ${brandConfig.companyNameSimple}, we ` +
                'prioritize the protection of your digital assets. Our robust platform incorporates advanced security ' +
                'measures and protocols to safeguard your funds and transactions. You can trust us to provide a secure ' +
                'environment for buying, selling, and exchanging virtual currencies, giving you peace of mind as you ' +
                'explore new investment opportunities.'
        },
        {
            icon: bitcoin,
            title: 'Range of Virtual Currencies:',
            text: 'We offer an extensive selection of virtual currencies, including established cryptocurrencies like ' +
                'Bitcoin (BTC), Ethereum (ETH), and Litecoin (LTC), as well as emerging digital assets with promising ' +
                'potential. Our diverse range of virtual currencies allows you to diversify your portfolio and capitalize ' +
                'on the evolving market trends.'
        },
        {
            icon: book,
            title: 'Educational Resources and Support:',
            text: 'We understand that the world of virtual currencies can be complex, especially for those new to the ' +
                'ecosystem. That\'s why we provide educational resources and support to enhance your understanding of ' +
                'cryptocurrencies. Our team is here to answer your questions, provide guidance, and assist you in making ' +
                'informed decisions regarding your virtual currency investments.'
        },
        {
            icon: bankCards,
            title: 'Secure Crypto Wallets:',
            text: 'Protecting your digital assets is of utmost importance. That\'s why we offer secure crypto wallets ' +
                'for storing your virtual currencies. Our wallets employ cutting-edge encryption technology and stringent ' +
                `security measures, ensuring the safety of your digital wealth. With ${brandConfig.companyNameSimple}, you can rest assured that ` +
                'your virtual currencies are stored securely and accessible only to you.'
        },
        {
            icon: raiting,
            title: 'Expert Insights and Analysis:',
            text: 'Staying informed is crucial in the dynamic world of virtual currencies. Our team of experts closely ' +
                'monitors market trends, news, and developments to provide you with valuable insights and analysis. We ' +
                'keep you updated on the latest happenings in the crypto space, empowering you to make well-informed ' +
                'investment decisions.'
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>

                    <ServicesHeaderBlock title={title} imageSrc={imageSrc}/>

                    <div className={s.page_description}>
                        At {brandConfig.companyNameSimple}, we recognize the growing importance and potential of virtual currencies in today's
                        digital economy. As a licensed Money Service Business (MSB), we are equipped with the expertise
                        and
                        regulatory compliance to offer comprehensive services for virtual currencies.
                    </div>

                    <div className={s.cards_block}>
                        {
                            cards.map((card) => {
                                return (
                                    <SettingsCard icon={card.icon} title={card.title} text={card.text}/>
                                )
                            })
                        }
                    </div>


                    <div className={s.button_wrapper}>
                        <div className={s.button_block}>
                            <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                        </div>
                    </div>

                    <div className={s.support_wrapper}>
                        <div className={s.container_support}>
                            <div>
                                <div className={s.support_subtitle}>
                                    Creating a positive and empowering experience with virtual currencies
                                </div>
                                <div className={s.support_title}>
                                    We are here to support you <span className={s.underline}> every step</span> of the
                                    way
                                </div>
                                <div className={s.support_text}>
                                    Whether you're looking to diversify your investment portfolio, explore new
                                    opportunities,
                                    or simply learn more about this exciting asset class.
                                </div>
                            </div>
                            <div className={s.img}>
                                <img src={support} alt=""/>
                            </div>
                        </div>


                        <div className={s.support_text_mobile}>
                            Whether you're looking to diversify your investment portfolio, explore new
                            opportunities,
                            or simply learn more about this exciting asset class.
                        </div>

                    </div>

                    <div className={s.contact_us_form_wrapper}>
                        <ContactUsForm/>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default VirtualCurrencies;
