import React from 'react';
import classnames from "classnames";
import s from "../../Slider/Slider.module.css";
import {ReactComponent as Star} from '../../../assets/newImg/publicPages/star.svg';

type PropsType = {
    rating: number
    starCount: number
}
const ReviewsRating = (props: PropsType) => {
    return (
        <>
            {[...Array(props.starCount)].map((item, index) => {
                return (
                    <Star key={index}
                          className={
                              classnames(props.rating <= index ? s.star_inactive : s.star_active)
                          }
                    />
                )
            })}
        </>
    );
};

export default ReviewsRating;
