import React from 'react';
import s from './SettingsCard.module.css'

type PropsType = {
    icon: string,
    title: string,
    text: string,
}

const SettingsCard = (props: PropsType) => {
    return (
        <div className={s.card_wrapper}>
            <div>
                <img src={props.icon} alt={""}/>
            </div>
            <div className={s.card_title}>
                {props.title}
            </div>
            <div className={s.card_text}>
                {props.text}
            </div>
        </div>
    );
};

export default SettingsCard;
