import React, {useState} from 'react';
import s from './CreateTemplate.module.css'
import {Dropdown, MenuProps, Popover, Space} from "antd";
import arrow from "../../../../assets/newImg/personalAccount/arrow.svg";
import info from "../../../../assets/newImg/personalAccount/information.svg";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import {useParams} from "react-router-dom";
import ToAnIberbancoTransactionTemplate
    from "../../../../components/TransactionTemplates/ToIberbancoTransactionTemplate/ToIberbancoTransactionTemplate";
import BankTransactionTemplate
    from "../../../../components/TransactionTemplates/BankTransactionTemplate/BankTransactionTemplate";
import {brandConfig} from '../../../../config/config';

interface CreateTemplateProps {
    typeForm?: string,
    setShowForm?: any;
}

const templateType: MenuProps['items'] = [
    {
        label: 'To accounts in Canada and foreign banks',
        key: 'To accounts in Canada and foreign banks',
    },
    {
        label: `Transfer to ${brandConfig.companyNameSimple} User`,
        key: `Transfer to ${brandConfig.companyNameSimple} User`,
    },
];

export const menuStyle = {
    border: '1.5px solid #EEEFEF',
    borderRadius: 8,
};
export const contentStyle = {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#979797'
};

const CreateTemplate = (props: CreateTemplateProps) => {

    const [purpose, setPurpose] = useState('To accounts in Canada and foreign banks')

    const {id } = useParams()

    const handleItemClick: MenuProps['onClick'] = (e) => {
        setPurpose(e.key);
    };

    const [templateName, setTemplateName] = useState<string>('')
    const templateNameError = 'max 100 symbols'

    return (
        <div className={s.templateWrapper}>

            {
                id === "create"
                ? <div className={s.detailsWrapper}>
                        <p className={s.title}>New Template</p>

                        <div className={s.inputsWrapper}>
                            <div className={s.detailsWrapperItem}>
                                <div className={s.row}>
                                    <p className={s.detailsWrapperItemName}>Template Name</p>
                                    <Popover
                                        content={"In order to save this transfer as a template, please enter the template name."}
                                        color={'#EEEFEF'}
                                        placement={"bottom"}
                                        overlayInnerStyle={{
                                            fontFamily: 'Inter',
                                            padding: '13px 16px',
                                            backgroundColor: '#EEEFEF',
                                            boxShadow: 'none'
                                        }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <MyInput
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.currentTarget.value)}
                                    width={'100%'}
                                    isError={(templateName.length > 100 || templateName.length <= 0)}
                                    touched={true}
                                />
                                {templateName.length > 100 && <div className={s.error_label}>{templateNameError}</div>}
                                {templateName.length <= 0 && <div className={s.error_label}>Required field</div>}
                            </div>

                            <div className={s.detailsWrapperItem}>
                                <p className={s.detailsWrapperItemName}>Transfer type</p>
                                <Dropdown menu={{items: templateType, onClick: handleItemClick}} trigger={['click']}
                                          dropdownRender={(menu: React.ReactNode) => (
                                              <div style={contentStyle}>
                                                  {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                              </div>
                                          )}>
                                    <Space style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: 52,
                                        color: '#2A2E37',
                                        border: '1.5px solid #EEEFEF',
                                        borderColor: '#EEEFEF',
                                        borderRadius: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '18px',
                                        paddingLeft: '16px',
                                        paddingRight: '16px'
                                    }}>
                                        <p className={s.textWrapper}>{purpose}</p>
                                        <img alt={'dropdownArrow'} className={s.arrow_img} src={arrow}/>
                                    </Space>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                :<></>
            }

            <>
                <p className={s.title}>Payer Details</p>
                {
                    purpose === "To accounts in Canada and foreign banks"
                        ? <BankTransactionTemplate id={id} templateName={templateName} setPurpose={setPurpose}/>
                        : <ToAnIberbancoTransactionTemplate id={id} templateName={templateName} setPurpose={setPurpose}/>
                }
            </>

        </div>

    )
}

export default CreateTemplate;
