import {phonecodes} from "../../../../Constants/StaticData";

const getCountryCode = (countryName: string) => {
    const country = phonecodes.find((entry) => entry.na === countryName);
    return country?.co;
};

export const PrepareDataForCardRequest = (values: { [p: string]: any }) => {

    const cleanObject = (function () {

        function isEmpty(value: any) {
            return value === '';
        }

        return function (obj: any) {

            const tempObj = {...obj}

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {

                    let value = tempObj[key];

                    if (isEmpty(value) || (value instanceof Object && cleanObject(value))) {
                        delete tempObj[key];
                    }
                }
            }

            return tempObj
        };
    })();

    const testObj = {
        currency: values.currency,
        email: values.email,
        phone: values.phone,
        shippingAddress: values.shippingAddress,
        shippingCountryCode: getCountryCode(values.shippingCountry),
        shippingCity: values.shippingCity,
        shippingCountyState: values.shippingCountyState,
        shippingPostCode: values.shippingPostCode,
        amount: Number(values.amount),
        payerAccount: values.payerAccount,
        deliveryMethod: values.deliveryMethod
    }

    const cleanedMainObject = cleanObject(testObj)

    return cleanedMainObject
};