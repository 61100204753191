import React, {Dispatch, SetStateAction} from 'react';
import BankTransferFormBusiness from "../../../../../components/BankTransferFormBusiness/BankTransferFormBusiness";
import {useAppSelector} from "../../../../../hooks/redux";
import BankTransferFormPersonal from "../../../../../components/BankTransferFormPersonal/BankTransferFormPersonal";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    setTransferData: Dispatch<SetStateAction<any>>
}

const BankTransferStepOne = (props: PropsType) => {

    const accountType = useAppSelector(state => state.userReducer.user.userData.accountType)

    return (
        <>
            {
                accountType === "personal" ?
                    <BankTransferFormPersonal
                        setTransferData={props.setTransferData}
                        setCurrent={props.setCurrent}
                    />
                    : <BankTransferFormBusiness
                        setTransferData={props.setTransferData}
                        setCurrent={props.setCurrent}
                    />
            }
        </>

    );

};

export default BankTransferStepOne;
