import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { setDisabled } from "../../../store/reducers/UserSlice";
import { getCardsInfo, getPhoneListThunk, sendCardRequest } from "../../../store/reducers/ActionCreators";
import s from "./VirtualCardRequest.module.css";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import MyInput from "../../ui/MyInput/MyInput";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import MyBtn from "../../ui/MyBtn/MyBtn";
import CustomModal from "../../ui/CustomModal/CustomModal";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import * as yup from "yup";
import { ReactComponent as Back } from "../../../assets/newImg/personalAccount/back.svg";
import { cardCountries } from "../../../Constants/StaticData";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import { PrepareDataForCardRequest } from "./FormHelpers/FormHelpers";
import { Popover } from "antd";
import info from "../../../assets/newImg/personalAccount/information.svg";

type PropsType = {
    onCancelClick: () => void; onSuccessClick: () => void;
};

const VirtualCardRequest = (props: PropsType) => {

    const {onCancelClick} = props;
    const handleSuccessClick = () => {
        props.onSuccessClick();
    };

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [availableCurrencies, setAvailableCurrencies] = useState([]);

    const dispatch = useAppDispatch();
    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)
    const phoneList = useAppSelector(state => state.userReducer.user.phoneList);
    const accountsList = useAppSelector(state => state.userReducer.accountsList.accounts);
    const userInfo = useAppSelector(state => state.userReducer.user);
    const userCards: any[any] = useAppSelector(store => store.userReducer.cardInfo)


    useEffect(() => {
        dispatch(getPhoneListThunk(token));
    }, [dispatch, token]);

    useEffect(() => {
        const currencyList = ['EUR', 'USD'];
        const usedCurrencies = userCards?.length > 0 ? userCards.map((card: any) => card.currency) : [];
        const filteredCurrencies: any[''] = currencyList.filter(currency => !usedCurrencies.includes(currency));
        setAvailableCurrencies(filteredCurrencies);

        if (filteredCurrencies?.length === 1) {
            setSelectedCurrency(filteredCurrencies[0]);
        }
    }, [userCards]);


    const phoneNumbers = phoneList?.filter(phone => phone.isVerify)?.map(phone => `+${phone.phoneNumber}`) || [];
    const filteredAccounts = accountsList.filter((account: any) => account.currency === selectedCurrency);

    const getFilteredAccounts = () => {
        return accountsList.filter((account: { currency: string; }) => account.currency === availableCurrencies[0]);
    }

    const validationSchema = yup.object({
        currency: selectedCurrency ? yup.string().notRequired() : yup.string().required('Required field'),
        email: yup.string().required('Required field').email("invalid format"),
        phone: yup.string().required('Required field'),
        shippingAddress: yup.string().required('Required field'),
        shippingCountry: yup.string().required('Required field'),
        shippingCity: yup.string().required('Required field'),
        shippingPostCode: yup.string().required('Required field'),
        payerAccount: yup.string().required('Required field'),
        transferCurrency: selectedCurrency ? yup.string().optional() : yup.string().required('Required field'),
        deliveryMethod: yup.string().required('Required field'),
        amount: yup.string()
            .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
            .required('Required field')
            .max(10, 'max 10 digits')
            .test('no-leading-zero', 'invalid format', (value, context) => {
                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    return item === '0';
                });

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            })
            .test('max-balance', function (value) {
                const { payerAccount } = this.parent as { payerAccount: string };
                const selectedAccount = filteredAccounts.find((account: { number: string; }) => account.number === payerAccount);
                if (selectedAccount && parseFloat(value!) > selectedAccount.balance) {
                    return this.createError({
                        message: `Amount of the card loan can’t exceed available balance of the account. Max: ${selectedAccount.balance.toFixed(2)} EUR.`
                    });
                }
                return true;
            }),
    });

    return (
        <>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div>
                    <div className={s.popup_title}>Success!</div>
                    <span className={s.popup_subtitle}> Your request has been sent.</span>
                    <div className={s.popup_button_wrapper}>
                        <div className={s.btn_block}>
                            <MyBtn title={"Ok"} onClick={handleSuccessClick} />
                        </div>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={s.popup_title}>
                    Oops!
                </div>
                <div className={s.popup_subtitle}>
                    Something went wrong. Please try again later.
                </div>
                <div className={s.popup_button_wrapper}>
                    <div className={s.btn_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)} />
                    </div>
                </div>
            </CustomModal>

            <div className={s.wrapper}>
                <div className={s.title}>Virtual Card Request</div>
                <Formik
                    initialValues={{
                        currency: availableCurrencies?.length === 1 ? availableCurrencies[0] : selectedCurrency,
                        email: userInfo.userData.email ?? '',
                        phone: '',
                        shippingAddress: '',
                        shippingCountry: '',
                        shippingCity: '',
                        shippingCountyState: '',
                        shippingPostCode: '',
                        amount: '',
                        payerAccount: '',
                        transferCurrency: availableCurrencies?.length === 1 ? availableCurrencies[0] : selectedCurrency,
                        deliveryMethod: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log('submitted', values)
                        let tempData = {};
                        if(availableCurrencies?.length === 1) tempData = { ...values, currency: availableCurrencies[0], transferCurrency: availableCurrencies[0] }
                        else tempData = { ...values, currency: selectedCurrency };

                        console.log('new values are', tempData);

                        const preparedData = PrepareDataForCardRequest(tempData);
                        dispatch(setDisabled(true));
                        dispatch(sendCardRequest(token, preparedData))
                            .then((res) => {
                                dispatch(getCardsInfo(token)).then((res: any) => console.log('card info', res));
                                setIsSuccessModalOpen(true);
                            })
                            .catch((e) => {
                                setIsErrorModalOpen(true);
                            })
                            .finally(() => {
                                dispatch(setDisabled(false));
                            });
                    }}>

                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.formTitle}>
                                Card Owner Details
                            </div>
                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Phone Number (VERIFIED IN SETTINGS)</span>
                                    <CustomDropdown
                                        items={phoneNumbers}
                                        id={"phone"}
                                        name={"phone"}
                                        isError={errors.phone}
                                        touched={touched.phone} />
                                    {errors.phone && touched.phone && <div className={s.error}>{errors.phone}</div>}
                                </div>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Email Address</span>
                                    <MyInput
                                        name={"email"}
                                        id={"email"}
                                        value={values.email}
                                        touched={touched.email}
                                        onChange={handleChange}
                                        isError={errors.email}
                                        width={"288px"}
                                    />
                                    {errors.email && <div className={s.error}>{errors.email}</div>}
                                </div>
                            </div>
                            <div className={s.formTitle}>
                                Delivery Address
                            </div>
                            <div className={s.warningText}>Please verify the delivery address before requesting the card, as it is non-changeable.</div>

                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Address</span>
                                    <MyInput
                                        name={"shippingAddress"}
                                        id={"shippingAddress"}
                                        value={values.shippingAddress}
                                        touched={touched.shippingAddress}
                                        onChange={handleChange}
                                        isError={errors.shippingAddress}
                                        placeholder={"Street/Building/Flat"}
                                        width={"288px"}
                                    />
                                    {errors.shippingAddress && <div className={s.error}>{errors.shippingAddress}</div>}
                                </div>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Country</span>
                                    <CustomDropdown
                                        items={cardCountries}
                                        placeholder={"Country"}
                                        id="shippingCountry"
                                        name="shippingCountry"
                                        isError={errors.shippingCountry}
                                        touched={touched.shippingCountry}
                                    />
                                    {errors.shippingCountry && <div className={s.error}>{errors.shippingCountry}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        City</span>
                                    <MyInput
                                        name={"shippingCity"}
                                        id={"shippingCity"}
                                        value={values.shippingCity}
                                        touched={touched.shippingCity}
                                        onChange={handleChange}
                                        isError={errors.shippingCity}
                                        width={"288px"}
                                    />
                                    {errors.shippingCity && <div className={s.error}>{errors.shippingCity}</div>}
                                </div>
                                <div className={s.inputBlock}>
                                    <span>
                                        State</span>
                                    <MyInput
                                        name={"shippingCountyState"}
                                        id={"shippingCountyState"}
                                        value={values.shippingCountyState}
                                        touched={touched.shippingCountyState}
                                        onChange={handleChange}
                                        isError={errors.shippingCountyState}
                                        width={"288px"}
                                    />
                                    {errors.shippingCountyState && <div className={s.error}>{errors.shippingCountyState}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Post code</span>
                                    <MyInput
                                        name={"shippingPostCode"}
                                        id={"shippingPostCode"}
                                        value={values.shippingPostCode}
                                        touched={touched.shippingPostCode}
                                        onChange={handleChange}
                                        isError={errors.shippingPostCode}
                                        width={"288px"}
                                    />
                                    {errors.shippingPostCode && <div className={s.error}>{errors.shippingPostCode}</div>}
                                </div>
                                <div className={s.inputBlock}>
                                    <span> <span style={{ color: '#FF6471' }}>*</span>
                                        Delivery Method</span>
                                    <CustomDropdown items={['Standard', 'Registered']} id={"deliveryMethod"}
                                        name={"deliveryMethod"} isError={errors.deliveryMethod}
                                        touched={touched.deliveryMethod}
                                        placeholder={"Select shipping method"} />
                                    {errors.deliveryMethod && touched.deliveryMethod &&
                                        <div className={s.error}>{errors.deliveryMethod}</div>}
                                </div>
                            </div>

                            <div className={s.formTitle}>
                                Deposit Details
                            </div>

                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Select a currency</span>
                                    <CustomDropdown
                                        items={availableCurrencies}
                                        id="transferCurrency"
                                        name="transferCurrency"
                                        touched={touched.transferCurrency}
                                        disable={availableCurrencies?.length === 1}
                                        placeholder={availableCurrencies?.length === 1 ? availableCurrencies[0] : ''}
                                        onChange={(val) => val && setSelectedCurrency(val)}
                                        isError={errors.transferCurrency}
                                    />
                                </div>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Account
                                        <Popover content={"Accounts with balance equal to 0 can’t be chosen."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}>
                                            <img src={info} alt="" />
                                        </Popover></span>
                                    <CustomDropdownForAccounts
                                        items={availableCurrencies?.length === 1 ? getFilteredAccounts() : filteredAccounts}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        disableZeroBalance={true}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error}>{errors.payerAccount}</div>}
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.inputBlock}>
                                    <span><span style={{ color: '#FF6471' }}>*</span>
                                        Amount to Load</span>
                                    <MyInput
                                        name={"amount"}
                                        id={"amount"}
                                        value={values.amount}
                                        touched={touched.amount}
                                        isError={errors.amount}
                                        onChange={handleChange}
                                        width={"288px"}
                                    />
                                    {touched.amount && errors.amount && <div className={s.error}>{errors.amount}</div>}
                                </div>
                            </div>

                            <div className={s.infoRow}>
                                <span style={{ color: '#FF6471' }}>*</span> required fields. <br />
                                <div className={s.formSpan}>
                                    Please check your profile information. If needed, please
                                    <NavLink to={`/personal_account/personal/settings`}>
                                        <div className={s.link}> Edit Your Profile.
                                        </div>
                                    </NavLink>
                                </div>
                            </div>

                            <div className={s.buttonsWrapper}>
                                <MyBtn
                                    large
                                    style={{ width: 180 }}
                                    title={"Agree"}
                                    type={"submit"}
                                    onClick={() => console.log(errors)}
                                />
                                <TransparentButton
                                    title={"Cancel"}
                                    style={{ width: 180 }}
                                    large
                                    icon={<Icon component={() => <Back />} rev={undefined} />}
                                    onClick={onCancelClick}
                                    type={'button'}
                                />
                            </div>

                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default VirtualCardRequest;
