import React from 'react';
import s from "../LegalAndPrivacy.module.css";
import classnames from "classnames";
import {brandConfig} from '../../../../config/config';

const FirstPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                1. Introduction
            </div>

            <div className={s.point_subtitle}>
                1.1. Purpose
            </div>

            <div className={s.point_text}>
                1.1.1. <b>{brandConfig.companyName}</b> (hereinafter referred to as the "MSB" or
                " <b>{brandConfig.companyName}</b> ")
                is committed to operating its business in compliance with applicable
                regulatory
                requirements related to applicable sanctions requirements and Anti-Money
                Laundering/Anti-Terrorist Financing (collectively referred to as
                "AML/ATF" or "AML")
                requirements. <b>{brandConfig.companyName}</b> is required to develop, implement and
                maintain
                an effective AML/ATF Compliance Program dedicated to complying with the
                letter and spirit of Canada's AML/ATF legislation and associated
                guidance
                issued by the Office of the Superintendent of Financial Institutions
                ("OSFI"), the Financial Transactions and Reports Analysis Centre of
                Canada
                ("FINTRAC"), applicable economic sanctions requirements, the Criminal
                Code
                of Canada and the provisions set out in the Proceeds of Crime (Money
                Laundering)
                and Terrorist Financing Act ("PCMLTFA") and its related Regulations
                (collectively referred to as "regulatory requirements"). The AML/ATF
                and Sanctions Compliance Policy (the "Policy), in conjunction with
                <b> {brandConfig.companyName}</b>’s AML/ATF Compliance Program Operating Directive,
                AML/ATF Risk-Based
                Approach Methodology, <b>{brandConfig.companyName}</b>’s Risk Management Framework
                ("RMF"),
                {brandConfig.companyName}'s Risk Appetite Statement ("RAS”), Economic Sanctions and
                Suppression of Terrorism Operating Directive, and <b>{brandConfig.companyName}</b>'s
                Regulatory
                Compliance Management ("RCM") Policy and Framework.
            </div>

            <div className={s.point_text}>
                1.1.2. This Policy sets out <b>{brandConfig.companyName}</b>'s commitments and key
                accountabilities
                to manage and mitigate AML/ATF risk. Refer to the AML/ATF Compliance
                Program Operating Directive for details on how <b>{brandConfig.companyName}</b> will
                carry
                out these responsibilities.
            </div>

            <div className={s.point_text}>
                1.1.3. The Chief Anti-Money Laundering Officer ("CAMLO") of <b>{brandConfig.companyName}</b>
                is the officer responsible for governance, oversight and assessment
                of the AML Compliance Program as further described in this Policy. The
                AML team is part of the Compliance team at <b>{brandConfig.companyName}</b> and the
                CAMLO
                reports to the Chief Compliance Officer ("CCO”). The CAMLO may delegate
                authority to various positions within the <b>{brandConfig.companyName}</b> Compliance. These
                positions are subject to change at the discretion of the CAMLO.
            </div>

            <div className={s.point_subtitle}>
                1.2. Scope
            </div>

            <div className={s.point_text}>
                1.2.1. This Policy applies to all employees, contractors and agents
                approved
                to act on behalf of <b>{brandConfig.companyName}</b>. Employees of <b>{brandConfig.companyName}</b> who violate
                AML/ATF laws or regulations may be subject to disciplinary action up to
                and including termination of employment and may be referred to law
                enforcement
                or regulatory officials in accordance with legal and regulatory
                requirements.
                Such individuals may face penalties including fines and/or imprisonment.
            </div>

            <div className={s.point_text}>
                1.2.2. All employees and authorized agents acting on behalf of <b>{brandConfig.companyName}</b>
                are required to review this Policy and related procedures, receive
                AML/ATF
                training prior to dealing with clients, are expected to have a clear
                understanding of their accountabilities relating to combatting money
                laundering and terrorist financing ("ML/TF*) and must adhere to all
                related compliance policies as required by <b>{brandConfig.companyName}</b>'s Code
                of Business
                Conduct and Ethics.
            </div>

            <div className={s.point_subtitle}>
                1.3. References
            </div>

            <div className={s.point_text}>
                (i) <b>{brandConfig.companyName}</b> Code of Conduct. <br/>
                (ii) <b>{brandConfig.companyName}</b> RMF and RAS.<br/>
                (iii) <b>{brandConfig.companyName}</b> AML/ATF Operating Directive.<br/>
                (iv) <b>{brandConfig.companyName}</b>'s Economic Sanctions and Terrorist Financing
                Operating Directives and related guidiance.<br/>
                (v) Risk Based Approach ("RBA") and related procedures.<br/>
                (vi) <b>{brandConfig.companyName}</b> RCM and Operating Procedures.<br/>
                (vii) <b>{brandConfig.companyName}</b> Operational Risk Management Policy.<br/>
                (viii)PCMLTFA.<br/>
                (ix) OSFI B8.<br/>
                (x) FINTRAC Guidelines.<br/>
            </div>

            <div className={s.point_subtitle}>
                1.4. Definitions
            </div>

            <div className={s.point_text}>
                1.4.1. <b>Affiliate</b>: Affiliate relationships are defined as when a
                foreign
                or domestic entity is wholly owned by another, both entities are wholly
                owned by the same entity or if an entities financial statements are
                consolidated with another entities. Per the PCMLTFR, the parent company
                <b> MFT</b> is defined as an affiliate entity as <b>{brandConfig.companyName}</b> carries out similar
                business and is wholly owned by <b>{brandConfig.companyName}</b>. All the retail
                locations
                and subsidiaries of <b>{brandConfig.companyName}</b> are also considered affiliates.
            </div>

            <div className={s.point_text}>
                1.4.2. <b>AML/ATF (or AML) Compliance Program</b>: The program that sets
                out
                that <b>{brandConfig.companyName}</b> is committed to operating its business in
                compliance
                with applicable regulatory requirements related to applicable sanctions
                requirements and Anti-Money Laundering / Anti-Terrorist Financing
                (collectively referred to as "AML/ATF" or "AML" requirements. This
                Policy
                sets out the AML/ATF Compliance Program requirements to mitigate and
                manage risk posed by Money Laundering / Terrorist Financing ("ML/TF")
                and Sanctions.
            </div>

            <div className={s.point_text}>
                1.4.3. <b>AML/ATF (or AML) risk</b>: The risk <b>{brandConfig.companyName}</b> is
                susceptible to
                ML/TF and Sanctions risk and/or the failure to comply with regulatory
                requirements.
            </div>

            <div className={s.point_text}>
                1.4.4. <b>Audit Conduct and Review Committee ("ACRC")</b>: A committee
                of
                <b> {brandConfig.companyName}</b> Board of Directors that oversees the AML
                Compliance Program.
            </div>

            <div className={s.point_text}>
                1.4.5. <b>Bearer shares</b>: Negotiable instruments that accord
                ownership in a
                legal entity to the person who possesses the physical bearer share
                certificate. <b>{brandConfig.companyName}</b> is prohibited from doing business with
                customers
                with issued, or the ability to issue, Bearer Shares.
            </div>

            <div className={s.point_text}>
                1.4.6. <b>Chief Anti Money Laundering Officer ("CAMLO")</b>: The officer
                responsible
                for governance, oversight, developing, implementing, and maintaining an
                effective risk-based AML/ATF Compliance Program as further described in
                this Policy. Chief Compliance Officer ("CCO"): is responsible for <b>{brandConfig.companyName}</b>’s
                overall compliance with regulatory requirements and oversees the CAMLO
                and the AML Compliance Program.
            </div>

            <div className={s.point_text}>
                1.4.7. <b>Chief Risk Officer ("CRO”)</b>: Is part of the Second Line of
                Defence
                and is responsible for Risk Management at <b>{brandConfig.companyName}</b>.
            </div>

            <div className={s.point_text}>
                1.4.8. <b>Financial Action Task Force ("FATF")</b>: An agency
                organization that
                develops policies-to combat money laundering.
            </div>

            <div className={s.point_text}>
                1.4.9. <b>Financial Transactions Reports and Analysis Centre of Canada
                ("FINTRAC”)</b>:
                The financial intelligence unit for Canada and is the enforcement agency
                to ensure reporting entities are adhering to Canadian AML requirements.
            </div>

            <div className={s.point_text}>
                1.4.10. <b>First Line of Defence</b>: Business line management is the
                first line
                of defence, and is charged with overseeing a specific department, unit,
                or function within <b>{brandConfig.companyName}</b>, owns risk, and is responsible
                for:
                identifying and assessing the risks inherent in their business line or
                function; ownership of those risks; and developing and implementing the
                required controls to treat (manage) those risks.
            </div>

            <div className={s.point_text}>
                1.4.11. <b>Heads of International Organizations ("HIOs")</b>: A person
                entrusted
                with a prominent position that typically comes with the opportunity to
                influence decisions and the ability to control resources, which can make
                them vulnerable to corruption.
            </div>

            <div className={s.point_text}>
                1.4.12. <b>Internet Gambling</b>: A person or entity engaged in the
                business of
                providing internet games of chance (including, on-line card games,
                roulette,
                slots or similar on-line casino-type gaming) for profit. <b>{brandConfig.companyName} </b>
                is prohibited from doing business with individuals or entity) whose
                business consists of an internet gambling service or site.
            </div>

            <div className={s.point_text}>
                1.4.13. <b>Money Laundering ("ML”)</b>: any act, or attempted act, to
                conceal
                or disguise the identity of illegally obtained proceeds so that they
                appear to have originated from legitimate sources.
            </div>

            <div className={s.point_text}>
                1.4.14. <b>Money laundering / Terrorist Financing (“ML/TF”) Risk</b>:
                The risk
                the <b>{brandConfig.companyName}</b> is suspectable to ML/TF activities.
            </div>

            <div className={s.point_text}>
                1.4.15. <b>Money Service Business (“MSB")</b>: A person or entity
                engaged in the
                business of foreign currency business dealings, funds remittance or
                transmission or the issuing of redemption of financial instruments or
                dealing with virtual currency and as per local jurisdictional
                requirements.
                MSB’s are required to be registered with FINTRAC in order for <b>{brandConfig.companyName} </b>
                to onboard those customers.
            </div>

            <div className={s.point_text}>
                1.4.16. <b>Office of Foreign Assets Control ("OFAC")</b>: A financial
                intelligence
                and enforcemer agency of the U.S. Treasury Department to administer and
                enforce economic trade sanctions.
            </div>

            <div className={s.point_text}>
                1.4.17. <b>Office of the Superintendent of Financial Institutions
                (“OSFI”)</b>:
                The Federal Government Agency that regulates and supervises regulated
                financial institutions in Canada.
            </div>

            <div className={s.point_text}>
                1.4.18. <b>Politically Exposed Persons / Politically Exposed Foreign
                Persons
                ("PEP/PEFP")</b>: An individual who is or has been entrusted with a
                prominent
                public function, which function can potentially be abused for the
                purposes
                of ML/TF.
            </div>

            <div className={s.point_text}>
                1.4.19. <b>Proceeds of Crime (Money Laundering) & Terrorist Financing
                Act
                ("PCMLTFA")</b>: The objective of the act is to implement specific
                measures
                to detect and monitor ML/TF activities to facilitate the investigations
                or prosecution of ML/TF offences.
            </div>

            <div className={s.point_text}>
                1.4.20. <b>Regulatory Compliance Management ("RCM”)</b>: {brandConfig.companyName}'s
                process
                for regulatory compliance management, which is the Organizations set of
                key controls through which <b>{brandConfig.companyName}</b> manages its RCM Risk.
            </div>

            <div className={s.point_text}>
                1.4.21. <b>Risk Appetite Statement ("RAS”)</b>: Sets out <b>{brandConfig.companyName}</b>’s risk
                tolerance for, among other risks, ML/TF risk.
            </div>

            <div className={s.point_text}>
                1.4.22. <b>Risk Based Approach ("RBA”)</b>: {brandConfig.companyName} has adopted a
                risk-based
                approach ("RBA") to identify and assess inherent ML/TF risks and develop
                strategies to mitigate them. This allows the organisation to focus
                resources
                where they are most needed to manage risks within the tolerance levels
                outlined in the RAS.
            </div>

            <div className={s.point_text}>
                1.4.23. <b>Sanctions Risk</b>: The risk <b>{brandConfig.companyName}</b> is
                suspectable to not
                adhering to requirementsto prohibit or restrict dealing with specified
                groups, entities, individuals, governments, countries, regions,
                services,
                products or services that are enacted by legislation, regulation or
                other
                binding instruments.
            </div>

            <div className={s.point_text}>
                1.4.24. <b>Second Line of Defence</b>: Provides guidance and effective
                independent
                oversight and challenge of the first line. Together with the CCO and the
                CRO, the CAMLO is the second line of defence for Regulatory Compliance
                at
                <b> {brandConfig.companyName}</b>.
            </div>

            <div className={s.point_text}>
                1.4.25. <b>Shell entity or shell financial institution</b>: An entity or
                financial
                institution (including banks and non-banks financial institutions) that
                does not have physical presence or a place of business in any country
                and/or is not a regulated financial institution nor controlled by a
                Regulated Financial Institution that maintains a physical presence in a
                country. <b>{brandConfig.companyName}</b> is prohibited from doing business with
                Shell
                entities/financial institutions.
            </div>

            <div className={s.point_text}>
                1.4.26. Suspicious Transaction Reports (“STRs”): Reports filed to
                FINTRAC
                indicating {brandConfig.companyName} 's reasonable grounds to suspect ML/TF
                activity.
            </div>

            <div className={s.point_text}>
                1.4.27. <b>Terrorist Financing (“TF”)</b>: The collection, possession,
                provision,
                contribution, or solicitation of funds intended to be used to carry out
                or facilitate a terrorist activity or benefit a terrorist or terrorist
                group (including persons designated by regulatory authorities as
                terrorists
                or terrorist groups).
            </div>

            <div className={s.point_text}>
                1.4.28. <b>Third Line of Defence</b>: The third line of defense to
                manage the
                risks of <b>{brandConfig.companyName}</b> and the Internal Audit function. Internal
                Audit
                is an independent assessment that will verify and validate the design
                and effectiveness of the <b>{brandConfig.companyName}</b>'s AML Compliance Program.
            </div>

            <div className={s.point_title}>
                2. AML/ATF Commitments
            </div>

            <div className={s.point_text}>
                2.1. <b>{brandConfig.companyName}</b> will comply with all applicable regulatory
                requirements
                and has established a program to deter and detect ML/TF and sanctions
                activities and to manage ML/TF and sanctions risk that <b>{brandConfig.companyName}</b> may
                be susceptible to. <b>{brandConfig.companyName}</b> is committed to:
            </div>

            <div className={s.point_text}>
                i) complying with applicable AML/ATF regulatory requirements. <br/>
                ii) employing reasonable efforts to deter and detect ML/TF activities
                within <b>{brandConfig.companyName}</b> operations. <br/>
                iii) refusing to engage in activity with individuals and/or entities who
                are subject to sanctions (those listed on OSFI consolidated watch-lists
                of Designated Persons, the U.S Department of the Treasury's Office of
                Foreign Assets Control ("OFAC") Specially Designated Nationals ("SDN")
                List and other applicable government sanctions lists, including known or
                suspected terrorists); <br/>
                iv) identifying all new business relationships in accordance with
                regulatory requirements and ensure prohibited clients are not onboarded
                prior to approving a new business relationship. <br/>
                v) implementing and maintaining a risk assessment methodology that
                supports the evaluation of AML Compliance Program Risks and informs the
                areas (clients, products or services, delivery channels, transactions,
                new development and technology, affiliates, and geographies) and
                Sanctions Risks of greater exposures. <b>{brandConfig.companyName}</b> must address
                areas of higher risk, through the implementation of enhanced measures,
                allocate resources to focus on areas of greater ML/ TF and Sanctions
                vulnerability and operate in compliance with principles outlined in the
                AML and Sanctions Risk Assessment Program (see Section 4.2). <br/>
                vi) identifying and reporting required information / activity to FINTRAC
                and/or regulators /law enforcement as required. <br/>
                vii) implementing and documenting an AML/ATF/Sanctions risk management
                program (referred to as the AML/ATF Compliance Program - see AML/ATF
                Compliance Program Operating Directive and Economic Sanctions &
                Suppression of Terrorism Operating Directive for additional
                details). <br/>
                viii) appointing a CAMLO responsible for oversight of <b>{brandConfig.companyName}</b>’s
                AML/ATF and Sanctions Compliance Program. <br/>
                ix) Providing sufficient resources to the CAMLO for effective management
                of the AML/ATF and Sanctions Compliance Program. <br/>
                x) documenting the assessment of <b>{brandConfig.companyName}</b>'s inherent risks
                as it
                relates to ML/TF and sanctions and the self-assessment of applicable
                controls. <br/>
                xi) documenting the AML/ATF and Sanctions Policy that is reviewed and
                updated at least annually and approved by the Audit and Conduct Review
                Committee ("ACRC") and the Board. <br/>
                xii) documenting and providing ongoing AML/ATF and Sanctions compliance
                training for the Board, Senior Management, employees and agents acting
                on <b>{brandConfig.companyName}</b>’s behalf. <br/>
                xiii) reporting and oversight to ensure sufficient, pertinent, and
                timely communication to Senior Management, and to the Board respecting
                AML and Sanctions Compliance Program Risk management effectiveness,
                including material framework weaknesses, instances of noncompliance and
                related remedial action plans, material exposures to regulatory risk,
                significant results of monitoring and findings of AML and Sanctions
                Compliance Program oversight: <br/>
                xiv) maintenance of adequate documentation to demonstrate how AML
                Compliance Program Risk is managed and to support the flow of reports to
                Senior Management and the Board: <br/>
                xv) conducting an annual assessment of the adequacy and effectiveness of
                day-to-day controls that manage ML/TF and sanctions risk throughout
                <b> {brandConfig.companyName}</b> as outlined in the RCM framework: <br/>
                xvi) ensuring that an independent review of the 's AML/ATF Compliance
                Program is conducted every two years (at a minimum to test the
                effectiveness of the controls related to ML/TF and sanctions; and <br/>
                xvii) ensuring that an independent review and internal audit of the
                <b> {brandConfig.companyName}</b>’s compliance
            </div>

            <div className={s.point_title}>
                3. Roles and Accountabilities
            </div>

            <div className={s.point_subtitle}>
                3.1. Three Lines of Defense
            </div>

            <div className={s.point_text}>
                3.1.1. To support the design and implementation of the RCM Framework,
                <b> {brandConfig.companyName}</b> has instituted a Three Lines of Defence model.
                Following
                are the assigned responsibilities under the model and as they pertain to
                AML/ATF governance.
            </div>

            <div className={s.point_text}>
                3.1.2 <b>First Line</b>: The Risk Owners are accountable for financial
                and non-financial
                risks generated by activities. The First Line is responsible to
                identify,
                assess, measure, manage, and mitigate risk within risk appetite
                boundaries
                and in compliance with regulations and internal policies & procedures.
                The first line is responsible for executing internal controls.
            </div>

            <div className={s.point_text}>
                3.1.3. <b>Second line</b>: The CAMLO, the CCO and the CRO as the Second
                Line of
                Reference. provides risk oversight and challenge. The second Line
                establishes,
                recommends, and embeds the building blocks of the Risk Management
                framework
                and the AML Program. The second line is responsible for monitoring,
                assessment
                and reporting the risks.
            </div>

            <div className={s.point_text}>
                3.1.4. <b>Third line</b>: The Third Line of Defence, our internal audit
                function,
                provides independent assessment of the design and operating
                effectiveness
                of risk governance, controls, processes, and systems used by 1st and 2nd
                line of defense. Internal Audit will verify and validate the
                effectiveness
                of <b>{brandConfig.companyName}</b>'s AML Compliance Program.
            </div>

            <div className={s.point_text}>
                3.1.5. <b>{brandConfig.companyName}</b>’s key stakeholders have roles in carrying
                out AML/ATF
                commitments and the effective management of AML/ATF risk. All employees,
                through their individual roles and responsibilities, contribute to
                maintaining
                an effective AML Compliance Program. The following are specific
                authorities,
                accountabilities, and responsibilities:
            </div>

            <div className={s.point_subtitle}>
                3.2. Board Oversight
            </div>

            <div className={s.point_text}>
                3.2.1. The Board is required to have the appropriate level of oversight
                and have a clear understanding of their accountabilities per OSFI
                Corporate
                Governance Guideline, OSFI Guideline B-8 Deterring and Detecting Money
                Laundering and Terrorist Financing ("OSFI B-8" and the PCMLTA (and
                related
                Regulations). The Board fulfills its mandate for oversight of the
                AML/ATF
                Compliance Program through the ACRC Specifically, the ACRC is
                responsible
                for, at a minimum, the following:
            </div>

            <div className={s.point_text}>
                i) approving this AML/ATF and Sanctions Policy and ensuring that the
                AML/ATF Compliance Program outlined in it are established and
                maintained. <br/>
                ii) appointing the CAMLO and ensuring appropriate authority for the
                AML/ATF Compliance Program is assigned to the CAMLO and Senior
                Management. <br/>
                iii) receiving regular (at least quarterly) reports from the CAMLO on
                the design and operation of the AML/ATF Compliance Program. <br/>
                iv) receiving reports from Internal Audit on effectiveness testing of
                {brandConfig.companyName}’s AML/ATF Compliance Program; and <br/>
                v) acquiring a sound understanding of AML matters through briefings,
                reports (including but not limited to, reports of the CAMLO, issues
                identified by Internal Audit as part of its effectiveness testing) and
                training, as appropriate. <br/>
            </div>

            <div className={s.point_subtitle}>
                3.3. Executive Committee
            </div>

            <div className={s.point_text}>
                3.3.1. The Executive Committee has the accountability to ensure that
                risk
                management practices and procedures are implemented in accordance with
                the risk management policies and processes. This includes the
                responsibility
                to ensure that the AML/ATF Compliance Program, outlined in this Policy
                and associated procedures are adequate to manage ML/TF and sanctions
                risk.
            </div>

            <div className={s.point_subtitle}>
                3.4. Chief Compliance Officer
            </div>

            <div className={s.point_text}>
                3.4.1. The CCO as part of the second line of defence, is accountable for
                the following:
            </div>

            <div className={s.point_text}>
                i) Recommending the appointment of the CAMLO. <br/>
                ii) Ensuring the CAMLO is independent from operational management and
                has unfettered access to the Chair of the ACRC. <br/>
                iii) Ensuring the CAMLO has adequate resources to execute his/her
                mandate. <br/>
                iv) Ensuring significant recommendations and escalations of the CAMLO
                are acted upon. <br/>
                v) Ensuring the AML Compliance Program reporting to the ACRC and to the
                Board or its committees by the CAMLO is sufficient and appropriate;
                and <br/>
                vi) Ensuring the CAMLO has unfettered access to, and direct
                communications with, Senior Management, the Board, and respective
                committees.
            </div>

            <div className={s.point_subtitle}>
                3.5. Chief Anti Money Laundering Officer
            </div>

            <div className={s.point_text}>
                3.5.1. Together with the CCO and the CRO, the CAMLO is the second line
                of defence for Regulatory Compliance at <b>{brandConfig.companyName}</b>. The CAMLO
                is in
                charge of overseeing, developing, implementing and maintaining an
                effective
                risk-based AML/ATF Compliance Program. The primary role of the CAMLO is
                to be responsible for <b>{brandConfig.companyName}</b>'s compliance with AML/ATF
                legislation,
                regulation, regulatory guidance and the broader prudential risk
                management
                of internally established policies and procedures regarding compliance.
            </div>

            <div className={s.point_text}>
                3.5.2. The CAMLO will have the necessary mandate, resources and access
                to the Chief Executive Officer ("CEO*), the Board of Directors (the
                "Board")
                and the Board Committees as may be required to satisfactorily perform
                this
                Mandate.
            </div>

            <div className={s.point_text}>
                3.5.3. The CAMLO is responsible for this Policy and associated
                procedures,
                the overall AML/ATF Compliance Program and ensuring the <b>{brandConfig.companyName}</b>'s
                ongoing compliance with all applicable AML/ATF regulations.
            </div>

            <div className={s.point_text}>
                3.5.4. in addition, the CAMLO is accountable for the following:
            </div>

            <div className={s.point_text}>
                i) Ensuring adequate and effective controls are implemented and applied
                throughout <b>{brandConfig.companyName}</b>: <br/>
                ii) Updating written AML/ATF and Sanctions policies and procedures and
                obtaining approvals as applicable: <br/>
                iii) Developing and implementing AML/ATF and Sanctions risk assessment
                methodologies (including to assess client risk) and ensuring the
                methodology is applied to <b>{brandConfig.companyName}</b>'s processes and systems
                consistently as applicable.<br/>
                iv) Keeping {brandConfig.companyName} 's AML/ATF Compliance Program current relative
                to the <b>{brandConfig.companyName}</b>'s inherent risks (clients and business
                relationships, products and delivery channels, geographic locations of
                activity, and any other relevant factors).<br/>
                v) Assessing all key changes (e.g., initiatives, organizational,
                accountabilities, scope of business, etc.) which may have an impact on
                Money Laundering, Terrorist Financing or Sanctions compliance.<br/>
                vi) Ensuring client due diligence, enhanced due diligence and ongoing
                monitoring processes are in place.<br/>
                vii) identifying sufficient resources to detect and report attempted and
                completed suspicious transactions and activities.<br/>
                viii) Reviewing and assessing reportable transactions to FINTRAC and
                ensuring they are reported to FINTRAC as required.<br/>
                ix) Ensuring sanctions measures and processes are in place and operating
                effectively, to identify and assess politically exposed persons
                ("PEPs"), Designated Persons and known or suspected terrorists.<br/>
                x) Identifying any clients operating in prohibited countries or
                associated with a Designated Person and either declining to approve them
                as a new client or, for an existing client, presenting them for
                demarketing to the Management Risk Committee:<br/>
                xi) Promoting and maintaining <b>{brandConfig.companyName}</b>'s AML/ATF training
                program and training plan for employees, agents (as applicable) Senior
                Management and the Board.<br/>
                xii) Developing and implementing quality assurance and self-assessment
                of controls carried out at least annually.<br/>
                xiii) Escalating material AML/ATF issues and providing recommendations
                to Senior Management and the Board on AML/ATF Compliance matters, as
                applicable:<br/>
                xiv) Ensuring that the <b>{brandConfig.companyName}</b>'s Internal Auditor is aware
                of their requirement to conduct effectiveness testing of the AML/ATF
                Compliance Program at least every two years.<br/>
                xv) Dealing with regulator and law enforcement requests relating to
                ML/TF and Sanctions.<br/>
                xvi) Providing regular (at least quarterly) reporting to Senior
                Management and the Board and ensuring that the information disseminated
                is accurate and adequately communicated.<br/>
                xvii)Developing an Annual Plan to conduct these activities and
                presenting the Annual Plan to the Board for approval.<br/>
                xviii) Responding to OSFI and FINTRAC in relation to AML/ATF matters, as
                required:<br/>
                xix) Providing guidance on AML/ATF initiatives and regulatory
                developments:<br/>
                xx) Maintaining up to date information on applicable AML/ATF regulatory
                requirements, updating relevant documentation and procedures and
                communicating to relevant stakeholders; and<br/>
                xxi) Ensuring records are maintained in accordance with regulatory
                expectations and the <b>{brandConfig.companyName}</b> Records Retention Policy.
            </div>

            <div className={s.point_text}>
                3.5.5. The CAMLO may delegate any of the above as appropriate to the
                first
                line of defense or within the Compliance team. While certain duties may
                be delegated, the CAMLO
            </div>

            <div className={s.point_subtitle}>
                3.6. Senior Management Oversight
            </div>

            <div className={s.point_text}>
                3.6.1. Business lines owners are the first line of defence charged with
                overseeing a specific department, unit, or function within <b>{brandConfig.companyName} </b>
                and is responsible for: identifying the risks inherent in their business
                line or function; ownership of those risks; and developing and
                implementing
                the required controls to mitigate those risks.
            </div>

            <div className={s.point_text}>
                3.6.2. <b>{brandConfig.companyName}</b>'s Senior Management is responsible for the
                day-to-day
                implementation and management of the AML/ATF Compliance Program. This
                includes ensuring that the AML/ATF Compliance Program is adequate,
                complies
                with applicable legislation and is implemented effectively. Senior
                Management will ensure that:
            </div>

            <div className={s.point_text}>
                i) The CAMLO has unfettered access to, and direct communications with
                the Chair of the ACRC, Senior Management and the Board. <br/>
                ii) The CAMLO unfettered access to all pertinent information, records,
                and personnel throughout {brandConfig.companyName}. <br/>
                iii) The CAMLO has access to adequate resources in terms of people, data
                management systems and budget to implement and administer the AML/ATF
                Compliance Program requirements effectively and to offer objective
                opinions or advice to Senior Management and the Board.<br/>
                iv) Applicable staff have clear and documented responsibility and
                accountability for AML/ATF Compliance Program implementation in all
                relevant business areas of operation, and sufficient resources to manage
                program implementation effectively.<br/>
                v) ML/TF and Sanctions risk are identified and assessed, and adequate
                documentation is available:<br/>
                vi) The CAMLO is advised of any new developments or significant changes
                in initiatives, products, delivery channels, client types, geographies,
                technology, and operational procedures to ensure they are adequately
                addressed by the AML Compliance Program, including, for example, of any
                expansion of business, office location, services, products, or clients
                to other jurisdictions. through the Change Management Policy:<br/>
                vii) With the CAMLO's support, adequate day-to-day AMLATE controls are
                developed and implemented to mitigate MLATF and Sanctions risks.<br/>
                viii) All significantrecommendations in respect of AML/ATF Compliance
                Program issues and controls made by the CAMLO, Internal Audit and Senior
                Management are acted upon in a timely manner; and<br/>
                ix) Assessment of ML/TF and sanctions risk and the adequacy and
                operational effectiveness of controls in managing that risk is conducted
                on a regular basis using a risk-based approach.<br/>
            </div>

            <div className={s.point_subtitle}>
                3.7. Employee Accountability
            </div>

            <div className={s.point_text}>
                3.7.1. Protecting the <b>{brandConfig.companyName}</b> from being inadvertently used
                for ML
                TF or contrary to Sanctions is the responsibility of every employer
                regardless of level of seniority or role within <b>{brandConfig.companyName}</b>.
                Employees
                must adhere all requirements outlined in this AML Policy and the
                Operating
                Directive Labilities include the following:
            </div>

            <div className={s.point_text}>
                i) Employees must collect required client information. <br/>
                ii) Employees must report information, situations activities transaction
                or attempted transactions that are unusual of any type of financial
                product was completed, and for non-clients as well as clients. Employees
                must promptly report the unusual activity to the CAMLO for review.<br/>
                iii) Employees must not inform or otherwise make clients aware that the
                client or other clients are or may be the subject of an Unusual
                Transaction Report or that a Suspicious Transaction Report ("STR") has
                been or may be filed. Employees should be guided by <b>{brandConfig.companyName}</b>'s
                Code of Conduct, and exercise extreme caution with information relating
                to STRs:<br/>
                iv) Employees must not provide any financial service or advice contrary
                to applicable AML Compliance Program requirements. Additionally,
                Employees must not promote products or services, or structure
                transactions or alter information, purposely designed to assist anyone
                to evade AML Compliance Program requirements; and<br/>
                v) Employees must complete the annual AML training and any other
                training required to fulfill their AML mandate.
            </div>

            <div className={s.point_subtitle}>
                3.8 Internal Audit
            </div>

            <div className={s.point_text}>
                3.8.1. Internal Audit, as the third line of defense, is accountable for
                the providing independent Internal Audit, as the third line of defense,
                is accountable for providing independent assessments as to the
                effectiveness
                of internal controls relating to key components of the AML Compliance
                Program, including:
            </div>

            <div className={s.point_text}>
                i) Conducting independent testing of the AML Compliance Program
                controls, including testing to ensure {brandConfig.companyName}'s compliance with
                applicable regulatory requirements. <br/>
                ii) Assessing the effectiveness and adequacy of systems, staffing and
                technology that support the AML Compliance Program:<br/>
                iii) Identifying and addressing potential and emerging concerns in the
                audit reports relating to the AML Compliance Program:<br/>
                iv) Conducting independent testing on an annual basis, of all aspects of
                the OFAC screening.<br/>
                v) Reporting results to the Board and senior Management.
            </div>

            <div className={s.point_title}>
                4. AML/ATF Compliance Program Overview
            </div>

            <div className={s.point_text}>
                4.1. <b>{brandConfig.companyName}</b> recognizes that the ML/TF and Sanctions
                techniques
                and methodologies continuously evolve at a rapid pace. <b>{brandConfig.companyName} </b>
                endeavours to adapt its processes to this evolving environment and has
                built an AML Compliance Program with the following key elements of the
                AML Compliance Program to effectively manage AML/ATF and Sanctions risk.
                Refer to the AML/ATF Compliance Program Operating Directive, the
                Economic Sanctions and Suppression of Terrorism Operating Directive, the
                ML/TF Risk-Based Approach ("RBA") Methodology and associated procedures
                for further details.
            </div>

            <div className={s.point_subtitle}>
                4.2. Regulatory Compliance Management
            </div>

            <div className={s.point_text}>
                4.2.1. <b>{brandConfig.companyName}</b> is required to conduct a thorough review of
                applicable
                regulatory requirements in order to ensure the controls are in place to
                mitigate regulatory risks applicable to <b>{brandConfig.companyName}</b>. The CAMLO
                oversees
                the AML/ATF Compliance Program and leverages the <b>{brandConfig.companyName}</b>'s
                RCM
                Framework to manage all AML/ATF-related components of the organization’s
                RCM program.
            </div>

            <div className={s.point_text}>
                4.2.2. <b>{brandConfig.companyName}</b>'s RCM Framework sets out the procedures to
                identify,
                risk assess, communicate, effectively manage, mitigate, and document
                regulatory requirements (including AML/ATF requirements) and maintain
                knowledge of applicable regulatory requirements. Further, the RCM
                Framework
                outlines procedures for annual planning, monitoring, and reporting.
                Refer to the RCM Policy for additional details.
            </div>

            <div className={s.point_subtitle}>
                4.3. Risk Assessment Program
            </div>

            <div className={s.point_text}>
                4.3.1. The ML/TF & Sanctions RBA Methodology is used to identify and
                assess
                inherent ML/TF including Sanctions risks and develop strategies to
                mitigate
                them. The risk assessment program supports the evaluation of AML
                Compliance
                Program Risks and informs the areas (clients, products or services,
                delivery
                channels, transactions, new developments and technology, foreign and
                domestic
                affiliates, and geographies) of greater exposure. <b>{brandConfig.companyName}</b> must address
                areas of higher risk, through the implementation of enhanced measures,
                allocate resources to focus on areas of greater ML, TF and Sanctions
                vulnerability and operate in compliance with principles outlined in the
                AML Compliance Program Risk Appetite Statement ("RAS"). <b>{brandConfig.companyName}</b>'s
                ML/TF RBA includes the following:
            </div>

            <div className={s.point_text}>
                i) Setting <b>{brandConfig.companyName}</b>'s AML/ATF risk tolerance with input from
                the CRO. <br/>
                ii) Identification of inherent ML/TF and Sanctions risks relevant to <b>{brandConfig.companyName}</b>. <br/>
                iii) Development of controls to mitigate identified inherent risks
                through
                the implementation of controls and measures tailored to these risks,
                which includes: <br/>
            </div>

            <div className={s.point_text}>
                1. keeping client identification information and, if required,
                beneficial ownership and business relationship information up to date in
                accordance with the assessed level of risk. <br/>
                2. ongoing monitoring of transactions and business relationships in
                accordance with the assessed level of risk: <br/>
                3. applying enhanced or special measures to those transactions and
                business relationships identified as high-risk: <br/>
                4. Evaluation of residual risks based on the controls in place;
                and <br/>
                5. Review of the RBA program on a periodic basis.
            </div>

            <div className={s.point_text}>
                4.3.2. <b>{brandConfig.companyName}</b>’s RBA methodology and inherent risk
                assessments
                considers a variety of relevant factors related to <b>{brandConfig.companyName}</b>'s
                business, including (but not limited to) those across the following
                areas in accordance with applicable FINTRAC, OSFI guidance and other
                applicable regulatory guidance (for e.g. OFAC's Compliance Commitments
                Framework). The approach for undertaking a risk assessment is divided in
                two parts:
            </div>

            <div className={s.point_text}>
                i) An assessment of business-based risk including.
            </div>

            <div className={s.point_text}>
                a) The products and services offered by the Ban {brandConfig.companyName} as well as
                the delivery channels through which they are offered. <br/>
                b) Geographic locations where {brandConfig.companyName} conducts its activities and
                the locations of its clients.<br/>
                c) New products, services, developments and technologies risk assessment
                and impact to the {brandConfig.companyName}.<br/>
                d) Foreign and domestic affiliates that carry out similar
                activities<br/>
                e) Sanctions exposure of our clients:<br/>
                f) {brandConfig.companyName}’s client types served, and their business relationships
                with {brandConfig.companyName}.<br/>
                g) other relevant factors related to {brandConfig.companyName}.
            </div>

            <div className={s.point_text}>
                ii) An assessment of the risk associated with each client that we have
                established a business relationship with (relationship based) on an
                ongoing
                basis including
            </div>

            <div className={s.point_text}>
                h) The products, services, and delivery channels <b>{brandConfig.companyName}</b>'s
                clients
                utilize.<br/>
                i) The geography related to <b>{brandConfig.companyName}</b> clients (their
                location, links
                to high-risk countries where they conduct their business and
                transactions,
                etc.).<br/>
                j) The new products, services, developments, and technologies made
                available
                to the clients:<br/>
                k) <b>{brandConfig.companyName}</b> client type, activities, transaction patterns,
                characteristics,
                etc.
            </div>

            <div className={s.point_text}>
                iii) The results of AML / ATF and Sanctions risk assessment are reported
                by the CAMLO to the Board of Directors and Senior Management on a (at
                minimum) annual basis.
            </div>

            <div className={s.point_subtitle}>
                4.4. AML Compliance Program Risk Appetite Statement
            </div>

            <div className={s.point_text}>
                4.4.1. <b>{brandConfig.companyName}</b> is committed to meeting our legislative and
                regulatory obligations, both in letter and spirit, and to safeguard the
                organization and our clients against Money Laundering, Terrorist
                Financing, and Sanction risks. <b>{brandConfig.companyName}</b> recognizes these
                risks are
                presented by our clients, products or services, delivery channels,
                transactions and geographies which are managed through <b>{brandConfig.companyName}</b>'s
                AML Compliance Program Operating Directive and our Risk Based Approach.
                {brandConfig.companyName} will not take on new AML Compliance Program Risks that are
                not understood or cannot be measured, managed, and monitored.
            </div>

            <div className={s.point_text}>
                4.4.2. <b>{brandConfig.companyName}</b>'s business strategy requires that some risks
                be taken
                to succeed, and to acknowledge this, <b>{brandConfig.companyName}</b> sets the AML
                Compliance
                Program Risk Appetite at moderate residual risk. This means <b>{brandConfig.companyName}</b>:
            </div>

            <div className={s.point_text}>
                i) accepts some business activities that may carry increased Money
                Laundering and Terrorist Financing Risks. <br/>
                ii) is willing to allow risks that can be mitigated in the normal course
                of business where these risks have little effect on capital, earnings,
                business opportunity or our reputation; and<br/>
                iii) adheres to applicable Sanctions Measures.
            </div>

            <div className={s.point_subtitle}>
                4.5. Written Policies and Procedures
            </div>

            <div className={s.point_text}>
                4.5.1. Written guidance and control documentation, which are kept
                current, support the development and implementation of an effective AML
                Compliance Program. The CAMLO is authorized to issue the AML/ATF and
                Sanctions Operating Directives and to provide other direction addressing
                regulatory compliance and risk management components of the AML
                Compliance Program, as required, which reflect the key principles and
                activities set out in this Policy.
            </div>

            <div className={s.point_subtitle}>
                4.6. Training
            </div>

            <div className={s.point_text}>
                4.6.1. As part of <b>{brandConfig.companyName}</b>‘s commitment to detecting and
                deterring
                ML/TF and manage sanction compliance, AML/ATF Compliance training is
                provided to all employees, the Board of Directors and Senior Management
                upon hire and at least annually thereafter.
            </div>

            <div className={s.point_text}>
                4.6.2. All employees, contractors and agents acting on behalf of
                <b> {brandConfig.companyName}</b> are required to complete sufficient AML/ATF
                compliance
                training specific to their role in order. to ensure staff across
                <b> {brandConfig.companyName}</b> are educated and aware of their accountabilities
                in
                relation to the requirements set out in this Policy.
            </div>

            <div className={s.point_subtitle}>
                4.7. Client Due Diligence (“CDD”) and Enhanced Due Diligence ("EDD")
            </div>

            <div className={s.point_text}>
                4.7.1. CDD is required to identify and verify client information and
                establish a reasonable belief that <b>{brandConfig.companyName}</b> knows the true
                identity
                of its clients, knows the type of business and transactions that the
                client is likely to undertake and understand the risk the client
                represents. The information obtained through the CDD process assists
                <b> {brandConfig.companyName}</b> in identifying higher risk clients, and therefore
                allocate
                resources to implement and execute a more effective and risk-based
                monitoring process and strategy for these clients. For some clients,
                additional due diligence, referred to as Enhanced Due Diligence (EDD),
                is required to ensure that <b>{brandConfig.companyName}</b> appropriately determines
                the
                nature and extent of the clients' risk and the acceptability of the
                relationship. <b>{brandConfig.companyName}</b> has established a robust onboarding
                process
                to ensure clients are identified and risk assessed in accordance with
                regulatory requirements, and the inherent risks of dealing with any
                potential relationship is in line with this Policy, the AML/ATF RBA and
                <b> {brandConfig.companyName}</b> Risk Appetite Framework.
            </div>

            <div className={s.point_text}>
                4.7.2. <b>{brandConfig.companyName}</b> has identified risk categories that are not
                acceptable per the <b>{brandConfig.companyName}</b>'s ML/TF BA, Risk Appetite
                Statement and
                associated Framework. As such, <b>{brandConfig.companyName}</b> is prohibited from
                entering
                a business relationship and/or conducting transactions for or on behalf
                of any of the following including, but not limited to:
            </div>

            <div className={classnames(s.point_text, s.underline)}>
                i) Prohibited client type / transaction type:
            </div>

            <div className={s.point_text}>
                a) Any client that cannot be identified in accordance with the PCMLTFR,
                whether using face-to-face or non-face-to-face methods, will be
                prohibited from establishing a business relationship with <b>{brandConfig.companyName}</b>. <br/>
                b) Any individual / retail clients (with the exception of the <b>{brandConfig.companyName}</b>'s employees and Directors)<br/>
                c) For entity clients where we have not been able to determine
                beneficial ownership, or we have not been able to confirm business
                registration.<br/>
                d) Any entity subject to the PCMLTFA with significant penalties and/or
                deficiencies that have not been rectified and/or whose FINTRAC (or other
                regulator) registration has expired or has been revoked.<br/>
                e) Any entity with significant reg pilatory penalties and/or issues
                noted that are not rectified which poses a heightened reputational
                and/or regulatory risk for <b>{brandConfig.companyName}</b> and is outside <b>{brandConfig.companyName}</b>'s AML/ATF risk tolerance.<br/>
                f) Entities without valid corporate registration documents and/or proof
                of identification or whose identification is questionable and/or cannot
                be identified.<br/>
                g) Any entity or related stakeholder where there is adverse news (fraud,
                financial crimes, criminal history) that would have a significant impact
                the inherent risk of the prospect and is outside <b>{brandConfig.companyName}</b>'s
                AML/ATF risk tolerance.<br/>
                h) Shell entities/corporations or shell financial institutions.<br/>
                i) MSBs and Foreign MSBs, or Virtual Currency and/or other applicable
                financial entities that are not registered with FINTRAC or not licensed
                by the Autorité des marchés financiers ("AMF”) in Quebec (if operating
                in Quebec).<br/>
                j) MSBs or Foreign MSBs that do not an active <b>{brandConfig.companyName}</b> account in a Financial Action Task Force ("FATF”) country.<br/>
                k) Third party payments for <b>{brandConfig.companyName}</b>'s MSB clients.<br/>
                l) MSB liquidity payments to high-risk countries.<br/>
                m) All transactions involving virtual currencies. n Online gaming and/or
                Internet gambling entities.<br/>
                n) Crowd funding platforms also if they are registered as MSBs with
                FINTRAC<br/>
                o) Escort services and pornography related distributions /
                creators.<br/>
                p) Arms and/or Weapons dealers or manufacturers<br/>
                q) Entities whose main business is the provision of services to any
                online or internet gambling business including software development,
                providers of technical support and payment processors primarily geared
                towards such businesses.<br/>
                r) Reporting Entities who have been registered with FINTRAC but who have
                not had an effectiveness review or regulatory audit conducted on their
                business.<br/>
                s) MSBs and Foreign MSBs that operate out of their residence.<br/>
                t) Unlicensed entities or where required license(s) has expired or
                revoked.<br/>
                u) Entities that issue bearer shares<br/>
                v) Foreign Financial Institutions and Central Banks whose primary
                location is in non-FATF Member countries that are moderate or high-risk
                countries. For low risk non-FATF countries see (v) below.<br/>
                w) Entities on OST's Designated Entities list, Special Economic Measures
                Act ("SEMA") lists and Office of Foreign Assets Control's ("OFAC's)
                sanctions lists.<br/>
                x) Entities that have associated individuals, where a positive name
                match screening against the OSFI Designated Persons list, SEMA
                watch-lists and/or OFAC's Specially Designated Nationals and Blocked
                Persons List is found and confirmed, Specific associations include
                individual stakeholders found to be beneficial owners of the entity,
                members of the entity's Board of Directors, officers, management team or
                any other related party identified at the time of onboarding and
                throughout the business relationship.<br/>
                y) Any associated individuals or entities whose name has been added to
                ministerial directives.
            </div>

            <div className={classnames(s.point_text, s.underline)}>
                (ii) Prohibited geographies:
            </div>

            <div className={s.point_text}>
                a) <b>{brandConfig.companyName}</b> will always refuse to provide services to
                clients doing
                business with any nation, entity or individual designated on sanctions
                or terrorisms list, or any nations on a restricted list referred or
                maintained by <b>{brandConfig.companyName}</b>: <br/>
                b) Any entity, individual and/or related stakeholder that is located. in
                a "Prohibited" country per <b>{brandConfig.companyName}</b>'s geographical inherent
                ML/TF
                risk assessment; prohibited countries are countries that are on the OSFI
                consolidated, UN Sanctions lists or OFAC Country Sanctions program.
            </div>

            <div className={classnames(s.point_text, s.underline)}>
                (iii) Prohibited delivery channels
            </div>

            <div className={s.point_text}>
                a) Accounts attempting to be opened by an agent / broker on behalf of
                the underlying dient.<br/>
                b) Transactions that are conducted on behalf of third parties which are
                not disclosed and/or where the relationship and/or source of funds is
                unclear or questionable.<br/>
                c) Transactions being undertaken by individuals who are not authorized
                signatories permitted to perform transactions for entity clients.<br/>
                d) <b>{brandConfig.companyName}</b> cannot receive deposits from Foreign MSBs.
            </div>

            <div className={classnames(s.point_text, s.underline)}>
                (iv) Internally black-listed clients
            </div>

            <div>
                a) <b>{brandConfig.companyName}</b> will not provide services to clients who we have
                black-listed in the past in relation to fraud, theft or other nefarious
                behavior including clients for whom we have not been able to determine
                further the client's source of funds or purpose of transactions for
                (please refer to Demarketing Relationships in the AML/ATF Operating
                Directives). <br/>
                b) We will refuse to continue providing services to a client in respect
                of whom we have filed three or more Suspicious Transaction Reports
                (STRs).
            </div>

            <div className={classnames(s.point_text, s.underline)}>
                (v) Restricted Client types, transactions and geographies
            </div>

            <div className={s.point_text}>
                a) Foreign Financial Institutions (Banks and Central Banks) that are
                located in low risk non-FATF countries are restricted with conditions as
                follows: o Non-FATF Countries require a Change Management Initiative,
                Executive and Board approvals, along with Enhanced Due Diligence from
                the CAMLO (site visit prior to onboarding, Governing Bodies evaluation,
                review of FAT country reports and ongoing monitoring of the countries
                and client). <br/>
                b) Wires to individuals may be permitted with Compliance review and
                approval, and with a stated business reason.<br/>
                c) MSBs whose primary business is a virtual currency, require a Change
                Management Initiative and appropriate approvals.<br/>
                d) Marijuana related industries require a Change Management Initiative
                and appropriate approvals.
            </div>

            <div className={s.point_subtitle}>
                4.8. Sanctions and Watchlist Screening
            </div>

            <div className={s.point_text}>
                4.8.1. Sanctions serve to further foreign policy objectives and are
                considered a viable alternative to military intervention to influence
                change in repressive regimes, or those with connections to terrorism,
                nuclear proliferation, or other threats to peace and security. Sanctions
                seek to prohibit or restrict access to funds or the financial system,
                trade and the associated financing of specific products or with specific
                parties or jurisdictions, or the provision of financial or related
                services to, or for the benefit of a country or anyone in that country.
            </div>

            <div className={s.point_text}>
                4.8.2. <b>{brandConfig.companyName}</b> is required to verify that it is not dealing
                with a
                known terrorist or a Sanctioned entity (individual or group) pursuant to
                the relevant section(s) of the Code. <b>{brandConfig.companyName}</b> is also
                required to
                ensure it is not dealing with any designated individual or entity on any
                other applicable watch-lists and is required to determine if it is
                dealing with a politically exposed person (foreign or domestic)
                ("PEP/PEFP) or a head of an international organization ("HIO”), or a
                family member or close associate of PEP/PEFP or HIO.
            </div>

            <div className={s.point_text}>
                4.8.3. All <b>{brandConfig.companyName}</b> clients and related stakeholders will be
                screened against all applicable watchlists to confirm the entity and
                related stakeholders are not subject to sanctions or is deemed a
                PEP/PEFP/HIO, their family member or a close associate. <b>{brandConfig.companyName} </b>
                employs an electronic system to conduct watch-list scanning of the
                entity and all associated stakeholders prior to onboarding and also on
                an ongoing basis as required.
            </div>

            <div className={s.point_subtitle}>
                4.9. Transaction Monitoring and Suspicious Transaction Reporting (STR)
            </div>

            <div className={s.point_text}>
                4.9.1. The reporting of suspicious activity/transactions by financial
                institutions is critical to a country's ability to utilize financial
                information to combat ML/F and other financial crimes. Transaction
                monitoring is a critical internal detective control whose purpose is to
                identify and protect <b>{brandConfig.companyName}</b> from transactions that may
                lead to
                Money Laundering or Terrorist Financing and to identify anomalies and
                file relevant Suspicious Transaction Reports ("STRs”) with the
                appropriate regulatory authorities <b>{brandConfig.companyName}</b> must employ
                transaction
                monitoring which is designed to identify anomalies in transactional
                activity that may indicate suspicious transactions. <b>{brandConfig.companyName}</b> is
                required to report a transaction as soon as practicable, where it has
                reasonable grounds to suspect that the transaction (whether completed or
                merely attempted) relates to a completed or attempted ML/IF offence.
            </div>

            <div className={s.point_subtitle}>
                4.10. Mandatory Reporting
            </div>

            <div className={s.point_subtitle}>
                4.10.1. <b>{brandConfig.companyName}</b> will comply with applicable AML/ATF and
                Sanctions
                mandatory reporting regulators, including large cash transaction
                reports, electronic fund transfer reports, suspicious transaction
                reports and terrorist property reports. Associates’ documentation will
                be kept in accordance with records retention requirements.
            </div>

            <div className={s.point_subtitle}>
                4.11. Record Keeping
            </div>

            <div className={s.point_text}>
                4.11.1. <b>{brandConfig.companyName}</b> will comply with AML/ATF record retention
                requirements.
                Required documentation will be maintained for a minimum of 5 years and
                documentation be in a reproducible form sufficient for regulatory
                audits.
            </div>

            <div className={s.point_subtitle}>
                4.12. Monitoring and Reporting
            </div>

            <div className={s.point_text}>
                4.12.1. <b>{brandConfig.companyName}</b> considers effective monitoring, oversight
                and
                reporting in respect of the AML Compliance Program as essential. The
                CAMLO is accountable for overseeing and monitoring AML Compliance
                Program through the AML RCM Framework and implementing such other
                monitoring processes as are appropriate for effective oversight of the
                AML Compliance Program.
            </div>

            <div className={s.point_text}>
                4.12.2. AML/ATF compliance monitoring and testing is performed based on
                the Self-Assessment of Controls Methodology, the Quality Assurance
                Methodology and the Regulatory Compliance Risk Assessment ("RCRA") 2nd
                line testing to ensure relevant policies, procedures and controls are
                adhered to in accordance with <b>{brandConfig.companyName}</b>'s requirements,
                should there
                be any material gaps identified, the deficiencies/incidents) shall be
                raised to the CAMLO and Senior Management without delay.
            </div>

            <div className={s.point_text}>
                4.12.3. The Board and Senior Management require timely, complete and
                effective reporting from the CAMLO as to the results of such oversight
                and monitoring. The CAMLO shall report to the Board and to Senior
                Management (including the Management Risk Committee), Board reporting is
                on a quarterly basis and at such other times as the Senior Management,
                may require or the CAMLO considers advisable. The reports will provide
                details of the overall structure and scope, effectiveness and adequacy
                of the AML Compliance Program (including the assessment of significant
                patterns or trends, inherent risks, the ongoing self-assessment of
                controls and material changes thereto, remedial action plans or
                recommendations, with milestones and target dates for completion) and
                any advice, recommendation or conclusions as the CAMLO deems appropriate
                from time to time. in addition, periodically, the CAMLO will report on
                the adequacy of the resources.
            </div>

            <div className={s.point_subtitle}>
                4.13. Effectiveness Testing
            </div>

            <div className={s.point_text}>
                4.13.1. Testing and review of the AML Compliance Program is an important
                means of ensuring that the AML Compliance Program remains robust. Full
                comprehensive, independent assessments are completed by Internal Audit
                every two years as to the adequacy and effectiveness of internal
                controls relating to key components of the AML Compliance Program,
                including control, risk management and governance processes, the results
                of which will be reported to the Board and Senior Management in a timely
                manner (within 30 days upon completion of the review).
            </div>

            <div className={s.point_text}>
                4.13.2. On an annual basis, internal Audit conducts the review and
                independent testing of all aspects of the OFAC compliance program and of
                the Reportable activity for the Federal Reserve Bank of Canada. The two
                years effectiveness audit will also include the testing for OFC
                compliance program and the Bank Notes.
            </div>

            <div className={s.point_title}>
                5. Policy Review and Approval
            </div>

            <div className={s.point_text}>
                5.1. The Executive Sponsor of this Policy will report to the ACRC
                quarterly on the Ban compliance with this Policy and will make
                recommendations for amendments.
            </div>

            <div className={s.point_subtitle}>
                5.2. Policy Exception: How issues of non-compliance are managed and
                reported
            </div>

            <div className={s.point_text}>
                5.2.1. This Policy will be reviewed by the ACRC and recommended to the
                Board of Director. for approval annually.
            </div>

            <div className={s.point_text}>
                5.2.2. Exceptions to this AML/ATF and Sanctions Policy will be granted
                in very rare circumstances; exceptions to law are not permitted.
            </div>

            <div className={s.point_text}>
                5.2.3. Requests for exceptions will be directed to the CAMLO and the CCO
                for approval.
            </div>

            <div className={s.point_text}>
                5.2.4. The CAMLO will escalate significant issues concerning compliance
                with AML Compliance Program requirements to the CCO, Senior Management
                and the Executive Committee.
            </div>

            <div className={s.point_text}>
                5.2.5. The CCO and CAMLO will escalate material exceptions and to the
                Policy, to the Board of Directors or its committees for review and
                resolution. The CAMLO may recommend actions to address such matters.
            </div>

        </div>
    );
};

export default FirstPanel;
