import React, {Dispatch, SetStateAction} from 'react';
import s from "./CardsStepTwo.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";


type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const CardsStepTwo = (props: PropsType) => {

    return (<>
        <div className={s.pageWrapper}>
            <div className={s.successIcon}></div>
            <div className={s.title}>Your Virtual Card Request is Under Review</div>
            <div className={s.subtitle}>Your virtual card application is usually swiftly processed within minutes, but
                in specific cases, it may take up to 7 working days. Once approved, you'll receive a notification via
                email.
            </div>
            <div className={s.buttonWrapper}>
                <MyBtn
                    title={'Card Dashboard'}
                    large
                    type={"submit"}/>
            </div>
        </div>
    </>);
};

export default CardsStepTwo;