import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./ToIberbancoTransactionTemplate.module.css";
import {DatePicker, Popover} from "antd";
import info from "../../../assets/newImg/personalAccount/information.svg";
import classnames from "classnames";
import {motion} from "framer-motion";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import MyBtn from "../../ui/MyBtn/MyBtn";
import MyTextarea from "../../ui/MyTextarea/MyTextarea";
import MyInput from "../../ui/MyInput/MyInput";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../assets/newImg/personalAccount/back.svg";
import {useNavigate} from "react-router-dom";
import {
    PrepareDataForPaymentIntraTemplate,
} from "../../../helpers/PrepareDataForPaymentTemplate";
import {createTemplate, getTemplateByIdThunk, SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {ITemplate} from "../../../types/interfaces";
import {PrepareDataForIberbancoTransaction} from "../../../helpers/PrepareDataForIberbancoTransaction";
import * as yup from "yup";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";
import {myDate} from "../../../helpers/CalculateDate";
import CustomModal from "../../ui/CustomModal/CustomModal";
import classNames from "classnames";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {brandConfig} from '../../../config/config';

type PropsType = {
    id?: string | number;
    templateName: string,
    setPurpose: Dispatch<SetStateAction<string>>
}

const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.forEach((account: any) => {
            if (account.number === props.accNumberFrom) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.accNumberFrom, props])

    return null
}

const ToAnIberbancoTransactionTemplate = (props: PropsType) => {

    const accountsList = useAppSelector(state => state.userReducer.accountsList)
    const navigate = useNavigate()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()
    const [paymentToIberbancoTemplate, setPaymentToIberbancoTemplate] = useState<ITemplate>()
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [currency, setCurrency] = useState('')

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? '100%' : 311,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        if (props.id !== 'create') {
            dispatch(getTemplateByIdThunk(token, props.id))
                .then((res) => {
                    props.setPurpose(`Transfer to ${brandConfig.companyNameSimple} User`)
                    setPaymentToIberbancoTemplate(res.data)
                })
        }
    }, [dispatch, props, token])

    const transferMethods = [
        'Account number',
        /*  'Email',
          'Phone number',*/
    ]

    const languages = [
        'EN',
        'FR',
        'RU',
    ]

    const validationSchema = yup.object({
        accNumberFrom: yup.string().required('Required field'),
        accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
        reference: yup.string().required('Required field').max(100, 'max 100 symbols'),
    })

    const closeAndRedirect = () => {
        setIsShowSuccessModal(false)
        navigate(-1)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.form_wrapper}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    accNumberFrom: paymentToIberbancoTemplate?.account,
                    accNumberTo: paymentToIberbancoTemplate?.toAccount,
                    email: paymentToIberbancoTemplate?.email,
                    phoneNumber: '',
                    emailLanguage: 'EN',
                    paymentDate: date.from,
                    transferCurrency: 'USD',
                    reference: paymentToIberbancoTemplate?.reference,
                    amount: '',
                    transferMethod: 'Account number',
                    type: '1'
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {

                    dispatch(setDisabled(true))

                    if (values.transferMethod === 'Phone number') {
                        // @ts-ignore
                        delete values.recipientsAccountNumber
                    }

                    if (values.transferMethod === 'Email') {
                        // @ts-ignore
                        delete values.phoneNumber
                        // @ts-ignore
                        delete values.recipientsAccountNumber
                    }

                    if (values.transferMethod === 'Account number') {
                        // @ts-ignore
                        delete values.phoneNumber
                    }

                    const tempData = {
                        ...values,
                        templateName: props.templateName,
                        paymentDate: date.from,
                    }

                    if (props.id === 'create') {
                        const preparedData = PrepareDataForPaymentIntraTemplate(tempData)
                        dispatch(createTemplate(token, preparedData))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    setIsShowSuccessModal(true)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    } else {
                        const secondTempData = {
                            ...tempData,
                            type: 'internal'
                        }
                        const preparedData = PrepareDataForIberbancoTransaction(secondTempData)
                        dispatch(SendTransferThunk(token, await preparedData))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    navigate(-1)
                                } else {
                                    console.error(res.data)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }


                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <CustomModal setIsModalOpen={setIsShowSuccessModal} isModalOpen={isShowSuccessModal}>
                            <div>
                                <div className={s.success_title}>Success!</div>
                                <span className={s.success_subtitle}>Your template was successfully created.</span>
                                <div className={s.success_button_wrapper}>
                                    <div className={s.btn_block}>
                                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                                    </div>
                                </div>
                            </div>
                        </CustomModal>

                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Payer account
                                    <Popover content={"Please select the account you wish to make a transfer from."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Inter',
                                                 width: '280px',
                                                 padding: '13px 16px'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    placeholder={"Please select the account"}
                                    id="accNumberFrom"
                                    name="accNumberFrom"
                                    isError={errors.accNumberFrom}
                                    touched={touched.accNumberFrom}
                                    disableZeroBalance={true}
                                />
                                {errors.accNumberFrom &&
                                    <div className={s.error_message}>{errors.accNumberFrom}</div>}
                            </div>

                            <div className={s.input_block}>

                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Recipient’s Details
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Transfer Method
                                        <Popover content={`Choose one of the recipient’s identifiers: their ${brandConfig.companyNameSimple}` +
                                            " account, email address or phone number."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdown
                                        items={transferMethods}
                                        id="transferMethod"
                                        name="transferMethod"
                                        isError={errors.transferMethod}
                                        touched={touched.transferMethod}
                                    />
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Account number
                                            <Popover
                                                content={<span>
                                                        Account number in the format <br/>
                                                        <span className={s.color_popover}>CAIBNCXXXXXXXXXXXXXXX.</span>
                                                    </span>}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"accNumberTo"}
                                                 name={"accNumberTo"}
                                                 touched={touched.accNumberTo}
                                                 value={values.accNumberTo}
                                                 onChange={handleChange}
                                                 isError={errors.accNumberTo}
                                        />
                                    </div>
                                    {errors.accNumberTo &&
                                        <div className={s.error_message}>{errors.accNumberTo}</div>}

                                    {/*      {values.transferMethod === 'Email'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                *Email
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                        </div>
                                    }

                                    {values.transferMethod === 'Phone number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>

                                                *phone number
                                                <Popover
                                                    content={"Phone number in international format."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"phoneNumber"}
                                                     name={"phoneNumber"}
                                                     touched={touched.phoneNumber}
                                                     value={values.phoneNumber}
                                                     onChange={handleChange}
                                                     isError={errors.phoneNumber}
                                            />
                                        </div>
                                    }*/}

                                </div>
                            </div>

                            <div className={classNames(s.row, s.mobile_row)}>
                                <div className={classnames(s.input_block, s.short)}>
                                    <div className={s.input_label}>
                                        Email
                                        <Popover
                                            content={"Please indicate the recipient’s email address and language, " +
                                                "if you would like us to send them an electronically signed automatic email " +
                                                "about the performed transfer."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id={"email"}
                                             name={"email"}
                                             touched={touched.email}
                                             value={values.email}
                                             onChange={handleChange}
                                             isError={errors.email}
                                    />
                                </div>

                                <div className={s.languages_dropdown}>
                                    <CustomDropdown
                                        items={languages}
                                        id="emailLanguage"
                                        name="emailLanguage"
                                        isError={errors.emailLanguage}
                                        touched={touched.emailLanguage}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Transfer Details
                            </div>

                            {
                                props.id !== 'create' &&
                                <>
                                    <div className={s.row}>
                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                * Payment date
                                                <Popover
                                                    content={"Please enter the date of the transfer. The date can be 2 years ahead from today."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <DatePicker suffixIcon={null} style={dateStyle}
                                                        defaultValue={dayjs(new Date(), dateFormat)}
                                                        disabledDate={disabledDate}
                                                        value={dayjs(date.from)}
                                                        onChange={(_, dateString) => {
                                                            if (!dateString) {
                                                                setDate({...date, from: new Date().toDateString()})
                                                            } else {
                                                                setDate({...date, from: dateString})
                                                            }
                                                        }}
                                                        format={dateFormat}/>
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    * Amount
                                                    <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                        "In the account balance in the selected currency is insufficient, the system " +
                                                        "will automatically offer you to top up using funds in a different currency " +
                                                        "you have on the account."}
                                                             color={'#EEEFEF'}
                                                             overlayInnerStyle={{
                                                                 fontFamily: 'Inter',
                                                                 width: '280px',
                                                                 padding: '13px 16px'
                                                             }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"amount"}
                                                         name={"amount"}
                                                         touched={touched.amount}
                                                         value={values.amount}
                                                         onChange={handleChange}
                                                         isError={errors.amount}
                                                />
                                                {errors.amount && <div className={s.error_message}>{errors.amount}</div>}
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className={s.row}>
                                <div className={s.textarea_block}>
                                    <div className={s.input_label}>
                                        *Reference
                                    </div>
                                    <MyTextarea
                                        id={"reference"}
                                        name={"reference"}
                                        onChange={handleChange}
                                        value={values.reference}
                                        isError={errors.reference}
                                        touched={touched.reference}
                                    />
                                    {errors.reference &&
                                        <div className={s.error_message}>{errors.reference}</div>}
                                </div>
                            </div>

                        </div>

                        <div className={s.buttonsWrapper}>
                            <div className={s.button_block}>
                                <TransparentButton title={'Back'}
                                                   large
                                                   icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                   onClick={() => {
                                                       navigate(-1);
                                                   }}/>
                            </div>

                            <MyBtn title={props.id === 'create' ? 'Save' : 'Make a transfer'}
                                   large
                                   style={{width: 180}}
                                   type="submit"
                            />
                        </div>

                        <CurrencyHelper
                            accountsList={accountsList}
                            accNumberFrom={values.accNumberFrom}
                            setCurrency={setCurrency}
                        />

                    </form>
                )}
            </Formik>
        </motion.div>
    );
};

export default ToAnIberbancoTransactionTemplate;
