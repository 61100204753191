import React, { useEffect, useState } from "react";
import KycPersonalStepOne from "./KycPersonalStepOne/KycPersonalStepOne";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { getPhoneListThunk } from "../../../../../store/reducers/ActionCreators";
import GenerateKycForm from "../../../../../components/GenerateKycForm/GenerateKycForm";

const KycPersonal = () => {
    const [current, setCurrent] = useState(0);
    const token = useAppSelector((state) => state.userReducer.user.userToken);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPhoneListThunk(token));
    }, [dispatch, token]);

    const steps = [
        {
            content: (
                <KycPersonalStepOne setCurrent={setCurrent} current={current} />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="personal"
                    action="create"
                    setCurrent={setCurrent}
                    title={"KYC for Personal Accounts"}
                />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="personal"
                    action="edit"
                    setCurrent={setCurrent}
                    title={"Edit KYC Personal Accounts"}
                />
            ),
        },
    ];

    return <div>{steps[current].content}</div>;
};

export default KycPersonal;
