import React, {Dispatch, SetStateAction} from 'react';
import ToAnIberbancoTransferForm
    from "../../../../../components/ToAnIberbancoTransferForm/ToAnIberbancoTransferForm";


type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    setTransferData: Dispatch<SetStateAction<any>>
}

const ToAnIberbancoStepOne = (props: PropsType) => {

    return (
        <ToAnIberbancoTransferForm
            setCurrent={props.setCurrent}
            setTransferData={props.setTransferData}
        />
    );
};

export default ToAnIberbancoStepOne;
