import React, { Dispatch, SetStateAction, useState } from "react";
import { motion } from "framer-motion";
import s from "./GenerateKycForm.module.css";
import CustomModal from "../ui/CustomModal/CustomModal";
import MyBtn from "../ui/MyBtn/MyBtn";
import { Formik, Form } from "formik";
import { KycFormPersonal } from "./Templates/KycFormPersonal/KycFormPersonal";
import { KycFormBusiness } from "./Templates/KycFormBusiness/KycFormBusiness";
import { useAppSelector } from "../../hooks/redux";
import {
    getKycInitialValues,
    validationSchema,
    requiredDocuments,
    defaultDocuments,
    getFinalizedData,
    getMissingFilesForEdit,
} from "./Constants/KycConstants";
import type { KycTypes, ActionTypes } from "./Constants/KycConstants";
import { toBase64 } from "../../helpers/toBase64";
import { useAppDispatch } from "../../store/store";
import { myDate } from "../../helpers/CalculateDate";
import {
    editKycThunk,
    sendPersonalKycThunk,
    setTokenState,
} from "../../store/reducers/ActionCreators";
import { setDisabled } from "../../store/reducers/UserSlice";

type PropsType = {
    title: string;
    type: KycTypes;
    action: ActionTypes;
    setCurrent: Dispatch<SetStateAction<number>>;
};

const GenerateKycForm = (props: PropsType) => {
    const { title, type, action, setCurrent } = props;

    const kycList = useAppSelector((state) => state.userReducer.user.kycList);
    const token: string = useAppSelector(
        (state) => state.userReducer.user.userToken
    );
    const dispatch = useAppDispatch();

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isPep, setIsPep] = useState(false);
    const [createModeMissingDoc, setCreateModeMissingDoc] = useState([""]);
    const [editModeMissingDoc, setEditModeMissingDoc] = useState([""]);
    const [errorMessage, setErrorMessage] = useState(
        "Something went wrong. Please try again later."
    );
    const [documents, setDocuments] = useState<any>(action !== "edit" ? defaultDocuments[type] : []);

    const [date, setDate] = useState(() => {
        const formattedDate = (dateValue: any) =>
            dateValue.getFullYear() +
            "-" +
            (dateValue.getMonth() + 1 < 10
                ? ("0" + (dateValue.getMonth() + 1)).slice(-2)
                : dateValue.getMonth() + 1) +
            "-" +
            (dateValue.getDate() < 10
                ? ("0" + dateValue.getDate()).slice(-2)
                : dateValue.getDate());

        const baseDate = {
            from: formattedDate(myDate),
        };

        if (type === "business") {
            return {
                ...baseDate,
                birth: formattedDate(new Date()),
            };
        }
        return baseDate;
    });

    const DisplayKycForm = {
        personal: (
            <KycFormPersonal
                s={s}
                editModeMissingDoc={editModeMissingDoc}
                createModeMissingDoc={createModeMissingDoc}
                setCurrent={setCurrent}
                documents={documents}
                setDocuments={setDocuments}
                isPep={isPep}
                date={date}
                setIsPep={setIsPep}
                setDate={setDate}
            />
        ),
        business: (<KycFormBusiness
            s={s}
            editModeMissingDoc={editModeMissingDoc}
            createModeMissingDoc={createModeMissingDoc}
            setCurrent={setCurrent}
            documents={documents}
            setDocuments={setDocuments}
            date={date}
            setDate={setDate}
        />),
    };

    const dispatchAction = (tempData: any) => {
        return {
            create: sendPersonalKycThunk(token, tempData),
            edit: editKycThunk(token, tempData)
        }
    }

    const onSubmitAction = async (values: any) => {
        // @ts-ignore
        const tempData = getFinalizedData(values, date, isPep, (action === "edit"), (action === "edit" ? kycList[0].id : undefined))[type];
        if (tempData.isOtherSourcesOfWealth) {
            tempData.sourcesOfWealth = [
                ...(tempData.sourcesOfWealth || []),
                tempData.otherSourcesOfWealth,
            ];
        }

        delete tempData.citizenshipName;
        delete tempData.otherSourcesOfWealth;
        delete tempData.isOtherSourcesOfWealth;
        
        if(action === "edit") {
            // @ts-ignore
            const missingFilesEditMode = getMissingFilesForEdit(kycList[0].files, type);
            setEditModeMissingDoc(missingFilesEditMode);
            if (missingFilesEditMode.length > 0) return;
        } else if(action === "create") {
            const missingFilesCreateMode = requiredDocuments[type].filter(
                (file) => !documents[file]
            );
            setCreateModeMissingDoc(missingFilesCreateMode);
            if (missingFilesCreateMode.length > 0) return;
        }

        for (const [key, file] of Object.entries(documents)) {
            if (file) {
                tempData.files[key] = await toBase64(file);
            }
        }

        dispatch(dispatchAction(tempData)[action])
            .then((res) => {
                if (res.data === "Created" || res.data === "Updated") {
                    setIsSuccessModalOpen(true);
                } else {
                    setIsErrorModalOpen(true);
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.data === "Invalid authorization data") {
                    dispatch(setTokenState(false));
                    return;
                }

                if (error.response.data === "Too many phones. Max count is 5") {
                    setErrorMessage(error.response.data);
                    setIsErrorModalOpen(true);
                    return;
                }

                if (error.response.data === "Phone already exist") {
                    setErrorMessage(
                        "This phone number already exists. Please try another phone number."
                    );
                    setIsErrorModalOpen(true);
                    return;
                }

                if (error.code === "ERR_NETWORK" && !error.response) {
                    setErrorMessage("Maximum files size is 90 MB");
                    setIsErrorModalOpen(true);
                } else {
                    setErrorMessage(
                        "Something went wrong. Please try again later."
                    );
                    setIsErrorModalOpen(true);
                }
            })
            .finally(() => {
                dispatch(setDisabled(false));
            });
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className={s.wrapper}>
                <CustomModal
                    isModalOpen={isSuccessModalOpen}
                    setIsModalOpen={setIsSuccessModalOpen}
                >
                    <div className={s.flex}>
                        <div className={s.modal_title}>Success!</div>
                        <div className={s.modal_subtitle}>
                            Your details have been sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn
                                title={"Ok"}
                                onClick={() => {
                                    setIsSuccessModalOpen(false);
                                    setCurrent(0);
                                }}
                            />
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    type="error"
                    isModalOpen={isErrorModalOpen}
                    setIsModalOpen={setIsErrorModalOpen}
                >
                    <div className={s.flex}>
                        <div className={s.modal_title}>Oops!</div>
                        <div className={s.modal_subtitle}>{errorMessage}</div>
                        <div className={s.button_block}>
                            <MyBtn
                                buttonType="error"
                                title={"Ok"}
                                onClick={() => setIsErrorModalOpen(false)}
                            />
                        </div>
                    </div>
                </CustomModal>

                <div className={s.page_title}>{title}</div>

                <Formik
                    initialValues={
                        getKycInitialValues(
                            action,
                            kycList.length >= 1 ? kycList[0] : null
                        )[type]
                    }
                    validationSchema={validationSchema[type]}
                    onSubmit={onSubmitAction}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            {DisplayKycForm[type]}
                        </Form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default GenerateKycForm;
