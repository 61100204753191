import React from 'react';
import styles from '../../../styles/styles.module.css';
import s from './WhyUs.module.css'
import attention from '../../../assets/newImg/publicPages/why_us/alert.svg'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import approach from "../../../assets/newImg/publicPages/why_us/approach.png";
import experience from "../../../assets/newImg/publicPages/why_us/experience.png";
import solutions from "../../../assets/newImg/publicPages/why_us/solutions.png";
import technology from "../../../assets/newImg/publicPages/why_us/technology.png";
import business_solutions from "../../../assets/newImg/publicPages/why_us/business_solutions.png";
import relationships from "../../../assets/newImg/publicPages/why_us/relationships.png";
import support from "../../../assets/newImg/publicPages/services/foreignExchange/foreign_help.svg";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import {motion} from 'framer-motion';
import {useNavigate} from "react-router-dom";
import {brandConfig} from '../../../config/config';

const WhyUs = () => {

    const navigate = useNavigate()

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
            <div className={styles.wrapper}>

                <div className={s.top_block}>
                    <div className={s.title}>
                        Why Choose <span className={s.color}>{brandConfig.companyNameSimple.toUpperCase()}? <img src={attention} alt="" className={s.attention}/></span>

                    </div>
                    <div className={s.flex}>
                        <span className={s.subtitle}>As your financial partner</span>
                        <div className={s.button_wrapper}>
                            <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                        </div>
                    </div>
                </div>

                <div className={s.reverse_cards_block}>
                    <div className={s.card_revert}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Client-Centric Approach
                            </div>
                            <span>
                            At {brandConfig.companyNameSimple}, our clients are at the heart of everything we do. We pride ourselves on providing
                                unparalleled attention, ensuring that each client receives personalized service tailored
                                to their unique needs. Our dedicated team is committed to building lasting relationships,
                                putting your financial goals and success at the forefront.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={approach} alt=""/>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Unmatched Expertise
                                & Experience
                            </div>
                            <span>
                            With decades of combined experience in the financial industry, our team brings extensive
                                knowledge and insights to the table. We have honed our skills over the years, making us
                                the trusted partner you can rely on for innovative foreign exchange and payment solutions.
                                Our global reach empowers businesses to thrive in a rapidly evolving marketplace.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={experience} alt=""/>
                        </div>
                    </div>

                    <div className={s.card_revert}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Tailored Risk Management Solutions
                            </div>
                            <span>
                            We understand that every business has its own risk tolerance and market needs. That's why we
                                offer customized risk management solutions designed to mitigate potential risks and
                                safeguard your interests. Our expert team will work closely with you to develop strategies
                                that align with your unique requirements, providing you with peace of mind and a competitive
                                edge.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={solutions} alt=""/>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Cutting-Edge Technology
                            </div>
                            <span>
                            With our state-of-the-art platform, you gain secure and convenient access to real-time market
                                rates, deal confirmations, trade settlements, and global payments. Our user-friendly
                                interface ensures a seamless experience, empowering you to make informed decisions and
                                execute transactions with ease.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={technology} alt=""/>
                        </div>
                    </div>

                    <div className={s.card_revert}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Comprehensive Business Solutions
                            </div>
                            <span>
                            From sending to tracking and delivering payments, {brandConfig.companyNameSimple} simplifies and streamlines your
                                financial operations. Our solutions are designed to make payments straightforward,
                                stress-free, and cost-effective. We help you manage cash flow, protect your margins,
                                and optimize your financial performance. With dedicated account managers, we take the
                                time to understand your business needs, providing proactive guidance and support every
                                step of the way.
                        </span>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={business_solutions} alt=""/>
                        </div>
                    </div>

                    <div className={s.card}>
                        <div className={s.card_text_block}>
                            <div className={s.reverse_cards_title}>
                                Long-Term Partnerships
                            </div>
                            <span>
                            At {brandConfig.companyNameSimple}, we believe in fostering long-term partnerships built on trust, reliability,
                                and mutual growth. We are committed to retaining our valued clients, which is why our
                                team remains consistent and dedicated. By developing a deep understanding of your business
                                and goals, we can proactively address your evolving needs and help you navigate the
                                ever-changing financial landscape.
                        </span>
                            <div className={s.card_button_wrapper}>
                                <div className={s.card_button_block}>
                                    <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                                </div>
                            </div>
                        </div>
                        <div className={s.card_image_block}>
                            <img className={s.img} src={relationships} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={s.support_wrapper}>
                    <div className={s.container_support}>
                        <div>
                            <div className={s.support_subtitle}>
                                Ensure your success and deliver exceptional results
                            </div>
                            <div className={s.support_title}>
                                Choose {brandConfig.companyNameSimple} as your <span className={s.underline}> financial partner </span>
                            </div>
                            <div className={s.support_text}>
                                Experience the difference of personalized service, unmatched expertise, and unwavering
                                commitment.
                            </div>
                        </div>
                        <div className={s.img_support}>
                            <img src={support} alt=""/>
                        </div>
                    </div>

                    <div className={s.support_text_mobile}>
                        Experience the difference of personalized service, unmatched expertise, and unwavering
                        commitment.
                    </div>

                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default WhyUs;
