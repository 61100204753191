import * as yup from 'yup';
import { IPhoneNumber } from '../../../types/interfaces';
import { phonecodes } from '../../../Constants/StaticData';

type KycTypes = "personal" | "business";
type ActionTypes = "create" | "edit";
type FileTypes =
    | ".png"
    | ".jpg"
    | ".jpeg"
    | ".pdf"
    | "image/png"
    | "image/jpeg"
    | "application/pdf";

interface PersonalKycValues {
    firstName: string;
    lastName: string;
    countryName: string;
    city: string;
    state: string;
    postalCode: string;
    identityCardType: string;
    identityCardId: string;
    address: string;
    phoneNumber: string;
    taxNumber: string;
    citizenshipName: string;
    sourcesOfWealth: string[];
    isOtherSourcesOfWealth: boolean;
    otherSourcesOfWealth: string;
    pep: boolean;
    currencies: string[];
    files: {
        passportSpread: string;
        selfie: string;
        proofOfAddress: string;
    };
}

interface BusinessKycValues {
    companyName: string;
    countryName: string;
    city: string;
    address: string;
    email: string;
    postalCode: string;
    companyType: string;
    natureOfBusiness: string;
    registrationNumber: string;
    website: string;
    marketingStrategy: string;
    industryId: string;
    taxNumber: string;
    financialRegulator: string;
    regulatoryLicenseNumber: string;
    phoneNumber: string;
    currencies: string[];
    sourcesOfWealth: string[];
    isOtherSourcesOfWealth: boolean;
    otherSourcesOfWealth: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    residenceCountryName: string;
    authorizedCitizenshipName: string;
    authorizedPersonCity: string;
    authorizedPersonAddress: string;
    authorizedPersonPostalCode: string;
    files: {
        accountApplication: string;
        articlesMemorandums: string;
        proofOfAddress: string;
        registrationCertificate: string;
        beneficiaryIdDocument: string;
        beneficiaryIdcp: string;
        beneficiaryProofOfAddress: string;
        registerOfDirectorOrMembers: string;
        registerOfShareholders: string;
    };
}
    

const getKycInitialValues = (action: ActionTypes, data: any) => {
    const validForEdit = action === "edit" && data;
    return {
        personal: {
            firstName: validForEdit ? data.firstName : "",
            lastName: validForEdit ? data.lastName : "",
            countryName: validForEdit ? data.country : "",
            city: validForEdit ? data.city : "",
            state: validForEdit ? data.state : "",
            postalCode: validForEdit ? data.postalCode : "",
            identityCardType: validForEdit ? data.identityCardType : "",
            identityCardId: validForEdit ? data.identityCardId : "",
            address: validForEdit ? data.address : "",
            phoneNumber: validForEdit ? data.phoneNumber : "",
            taxNumber: validForEdit ? data.taxNumber : "",
            citizenshipName: validForEdit ? data.citizenship : "",
            sourcesOfWealth: validForEdit ? data.sourcesOfWealth : [],
            isOtherSourcesOfWealth: validForEdit ? data.isOtherSourcesOfWealth : false,
            otherSourcesOfWealth: (validForEdit && data.isOtherSourcesOfWealth) ? data.sourcesOfWealth[0] : "",
            pep: false,
            currencies: validForEdit ? data.currencies : [],
            files: {
                passportSpread: "",
                selfie: "",
                proofOfAddress: "",
            },
        },
        business: {
            companyName: validForEdit ? data.owner : "",
            countryName: validForEdit ? data.country : "",
            city: validForEdit ? data.city : "",
            address: validForEdit ? data.address : "",
            email: validForEdit ? data.email : "",
            postalCode: validForEdit ? data.postalCode : "",
            companyType: validForEdit ? data.companyType : "",
            natureOfBusiness: validForEdit ? data.natureOfBusiness : "",
            registrationNumber: validForEdit ? data.registrationNumber : "",
            website: validForEdit ? data.website : "",
            marketingStrategy: validForEdit ? data.marketingStrategy : "",
            industryId: validForEdit ? data.industryId : "",
            taxNumber: validForEdit ? data.taxNumber : "",
            financialRegulator: validForEdit ? data.financialRegulator : "",
            regulatoryLicenseNumber: validForEdit ? data.regulatoryLicenseNumber : "",
            phoneNumber: validForEdit ? data.phoneNumber : "",
            currencies: validForEdit ? data.currencies : [],
            sourcesOfWealth: validForEdit ? data.sourcesOfWealth : [],
            isOtherSourcesOfWealth: validForEdit ? data.isOtherSourcesOfWealth : false,
            otherSourcesOfWealth: validForEdit ? data.otherSourcesOfWealth : "",
            firstName: validForEdit ? data.firstName : "",
            lastName: validForEdit ? data.lastName : "",
            dateOfBirth: validForEdit ? data.lastName : "",
            residenceCountryName: validForEdit ? data.authorizedPersonCountryOfResidence : "",
            authorizedCitizenshipName: validForEdit ? data.citizenship : "",
            authorizedPersonCity: validForEdit ? data.authorizedPersonCity : "",
            authorizedPersonAddress: validForEdit ? data.authorizedPersonAddress : "",
            authorizedPersonPostalCode: validForEdit ? data.authorizedPersonPostalCode : "",
            files: {
                accountApplication: "",
                articlesMemorandums: "",
                proofOfAddress: "",
                registrationCertificate: "",
                beneficiaryIdDocument: "",
                beneficiaryIdcp: "",
                beneficiaryProofOfAddress: "",
                registerOfDirectorOrMembers: "",
                registerOfShareholders: "",
            },
        },
    };
}

const getPhoneNumberList = (phoneList: any): string[] => {
    const phonesArr: string[] | null = [];
    phoneList?.forEach((item: IPhoneNumber) => {
        if (item.phoneNumber != null) {
            phonesArr.push(item.phoneNumber);
        }
    });
    return phonesArr;
}

const getCountryCode = (countryName: string) => {
    const country = phonecodes.find((entry) => entry.na === countryName);
    return country?.co;
};

const validationSchema = {
    personal: yup.object({
        phoneNumber: yup.string()
            .required('Required field'),
        firstName: yup.string()
            .max(60, 'max 60')
            .required('Required field'),
        lastName: yup.string()
            .required('Required field')
            .max(60, 'max 60'),
        citizenshipName: yup.string().required('Required field').max(90, 'max 90'),
        countryName: yup.string().required('Required field').max(60, 'max 60'),
        city: yup.string().required('Required field').max(60, 'max 60'),
        address: yup.string().required('Required field').max(255, 'max 255'),
        identityCardType: yup.string().required('Required field').max(50, 'max 50'),
        identityCardId: yup.string().required('Required field').max(90, 'max 90'),
        postalCode: yup.string().required('Required field').max(30, 'max 30'),
        taxNumber: yup.string().required('Required field').max(90, 'max 90'),
        otherSourcesOfWealth: yup.string().test({
            message: 'Required field',
            test: function (value) {
                const {isOtherSourcesOfWealth} = this.parent;
                if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        currencies: yup.array().min(1, "At least one account is required"),
        sourcesOfWealth: yup.array().test({
            message: 'At least one source is required',
            test: function (value) {
                const {isOtherSourcesOfWealth} = this.parent;
                if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
                return true
            },
        }),
    }),
    business: yup.object({
        phoneNumber: yup.string().required('Required field'),
        companyName: yup.string().required('Required field').max(90, 'max 90'),
        countryName: yup.string().required('Required field').max(90, 'max 90'),
        companyType: yup.string().required('Required field').max(90, 'max 90'),
        city: yup.string().required('Required field').max(60, 'max 60'),
        address: yup.string().required('Required field').max(255, 'max 255'),
        postalCode: yup.string().required('Required field').max(30, 'max 30'),
        email: yup.string().email('invalid format').required('Required field').max(60, 'max 60'),
        industryId: yup.string().required('Required field').max(20, 'max 20'),
        taxNumber: yup.string().required('Required field').max(90, 'max 90'),
        currencies: yup.array().min(1,"At least one account is required"),
        firstName: yup.string().required('Required field').max(60, 'max 60'),
        lastName: yup.string().required('Required field').max(60, 'max 60'),
        authorizedCitizenshipName: yup.string().required('Required field').max(60, 'max 60'),
        residenceCountryName: yup.string().required('Required field'),
        authorizedPersonCity:  yup.string().required('Required field').max(60, 'max 60'),
        authorizedPersonAddress:  yup.string().required('Required field').max(255, 'max 255'),
        authorizedPersonPostalCode:  yup.string().required('Required field').max(30, 'max 30'),
        otherSourcesOfWealth: yup.string().test({
            message: 'Required field',
            test: function (value) {
                const {isOtherSourcesOfWealth} = this.parent;
                if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        sourcesOfWealth: yup.array().test({
            message: 'At least one source is required',
            test: function (value) {
                const {isOtherSourcesOfWealth} = this.parent;
                if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
                return true
            },
        }),
        financialRegulator: yup.string().test({
            message: 'Required field',
            test: function (value) {
                const {companyType} = this.parent;
                if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        regulatoryLicenseNumber: yup.string().test({
            message: 'Required field',
            test: function (value) {
                const {companyType} = this.parent;
                if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
        website: yup.string().test({
            message: 'Website or Marketing Strategy required',
            test: function (value) {
                const { marketingStrategy } = this.parent;
                if ((value === undefined || value === null || value === '' ) && (marketingStrategy === undefined || marketingStrategy === null || marketingStrategy === '')) return false;
                return true
            },
        }),
        marketingStrategy: yup.string().test({
            message: 'Website or Marketing Strategy required',
            test: function (value) {
                const {website} = this.parent;
                if ((value === undefined || value === null || value === '' ) && (website === undefined || website === null || website === '')) return false;
                return true
            },
        }),
    })
};

const getFinalizedData = (values: any, date: any, isPep: boolean, isEditMode: boolean, kycId?: number) => {
    return {
        personal: {
            ...(isEditMode && { kycId }),
            ...values,
            dateOfBirth: date.from,
            country: getCountryCode(values.countryName),
            citizenship: getCountryCode(values.citizenshipName),
            pep: isPep,
            files: (!isEditMode ? {
                passportSpread: '',
                selfie: '',
                proofOfAddress: '',
            } : {})
        },
        business: {
            ...(isEditMode && { kycId }),
            ...values,
            registrationDate: date.from,
            dateOfBirth: date.birth,
            country: getCountryCode(values.countryName),
            authorizedPersonCountryOfResidence: getCountryCode(values.residenceCountryName),
            citizenship: getCountryCode(values.authorizedCitizenshipName),
            files: (!isEditMode ? {
                accountApplication: '',
                articlesMemorandums: '',
                proofOfAddress: '',
                registrationCertificate: '',
                beneficiaryIdDocument: '',
                beneficiaryIdcp: '',
                beneficiaryProofOfAddress: '',
                registerOfDirectorOrMembers: '',
                registerOfShareholders: '',
            } : {})
        }
    }
}

const defaultDocuments = {
    personal: {
        passportSpread: "",
        selfie: "",
        proofOfAddress: "",
        backOfIdCard: "",
        idCard: "",
    },
    business: {
        accountApplication: "",
        proofOfAddress: "",
        articlesMemorandums: "",
        registrationCertificate: "",
        beneficiaryIdDocument: "",
        beneficiaryIdcp: "",
        beneficiaryProofOfAddress: "",
        registerOfDirectorOrMembers: "",
        registerOfShareholders: "",
        poA: "",
        license: "",
        backOfIdCard: "",
        idCard: "",
    }
}

const requiredDocuments = {
    personal: ['passportSpread', 'selfie', 'proofOfAddress'],
    business: [
        'accountApplication',
        'proofOfAddress',
        'articlesMemorandums',
        'registrationCertificate',
        'beneficiaryIdDocument',
        'beneficiaryIdcp',
        'beneficiaryProofOfAddress',
        'registerOfDirectorOrMembers',
        'registerOfShareholders',
        'poA'
    ]
}

const companyTypes = [
    'Sole Proprietorship',
    'Partnership',
    'Privately Owned Company (Limited Company)',
    'Publicly Listed Company (PLC)',
    'Government Owned Entity Trusts',
    'Foundations & Similar Entities',
    'Non-Government Organisations / Charities inc Religious bodies and place of worship',
    'Clubs and Societies',
    'GO (Majority Owned Subsidiary of State-Owned Company)',
    'Financial Institution',
]

const natureOfBusiness = [
    'Personal',
    'Agriculture and Hunting',
    'Forestry',
    'Fishing',
    'Agricultural By-Products',
    'Coal Mining',
    'Oil Mining',
    'Iron Ore Mining',
    'Other Metal and Diamond Mining',
    'Other Mineral Mining',
    'Manufacture of Food/Drink/Tobacco',
    'Manufacture of Textiles/Leather/Fur/Furniture',
    'Manufacture of Wooden Products/Furniture',
    'Manufacture of Paper/Pulp/Allied Products',
    'Manufacture Of Chemicals Medical Petroleum Rubber Plastic Products',
    'Manufacture Of Pottery China Glass Stone',
    'Manufacture Of Iron Steel Non-Ferrous Metals Basic Industries',
    'Manufacture Of Metal Products Electrical And Scientific Engineering',
    'Manufacture Of Jewelry Musical Instruments Toys',
    'Electricity, Gas And Water',
    'Construction',
    'Wholesale Trade',
    'Retail Trade',
    'Catering Incl. Hotels',
    'Transport Storage',
    'Communications',
    'Finance And Holding Companies',
    'Insurance',
    'Business Services',
    'Real Estate Development Investment',
    'Central State Governments',
    'Community Services Defence Police Prisons Etc',
    'Social Services Education Health Care',
    'Personal Services - Leisure Services',
    'Personal Services - Domestic Laundry Repairs',
    'Personal Services - Embassies'
]

const getMissingFilesForEdit = (files: any, type: KycTypes) => {
    const submittedFiles = new Set(files.map((item: any) => item.fileType));
    const filesNeeded = requiredDocuments[type].filter(file => !submittedFiles.has(file));
    return filesNeeded;
};

export { getKycInitialValues, getPhoneNumberList, getCountryCode, getFinalizedData, getMissingFilesForEdit, validationSchema, requiredDocuments, defaultDocuments, companyTypes, natureOfBusiness };
export type { KycTypes, ActionTypes, FileTypes, PersonalKycValues, BusinessKycValues }