import React from 'react';
import s from "../LegalAndPrivacy.module.css";
import {brandConfig} from '../../../../config/config';

const SixthPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                1. Definitions
            </div>

            <div className={s.point_subtitle}>
                1.1. The following terms shall have the meanings set out below:
            </div>

            <div className={s.point_text}>
                a) "CASL" means Canada's Anti-Spam Legislation: <br/>
                b) "CEM" means a commercial electronic message, meaning a message sent electronically, such as through
                email, text or direct message sent through social media or a messaging app, where one of the message's
                purposes is to encourage participation in a commercial activity. <br/>
                c) "CCO" means <b>{brandConfig.companyName}</b>'s Chief Compliance Officer. <br/>
                d) "Director" means an individual appointed or elected to <b>{brandConfig.companyName}</b>'s Board of
                Directors. <br/>
                e) "Employee" means an individual working for <b>{brandConfig.companyName}</b>'s, whether part-time, full-time or
                contract. <br/>
                f) "Policy" or "CASL Policy" means this Canada Anti-Spam Compliance Policy. <br/>
                g) "Third Party" means a vendor, consultant or other entity or individual who provides services to
                <b> {brandConfig.companyName}</b>.
            </div>

            <div className={s.point_title}>
                2. Background, purpose, and scope
            </div>

            <div className={s.point_subtitle}>
                2.1. CASL includes the following key prohibitions:
            </div>

            <div className={s.point_text}>
                a) Sending a CEM to an electronic address unless:
            </div>

            <div className={s.point_text}>
                i) the person to whom the message is sent has consented to receiving it; <br/>
                ii) the message complies with prescribed form and content requirements.
            </div>

            <div className={s.point_text}>
                b) Altering transmission data without express consent. <br/>
                c) Installing computer programs without express consent. <br/>
                d) Making false and misleading representations in electronic messages. <br/>
                e) Collecting email addresses using computer programs without consent. <br/>
                f) Collecting personal information through unauthorized access to a computer system.
            </div>

            <div className={s.point_text}>
                <b>2.2.</b> CASL only applies to CEMs that are sent to electronic addresses. For this reason, online
                advertise
                posts to the <b>{brandConfig.companyName}</b>'s website or Facebook page and Tweets from the <b>{brandConfig.companyName}</b>'s
                Twitter account, if any, not subject to CASL.
            </div>

            <div className={s.point_text}>
                <b>2.3.</b> The purpose of this Policy is to facilitate <b>{brandConfig.companyName}</b>'s compliance with CASL.
            </div>

            <div className={s.point_text}>
                <b>2.4.</b> This Policy applies to all Employees and Third Parties.
            </div>

            <div className={s.point_title}>
                3. Restrictions and criteria for sending a CEM
            </div>

            <div className={s.point_text}>
                <b>3.1.</b> Examples of CEMs sent by <b>{brandConfig.companyName}</b>'s include electronic messages that wholly or
                partly:
            </div>

            <div className={s.point_text}>
                a) Advertise or promote the services, products, or expertise of <b>{brandConfig.companyName}</b>'s, its Employees or
                Third
                Parties. <br/>
                b) Promote <b>{brandConfig.companyName}</b>'s or its public image, or <br/>
                c) Solicit business from current or prospective customers.
            </div>

            <div className={s.point_text}>
                <b>3.2. {brandConfig.companyName}</b>'s, its Employees, and Third Parties, on behalf of <b>{brandConfig.companyName}</b> may
                only send CEMs in
                the following circumstances.
            </div>

            <div className={s.point_text}>
                (a) Existing <b>{brandConfig.companyName}</b>'s Customers - A CEM may be sent to an existing <b>{brandConfig.companyName}</b>’s
                customer (or a
                representative thereof), unless the <b>{brandConfig.companyName}</b>'s customer has unsubscribed from <b>{brandConfig.companyName}</b>'s
                communications. An entity will be considered an existing <b>{brandConfig.companyName}</b>'s customer if it has:
            </div>

            <div className={s.point_text}>
                i) Used <b>{brandConfig.companyName}</b>'s 's services within the previous 2 years. <br/>
                ii) Accepted a business or investment opportunity with {brandConfig.companyName} 's within the past 2 years. <br/>
                iii) A written contract with <b>{brandConfig.companyName}</b>'s that is in existence or expired within the past 2
                years; or <br/>
                iv) Inquired or applied, or is in negotiations in respect of, about any of the above within the past 6
                months.
            </div>

            <div className={s.point_text}>
                (b) Prospective <b>{brandConfig.companyName}</b> Customers - A CEM may be sent to a representative of a prospective
                <b> {brandConfig.companyName}</b> customer who has not unsubscribed from <b>{brandConfig.companyName}</b> communications if:
            </div>

            <div>
                i) The representative has provided his or her business card or electronic address to <b>{brandConfig.companyName}</b> or
                has caused his/ her electronic address to be conspicuously published (for example, on a company website,
                in a business directory or conference attendee list), and <br/>
                ii) The representative has not requested to be contacted, and<br/>
                iii) The message relates to the recipient's role in a business or official capacity.<br/>
                iv) <b>{brandConfig.companyName}</b>'s must retain evidence of compliance with the above requirements, for example,
                by
                retaining a copy of the business card or business directory with the representative's name, title and
                contact information, and recording the date upon which the card/directory was obtained. These records
                must be retained in accordance with the Records Retention Policy and Procedures.
            </div>

            <div className={s.point_subtitle}>
                3.3. Consent
            </div>

            <div className={s.point_text}>
                (a) Express Consent - A CEM may be sent to a representative of an existing or prospective customer who
                has given express consent to reserve CEMs. Any request for express consent must meet the following
                requirements:
            </div>

            <div className={s.point_text}>
                i) Must be opt-in. This means that the representative must take a positive step to confirm consent (such
                as checking an un-checked box). <br/>
                ii) Must be stand-alone. Consent cannot be bundled with other terms (for example, with agreement to
                service terms and conditions) in. <br/>
                iii) Must include the following information:
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>be oral or Witten</li>
                    <li>the purposes for which consent is being sought.</li>
                    <li><b>{brandConfig.companyName}</b>'s name, or information about the person on whose behalf consent is being
                        sought
                        (for example where {brandConfig.companyName} is seeking consent on behalf of its affiliate, <b>{brandConfig.companyName}</b>.
                    </li>
                    <li><b>{brandConfig.companyName}</b>'s mailing address, and one of a telephone number providing access to an
                        agent or
                        voice messaging system, an email address, or a web address; and
                    </li>
                    <li>a statement that the person can withdraw their consent at any time.</li>
                </ul>
            </div>

            <div className={s.point_text}>
                iv) Consent by an Affiliate or Third Party on <b>{brandConfig.companyName}</b>'s Behalf - Different requirements
                apply in
                these situations. Any Employee or Third Party seeking this type of consent must check the specific
                requirements with the CCO. <br/>
                v) electronic message requesting express consent to send CEMs - This is itself deemed to be a CEM. This
                means that a request for express consent cannot be sent electronically unless. <b>{brandConfig.companyName}</b> is
                otherwise permitted to send CEMs to the recipient (see above). <br/>
                vi) Evidence of Consent and Records Retention - <b>{brandConfig.companyName}</b> must retain records that express
                consent
                was obtained, in accordance with the Records Retention Policy and Procedures.
            </div>

            <div className={s.point_text}>
                (b) Implied Consent - <b>{brandConfig.companyName}</b> may rely on implied consent when sending a CEM in the
                following
                situations.
            </div>

            <div className={s.point_text}>
                i) Existing business relationships, meaning that the person has used and paid for <b>{brandConfig.companyName}</b>'s
                services within the prior two years. This list must be regularly updated by the Head of Sales, or
                delegate, for review by the CCO, and it is advisable to send a request for express consent to these
                persons prior to the end of the two-year period. <br/>
                ii) Existing non-business relationships (this includes friends, relatives, and other persons with whom
                there is a pre-existing relationship).<br/>
                iii) Conspicuous Publications - The person to whom the message is sent has conspicuously published, the
                electronic address to which the message is sent, the publication is not accompanied by a statement that
                the person does not wish to receive unsolicited commercial electronic messages at the electronic
                address, and the message is relevant to the person's business, role, functions, or duties in a business
                or official capacity; or <br/>
                iv) Voluntary Disclosures - The recipient has disclosed to the sender, the electronic address to which
                the message is sent without indicating a wish not to receive unsolicited commercial electronic messages
                at the electronic address, and the message is relevant to the person's business, role, functions, or
                duties in a business or official capacity.
            </div>

            <div className={s.point_subtitle}>
                3.4. Evidence of Consent and Records Retention
            </div>

            <div className={s.point_text}>
                (a) <b>{brandConfig.companyName}</b> shall keep physical or electronic copies of
            </div>

            <div className={s.point_text}>
                i) All evidence of express and implied consent (verbal, recorded, written). <br/>
                ii) Documentation regarding the methods through which consent was collected: <br/>
                iii) Policies and procedures regarding CASL compliance; and <br/>
                iv) All unsubscribe requests and resulting actions.
            </div>

            <div className={s.point_text}>
                (b) <b>{brandConfig.companyName}</b> shall retain the records referenced is paragraph (a) above, in accordance with
                the
                Record Retention Policy and Procedures.
            </div>

            <div className={s.point_subtitle}>
                3.5 Exemption from Consent Requirement
            </div>

            <div className={s.point_text}>
                a) Certain messages are exempt from the requirement to obtain consent, however, the form and content
                requirements must be complied with
            </div>

            <div className={s.point_text}>
                b) The following types of messages utilized by {brandConfig.companyName} are exempt from the consent requirements.
            </div>

            <div className={s.point_text}>
                i) Providing a requested quote or estimate: <br/>
                ii) Facilitating or confirming a previously agreed-upon commercial transaction. <br/>
                iii) Deliver a product, good or service under a prior transaction. <br/>
                iv) First messages sent through a third-party referral, which has a business or non-business
                relationship with {brandConfig.companyName} and the referred person, provided that the individual providing the
                referral has consented to the message and that the message identifies the referring person.
            </div>

            <div className={s.point_subtitle}>
                3.6. CEM Form and Content Requirements
            </div>

            <div className={s.point_text}>
                a) Each CEM sent by <b>{brandConfig.companyName}</b>, its Employees, or Third Parties on <b>{brandConfig.companyName}</b>’s
                behalf must
                include the following footer: “This message is sent by <b>{brandConfig.companyName}</b>. To unsubscribe click here”.
            </div>

            <div className={s.point_text}>
                b) The "unsubscribe mechanism" must be able to be readily performed (at no cost to the recipient) and
                must enable the recipient to unsubscribe using the same electronic means by which the CEM was sent or if
                those means are not practicable, any other electronic means. For email, this means a hyperlink to a web
                page or an email address to which an unsubscribe request should be sent. The unsubscribe mechanism must
                be valid for at least 60 days and be given effect to within 10 business days without any further action
                from the recipient
            </div>

            <div className={s.point_text}>
                <b>3.7.</b> Compliance Approval All CEMs, including those sent through Marketing and Sales must be
                reviewed and
                approved by the CCO or other compliance person to whom the CCO has delegated this function.
            </div>

            <div className={s.point_title}>
                4. Altering transmission data & installing computer programs
            </div>

            <div className={s.point_text}>
                <b>4.1.</b> As noted in s. 2.1 (b) and (c) above, <b>{brandConfig.companyName}</b> may not alter transmission data
                or install
                computer programs without the specific, separate consent of the recipient. As of the date of this
                Policy. <b>{brandConfig.companyName}</b> is not engaging in either of these activities, and as such, no further
                action by
                <b> {brandConfig.companyName}</b> is required. If, however, <b>{brandConfig.companyName}</b>'s does start to engage in any of
                these activities,
                the required separate consent will be obtained, and the appropriate compliance procedures will be put in
                place.
            </div>

            <div className={s.point_title}>
                5. Roles, responsibilities, and reporting
            </div>

            <div className={s.point_text}>
                Enforcement Advisory - Notice for businesses and individuals on how to keep records of consent: <br/>
                <a className={s.point_link}
                   target="_blank"
                   href="https://www.canada.ca/en/radio-television-telecommunications/news/2016/07/enforcementadvisory-notice-for-businesses-and-individuals-on-how-to-keep-records-of-consent.html"
                   rel="noreferrer"
                   >
                    https://www.canada.ca/en/radio-television-telecommunications/news/2016/07/enforcementadvisory-notice-for-businesses-and-individuals-on-how-to-keep-records-of-consent.html
                </a>
            </div>

            <div className={s.point_text}>
                <b>5.1.</b> The CCO is responsible for the implementation of this Policy, including the development and
                implementation of training programs for all employees.
            </div>

            <div className={s.point_text}>
                <b>5.2.</b> The CCO will report annually to the Risk Committee on <b>{brandConfig.companyName}</b>'s compliance with
                this Policy.
            </div>

            <div className={s.point_text}>
                <b>5.3.</b> This Policy will be recommended by the Risk Committee for approval by the Board.
            </div>

            <div className={s.point_text}>
                <b>5.4.</b> The Risk Committee will review the Policy annually and more frequently as may be required by
                the CCO, the Committee or the Board.
            </div>

            <div className={s.point_title}>
                6. Compliance and penalties
            </div>

            <div className={s.point_text}>
                <b>6.1.</b> The potential penalties for non-compliance with CASL are significant and include administrative
                monetary penalties of up to $10,000,000 for corporations.
            </div>

            <div className={s.point_text}>
                <b>6.2.</b> Non-compliance with this Policy can lead to disciplinary action up to and including
                termination of employment.
            </div>

            <div className={s.point_title}>
                7. Questions
            </div>

            <div className={s.point_text}>
                <b>7.1.</b> Questions about this Policy or the requirements of CASL should be directed to the CCO.
            </div>

        </div>
    );
};

export default SixthPanel;
