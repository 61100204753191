import {AppDispatch} from "../store";
import {Api} from "../../api/Api";
import {
    setAccountsList,
    setCurrencyExchangeResult, setCurrencyExchangeResultCheck,
    setDisabled, setEmailsList,
    setIsLoading,
    setPhoneList,
    setTokenStatus,
    userSlice
} from "./UserSlice";
import {setTemplateList, setTransactionsData, transactionsSlice} from "./TransactionsSlice/TransactionsSlice";
import {EmailVereficationDataType} from "../../components/Autentications/EmailAutentication/EmailAuntification";
import {IAccounts, IContactData, IloginData, ITransacton} from "../../types/interfaces";
import {IExchangeBody} from "../../pages/PersonalAccount/Currency/CurrencyExchange/ExchangeStepTwo/ExchangeStepTwo";
import {FormikValues} from "formik";
import {cardTransactionsSlice} from "./CardTransactionsSlice/CardTransactionsSlice";

export type LoginDataType = {
    email: string, password: string,
}

export type RegistrationDataType = {
    email: string, password: string, phone: string, type: string
}

export const getAdditionalsThunk = (data: LoginDataType) => async () => {
    return await Api.login(data)
}

export const RegistrationThunk = (data: RegistrationDataType) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    dispatch(userSlice.actions.setUserEmail(data.email))
    await Api.registration(data)
    dispatch(userSlice.actions.setRegistrationWasSuccess(true))
    dispatch(setDisabled(false))
}

export const LogoutThunk = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        dispatch(userSlice.actions.signOut())
        dispatch(transactionsSlice.actions.clearTransactionSlice())
        dispatch(cardTransactionsSlice.actions.clearCardTransactionSlice())
        dispatch(setDisabled(false))
    } catch (e: any) {
        console.error(e)
        dispatch(setDisabled(false))
    }
}

export const loginVereficationThunk = (data: EmailVereficationDataType) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    await Api.emailVerefication(data)
    dispatch(setDisabled(false))
}

export const sendPersonalKycThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendPersonalKyc(token, data)
}

export const getKycListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getKycList(token)
        dispatch(userSlice.actions.setKycList(response.data))
        return response
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteKycThunk = (token: string, id: number) => async (dispatch: AppDispatch) => {
    try {
        await Api.deleteKyc(token, id);
        dispatch(getKycListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const editKycThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.updateKyc(token, data)
}

export const get2FaThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        return await Api.get2Fa(token)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const send2FaThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
        return await Api.send2FaCode(token, data)
}

export const GetAccountsListThunk = (token: string, currency: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setIsLoading(true))
        const response = await Api.getAccountsList(token, currency)

        dispatch(setAccountsList(response.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    } finally {
        dispatch(setIsLoading(false))
    }
}

export const CreateAccountsThunk = (token: string, accounts: IAccounts) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        return await Api.createAccounts(token, accounts)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    } finally {
        dispatch(setDisabled(false))
    }
}

export const getTransactionsListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getTransactionsList(token)
        dispatch(setTransactionsData(response.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const SetNew2FaStatus = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(userSlice.actions.setIs2Fa(true))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const finalLoginThunk = (data: IloginData) => async (dispatch: AppDispatch) => {
    localStorage.clear()
    dispatch(setDisabled(true))
    const response = await Api.finalLogin(data)
    await dispatch(userSlice.actions.setUserData(response.data))
    return response
}

export const SendTransferThunk = (token: string, dataForTransaction: ITransacton) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.sendTransfer(token, dataForTransaction)
        dispatch(getTransactionsListThunk(token))
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const createTemplate = (token: string, dataForTemplate: ITransacton) => async (dispatch: AppDispatch) => {
    try {
        return await Api.createTemplate(token, dataForTemplate)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const getTemplateListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getTemplateList(token)
        await dispatch(setTemplateList(res.data))
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

}

export const getTemplateByIdThunk = (token: string, id: string | number | undefined) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getTemplateById(token, id)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteTemplateThunk = (token: string, id: number) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.deleteTemplate(token, id)
        dispatch(getTemplateListThunk(token))
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendCurrencyExchangeAmount = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchange(body)
    try {
        await dispatch(setCurrencyExchangeResult(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountCheck = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchange(body)
    try {
        await dispatch(setCurrencyExchangeResultCheck(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountReverse = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchangeRevert(body)
    try {
        await dispatch(setCurrencyExchangeResult(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountReverseCheck = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchangeRevert(body)
    try {
        await dispatch(setCurrencyExchangeResultCheck(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCardRequest = (token: string, dataForTransaction: FormikValues) => async (dispatch: AppDispatch) => {
    try {
        return await Api.sendCardRequest(token, dataForTransaction)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteOldCardRequest = (token: string, data: any) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.deleteOldCardRequest(token, data)
        await dispatch(userSlice.actions.setCardInfo(res))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const getCardsInfo = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getCardInfo(token)
        await dispatch(userSlice.actions.setCardInfo(res.data.cards))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendCardTopUp = (token: string, data: any) => async (dispatch: AppDispatch) => {
    try {
       return await Api.sendCardTopUp(token, data)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const getCardTransactionList = (token: string, san: string, year: string, month: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setIsLoading(true))
        const res = await Api.getCardTransactionList(token, san, year, month)
        await dispatch(cardTransactionsSlice.actions.setCardTransactionsData(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    } finally {
        dispatch(setIsLoading(false))
    }
}
export const activatePhysicalCard = (token: string, dataForTransaction: FormikValues) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.activatePhysicalCard(token, dataForTransaction)
        getCardsInfo(token)
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const sendPhysicalCardRequest = (token: string, dataForTransaction: FormikValues) => async (dispatch: AppDispatch) => {
    try {
        return await Api.sendPhysicalCardRequest(token, dataForTransaction)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const updateCard = (token: string, dataForTransaction: FormikValues) => async (dispatch: AppDispatch) => {
    try {
       const res = await Api.updateCard(token, dataForTransaction)
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const suspendCard = (token: string, dataForTransaction: FormikValues) => async (dispatch: AppDispatch) => {
    try {
       return await Api.suspendCard(token, dataForTransaction)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
export const resetPasswordThunk = (data: any) => async () => {
    return await Api.resetPassword(data)
}

export const newPasswordThunk = (data: any) => async () => {
    return await Api.newPassword(data)
}

export const createNewPasswordThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.createNewPassword(token, data)
}

export const getTopUpInfo = (token: string, data: any) => () => {
    return Api.getTopUpInfo(token, data)
}

export const getPhoneListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getPhoneList(token)
        dispatch(setPhoneList(res.data))
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const addNewPhoneThunk = (token: string, phone: any) => async (dispatch: AppDispatch) => {
    const res = await Api.addNewPhone(token, phone)
    return res
}

export const getCodeForVerifyPhoneThunk = (token: string, phoneId: any) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getCodeForVerifyPhone(token, phoneId)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendCodeThunk = (token: string, phoneId: any, code: string) => async (dispatch: AppDispatch) => {
    return await Api.sendVerifyCode(token, phoneId, code)

}

export const deletePhoneThunk = (token: string, phoneId: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.deletePhone(token, phoneId);
        dispatch(getPhoneListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setMainPhoneThunk = (token: string, phoneId: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.setMainPhone(token, phoneId);
        dispatch(getPhoneListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setSmsCodeThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.setSmsCode(token);
        dispatch(userSlice.actions.setIsSmsCode(true))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const getStatementThunk = (token: string, accountInfo: any) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getStatement(token, accountInfo)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendContactFormData = (data: IContactData) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendContactForm(data)
}

export const sendNewEmailThunk = (email: { email: string; }, token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        const res = await Api.sendNewEmail(token, email)
        dispatch(getUserEmailsListThunk(token))
        dispatch(getUserEmailVerifyCodeThunk(token, res.data.id))
        return res.data.id
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        } else {
            throw e;
        }
    } finally {
        dispatch(setDisabled(false))
    }
}

export const getUserEmailVerifyCodeThunk = (token: string, emailId: number) => async (dispatch: AppDispatch) => {
    try {
        await Api.getUserEmailVerifyCode(token, emailId)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendConfirmationCodeThunk = (emailId: string | undefined, code: string, token: string) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendEmailConfirmationCode(emailId, code, token)
}

export const setTokenState = (isValid: boolean) => async (dispatch: AppDispatch) => {
    dispatch(setTokenStatus(isValid))
}

export const disableSmsAutentication = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.disableSmsCode(token);
        dispatch(userSlice.actions.setIsSmsCode(false))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const disable2faAutentication = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.disable2faCode(token);
        dispatch(userSlice.actions.setIs2faCode(false))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const getUserEmailsListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getUserEmailsList(token)
        dispatch(setEmailsList(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteUserEmailThunk = (emailId: number, token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.deleteUserEmail(emailId, token)
        dispatch(getUserEmailsListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setUserEmailAsMainThunk = (emailId: number, token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.setUserEmailAsMain(token, emailId)
        dispatch(getUserEmailsListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}
