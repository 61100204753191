import React, {useEffect, useState} from 'react'
import s from './StatementTable.module.css'
import Table, {ColumnsType} from "antd/es/table";
import {ConfigProvider} from 'antd';
import {useAppSelector} from "../../../../../hooks/redux";
import {ITransaction} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {toHumanDate} from "../../../../../helpers/ConvertDate";
import {ReactComponent as SortIconAsc} from "../../../../../assets/newImg/personalAccount/sortArrow.svg";
import {ReactComponent as SortIconDesc} from "../../../../../assets/newImg/personalAccount/sortArrowDown.svg";
import {ReactComponent as SortIcons} from "../../../../../assets/newImg/personalAccount/sortIcons.svg";
import {useSelector} from "react-redux";
import {selectTransactionsByFilter} from "../../../../../store/reducers/TransactionsSlice/selector";
import img from "../../../../../assets/newImg/personalAccount/sortArrowDown.svg";
interface DataType {
    key: string;
    date: JSX.Element;
    transferN: string;
    payer: string;
    recipient: string;
    type: string;
    amount: JSX.Element;
    balance: string;
    description?: string,

}

const columns: ColumnsType<DataType> = [
    {
        title: 'date',
        dataIndex: 'date',
        sorter: true,
        className: `${s.dateCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    {
        title: 'transfer №',
        dataIndex: 'transferN',
    },
    {
        title: 'payer',
        dataIndex: 'payer',
    },
    {
        title: 'recipient',
        dataIndex: 'recipient',
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'reference',
        dataIndex: 'reference',
    },
    // Table.EXPAND_COLUMN,
    {
        title: 'amount',
        dataIndex: 'amount',
        align: 'right',
        sorter: true,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        },
        className: `${s.amountColumn}`
    },
    {
        title: 'balance',
        dataIndex: 'balance',
        align: 'right'
    },
];

const checkPaymentData = (item: ITransaction) => {
    if (item.toAccount) {
        return item.toAccount
    } else {
        if (item.recipient) {
            return item.recipient
        }

        if (item?.recipientDetails?.ibanCode) {
            return item.recipientDetails.ibanCode
        }

        if (item?.recipientDetails?.swiftCode) {
            return item.recipientDetails.swiftCode
        }

        if (item?.recipientDetails?.recipientsSwiftBic) {
            return item.recipientDetails.recipientsSwiftBic
        }
    }
}

export const StatementTable = () => {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const sortedTransactions = useSelector(selectTransactionsByFilter)
    const chosenAccountData = useAppSelector(state => state.transactionsReducer.filters)

    const tableData: Array<DataType> = sortedTransactions.map((item: ITransaction) => {
        return {
            key: item.transactionNumber,
            date: <><p className={s.dateCellP}>{toHumanDate(item.created).date}</p><p
                className={s.timeCellP}>{toHumanDate(item.created).time}</p></>,
            transferN: item.transactionNumber,
            payer: item.direction === "IN"
                ? checkPaymentData(item)
                : item.fromAccount,
            recipient: item.direction === "IN"
                ? item.fromAccount
                : checkPaymentData(item),
            type: <><p className={s.transaction_type}>{item.transactionType}</p></>,
            reference: item.reference,
            amount: <p
                className={item.direction === 'IN' ? `${s.in}` : `${s.out}`}>{item.direction === 'OUT' && '-'}{item.amount + ' ' + item.currency}</p>,
            balance: item.balance + ' ' + item.currency,
            description: 'test'
        }
    })

    /*
        useEffect(() => {
            const startIndex = (currentPage - 1) * 15;
            const endIndex = startIndex + 15;
            const paginatedData = tableData?.slice(startIndex, endIndex);
            setCurrentData(paginatedData);
        }, [currentPage]);


        const handlePageChange = (page:number) => {
            setCurrentPage(page);
        };

      const customItemRender = (current, type, originalElement) => {
            if (type === 'page' && current === currentPage) {
                return <p style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    fontSize: 18,
                    fontWeight: 500,
                    fontFamily: 'Cera Pro'
                }}>{current}</p>;
            }
            return originalElement;
        };
*/

    return (
        <div className={s.tableWrapper}>
            <div className={s.headerWrapper}>
                <div className={s.infoWrapper}>
                    <p className={s.line}>Account number: <b>{chosenAccountData.accountNumber}</b></p>
                    <p className={s.line}>Currencies: <b>
                        {chosenAccountData.currency}
                    </b></p>
                    <p className={s.line}>Period: from <b>{chosenAccountData.from}</b> to <b>{chosenAccountData.to}</b>
                    </p>
                </div>
                {/*<p className={s.balance}>Start balance: <span>11.88 USD</span></p>*/}
            </div>

            {
                isMobile
                    ? <div className={s.transactionsListMobile}>
                        {
                            sortedTransactions.map((item: ITransaction) =>
                                <div key={item.transactionNumber} className={s.transfer}>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Date</span></div>
                                            <p className={s.dateCellP}>{toHumanDate(item.created).date}</p><p
                                                    className={s.timeCellP}>{toHumanDate(item.created).time}</p>
                                        </div>
                                        <div>
                                            <div className={s.blockTitle}><span>Transfer №</span></div>
                                            <p className={s.dateCellP}>{item.transactionNumber}</p>
                                        </div>
                                    </div>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Type</span></div>
                                            <p className={s.dateCellP}>{item.transactionType}</p>
                                        </div>
                                    </div>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Reference</span></div>
                                            <p className={s.dateCellP}>{item.reference}</p>
                                        </div>
                                    </div>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Payer</span></div>
                                            <p className={s.dateCellP}>{item.fromAccount}</p>
                                        </div>
                                    </div>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Recipient</span></div>
                                            <p className={s.dateCellP}>{item.recipient ? item.recipient : item.toAccount}</p>
                                        </div>
                                    </div>

                                    <div className={s.mobileTransferRow}>
                                        <div className={s.mobileTransferBlock}>
                                            <div className={s.blockTitle}><span>Amount</span></div>
                                            <p className={item.direction === 'IN' ? `${s.in}` : `${s.out}`}>
                                                {item.direction === 'OUT' && '-'}
                                                {item.amount + ' ' + item.currency}
                                            </p>
                                        </div>
                                        <div>
                                            <div className={s.blockTitle}><span>Balance</span></div>
                                            <p className={s.dateCellP}>{item.balance} {item.currency}</p>
                                        </div>
                                    </div>

                                </div>
                            )}
                    </div>
                    : <ConfigProvider theme={{
                        components: {
                            Pagination: {
                                colorPrimary: '#FFFFFF',
                                colorPrimaryHover: '#FFFFFF',
                                colorBgContainer: 'var(--primary-color)',
                                colorLink: '#FFFFFF'
                            },
                        },
                    }}>
                        <Table
                            bordered={false}
                            style={{marginTop: 48}}
                            rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                            className={s.statementTable}
                            columns={columns}
                            pagination={{position: ['bottomCenter'], defaultPageSize: 15}}
                            //for info
                            expandable={{
                                expandIcon: ({expanded, onExpand, record}) =>
                                    (<img alt="Arrow Down" src={img} className={expanded ? s.open : s.close}
                                          onClick={e => onExpand(record, e)}/>)
                                ,
                                expandedRowRender: (record) => <p style={{margin: 0}}>{record.description}</p>,
                                expandRowByClick: true,

                            }}
                            dataSource={tableData}
                        />
                    </ConfigProvider>
            }

            {/*<ul className={s.summaryWrapper}>*/}
            {/*    <li className={s.summaryLine}><p className={s.summaryTitle}>Credit turnover:</p><p className={`${s.summaryValue} ${s.in}`}>5.49 USD</p></li>*/}
            {/*    <li className={s.summaryLine}><p className={s.summaryTitle}>Debit turnover:</p><p className={`${s.summaryValue} ${s.out}`}>-5.49 USD</p></li>*/}
            {/*    <li className={s.summaryLine}><p className={s.summaryTitle}>Fiat balance:</p><p className={`${s.summaryValue} ${s.sum}`}>11.88 USD</p></li>*/}
            {/*</ul>*/}
        </div>
    )
}
