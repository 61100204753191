import React, {useEffect, useMemo, useState} from 'react';
import s from './Templates.module.css'
import {Formik} from 'formik';
import plus from '../../../assets/newImg/personalAccount/whitePlus.svg'
import Table, {ColumnsType} from "antd/es/table";
import Icon from '@ant-design/icons';
import {ReactComponent as Trash} from '../../../assets/newImg/personalAccount/trash.svg';
import {ConfirmModal} from "./ConfirmModal/ConfirmModal";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ITemplate} from "../../../types/interfaces";
import MyInput from "../../../components/ui/MyInput/MyInput";
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import TransparentButton from "../../../components/ui/TransparentButton/TransparentButton";
import CustomDropdownForAccounts from "../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../components/ui/CustomDropdown/CustomDropdown";
import {getTemplateListThunk} from "../../../store/reducers/ActionCreators";
import {useSelector} from "react-redux";
import {selectTemplatesByFilter} from "../../../store/reducers/TransactionsSlice/selector";
import {setFiltersTemplates} from "../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useAccountsForSelect} from "../../../hooks/useAccountsForSelect";

interface DataType {
    name: JSX.Element | string,
    recipient: string,
    type: string,
    action: JSX.Element | string,

}

const columns: ColumnsType<DataType> = [
    {
        title: 'Account number',
        dataIndex: 'accountNumber',
    },
    {
        title: 'name',
        dataIndex: 'name',
    },
    {
        title: 'recipient',
        dataIndex: 'recipient',
    },
    {
        title: 'type',
        dataIndex: 'type',
        className: `${s.typeColumn}`
    },
    {
        title: 'action',
        dataIndex: 'action',
        className: `${s.actionColumn}`
    },
];
const Templates = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const accountsList = useAccountsForSelect()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const selectedTemplatesList = useSelector(selectTemplatesByFilter)
    const initTemplatesList = useAppSelector(state => state.transactionsReducer.templates)

    const templatesNames = useMemo(() => {
        const names = initTemplatesList.map((template: ITemplate) => template.templateName);
        names.push("ALL");
        return names;
    }, [initTemplatesList]);

    useEffect(() => {
        if (token) {
            dispatch(getTemplateListThunk(token));
        }
    }, [dispatch, token]);

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [TemplateForDelete, setTemplateForDelete] = useState({
        name: '',
        id: null! as number
    })

    const deletePaymentTemplate = (id: number, name: string) => {
        setTemplateForDelete({
            ...TemplateForDelete,
            id: id,
            name: name
        })
        setShowConfirmModal(true)
    }

    const tableData = selectedTemplatesList?.map((item: ITemplate) => {
        return {
            key: item.id,
            accountNumber: item.account,
            name: <div onClick={() => {
                handleNavigate(item.id, item.type)
            }} className={s.linkDiv}>{item.templateName}</div>,
            recipient: `${item.toAccount ? item.toAccount : item.recipient}`,
            type: item.type,
            action: <div className={s.actionBlock} onClick={() => deletePaymentTemplate(item.id, item.templateName)}>
                <Icon className={s.deleteIcon} component={() => <Trash/>} rev={undefined}/> <p>Delete</p>
            </div>
        }
    })

    const handleNavigate = (param?: string | number, templateType?: string | number) => {
        navigate(`new-template/${param}/${templateType}`)
    }

    return (
        <>
            <ConfirmModal setShowModal={setShowConfirmModal} showModal={showConfirmModal}
                          TemplateForDelete={TemplateForDelete}

            />
            <div className={s.formTemplatesWrapper}>
                <h2 className={s.transfersWrapperTitle}>List of Templates</h2>

                <Formik
                    initialValues={{
                        accountNumber: '',
                        templateName: '',
                        recipient: '',
                    }}
                    onSubmit={(values) => {
                        dispatch(setFiltersTemplates(values))
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.accountInfoRow}>
                                <div className={s.dropdown_wrapper}>
                                    <p className={s.labelText}>Account Number</p>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        id={'accountNumber'}
                                        name={'accountNumber'}
                                        disableZeroBalance={true}
                                    />
                                </div>

                                <div className={s.dropdown_wrapper}>
                                    <p className={s.labelText}>Template name</p>
                                    <CustomDropdown
                                        items={templatesNames}
                                        id={'templateName'}
                                        name={'templateName'}
                                    />
                                </div>
                            </div>

                            <div className={s.inputsWrapper}>
                                <div className={s.inputWrapper}>
                                    <p className={s.labelText}>Recipient</p>
                                    <MyInput
                                        name={'recipient'}
                                        id={'recipient'}
                                        value={values.recipient}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={s.buttonWrapper}>
                                    <TransparentButton
                                        large
                                        type={'submit'}
                                        title={'Filter'}/>
                                </div>
                            </div>
                        </form>)}
                </Formik>
            </div>
            <div className={s.transfersWrapper}>
                <Table
                    bordered={false}
                    className={s.transfersTable}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                />

                {
                    selectedTemplatesList.map((template: ITemplate) =>
                        <div key={template.id} className={s.templateMobile}>
                            <div>
                                <p className={s.templateLabel}>Account number</p>
                                <p>
                                    <span className={s.templateValue}>
                                        {template.account}
                                    </span>
                                </p>
                            </div>

                            <div>
                                <p className={s.templateLabel}>Recipient</p>
                                <p>
                                    <span className={s.templateValue}>
                                        {template.toAccount ? template.toAccount : template.recipient}
                                    </span>
                                </p>
                            </div>

                            <div className={s.flex}>
                                <div>
                                    <p className={s.templateLabel}>Name</p>
                                    <p>
                                        <span onClick={() => {handleNavigate(template.id, template.type)}} className={s.linkDiv}>
                                            {template.templateName}
                                        </span>
                                    </p>
                                </div>

                                <div>
                                    <p className={s.templateLabel}>Type</p>
                                    <p>
                                        <span className={s.templateValue}>
                                            {template.type}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div>
                                <p className={s.templateLabel}>Action</p>
                                <div className={s.actionBlock} onClick={() => deletePaymentTemplate(template.id, template.templateName)}>
                                    <Icon className={s.deleteIcon} component={() => <Trash/>} rev={undefined}/> <p>Delete</p>
                                </div>
                            </div>

                        </div>)
                }

                <div className={s.tableButtonWrapper}>
                    <MyBtn large icon={plus} style={{width: 180}}
                           title={'New Template'}
                           onClick={() => {
                               handleNavigate('create', 'new')
                           }}/>
                </div>
            </div>
        </>
    );
};

export default Templates;


