import React, {useState} from 'react';
import s from './BankTransfer.module.css'
import BankTransferStepOne from "./BankTransferStepOne/BankTransferStepOne";
import BankTransferStepTwo from "./BankTransferStepTwo/BankTransferStepTwo";

const BankTransfer = () => {

    const [current, setCurrent] = useState(0);
    const [transferData, setTransferData] = useState({})

    const steps = [
        {
            content: <BankTransferStepOne setCurrent={setCurrent}
                                          current={current}
                                          setTransferData={setTransferData}
            />
        },
        {
            content: <BankTransferStepTwo setCurrent={setCurrent}
                                          current={current}
                                          transferData={transferData}
            />
        },
    ];

    return (
        <>
            <div className={s.wrapper}>
                {steps[current].content}
            </div>
            <div className={s.description_wrapper}>
                <p>
                    Internal transfers are possible in any currency. All internal transfers reach the recipient within a
                    minute.
                </p>
                <p>
                    If the specified recipient is incorrect,  the money will automatically be refunded to your account
                    within 30 days or, in case you initiate a refund, in 3 days.
                </p>
            </div>
        </>
    );
};

export default BankTransfer;
