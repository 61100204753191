import React from 'react';
import secure from "../../../../assets/newImg/publicPages/services/paymentService/secure.svg";
import convenient from "../../../../assets/newImg/publicPages/services/paymentService/convenient.svg";
import efficient from "../../../../assets/newImg/publicPages/services/paymentService/efficient.svg";
import dedicated from "../../../../assets/newImg/publicPages/services/paymentService/dedicated.svg";
import styles from "../../../../styles/styles.module.css";
import ServicesHeaderBlock from "../../../../components/ServicesHeaderBlock/ServicesHeaderBlock";
import imageSrc from "../../../../assets/newImg/publicPages/services/paymentService/main.png";
import s from "./PaymentServiceProvider.module.css";
import SettingsCard from "../../../../components/SettingsCard/SettingsCard";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import support from "../../../../assets/newImg/publicPages/services/moneyOrders/help_money_orders.svg";
import ContactUsForm from "../../../../components/ContactUsForm/ContactUsForm";
import {motion} from 'framer-motion';
import {useNavigate} from "react-router-dom";
import {brandConfig} from '../../../../config/config';

const PaymentServiceProvider = () => {
    const title = "Your Trusted Payment Service Provider"
    const navigate = useNavigate()
    const cards = [
        {
            icon: secure,
            title: 'Secure and Reliable:',
            text: 'We prioritize the security and reliability \n' +
                'of your payments. With state-of-the-art encryption technology and stringent security measures, you can ' +
                'trust that your transactions are protected. Experience peace of mind knowing that your financial ' +
                'information is handled with the utmost care.'
        },
        {
            icon: convenient,
            title: 'Convenient Payment Options:',
            text: 'We understand the importance of flexibility when it comes to payments. Whether you prefer online ' +
                'transfers, mobile payments, or traditional methods, we offer a variety of payment options \n' +
                'to suit your preferences. Seamlessly make payments and manage your finances with ease.'
        },
        {
            icon: efficient,
            title: 'Efficient & Timely Processing:',
            text: 'We value your time, which is why we strive to process your payments efficiently and promptly. Our ' +
                'streamlined payment processing system ensures that your transactions are executed in a timely manner, ' +
                'allowing you to stay on top of your financial obligations.'
        },
        {
            icon: dedicated,
            title: 'Dedicated Support:',
            text: 'We are committed to providing dedicated support for all your payment needs. Our friendly and ' +
                'knowledgeable team is available to assist you, answer your inquiries, and address any concerns \n' +
                'you may have. Experience exceptional customer service that puts your needs first.'
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
            <div className={styles.wrapper}>

                <ServicesHeaderBlock title={title} imageSrc={imageSrc}/>

                <div className={s.page_description}>
                    At {brandConfig.companyNameSimple}, we are your trusted payment service provider, offering a range of secure and convenient
                    payment solutions to meet your financial needs.
                </div>

                <div className={s.cards_block}>
                    {
                        cards.map((card) => {
                            return (
                                <SettingsCard icon={card.icon} title={card.title} text={card.text}/>
                            )
                        })
                    }
                </div>

                <div className={s.button_wrapper}>
                    <div className={s.button_block}>
                        <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                    </div>
                </div>

                <div className={s.support_wrapper}>
                    <div className={s.container_support}>
                        <div>
                            <div className={s.support_subtitle}>
                                Creating a positive and empowering experience with virtual currencies
                            </div>
                            <div className={s.support_title}>
                                money transfers, money orders and payments are <span className={s.underline}>effortless & secure</span>
                            </div>
                            <div className={s.support_text}>
                                Join us and discover the convenience and peace of mind that comes with choosing a trusted
                                financial partner.
                            </div>
                        </div>
                        <div className={s.img}>
                            <img src={support} alt=""/>
                        </div>
                    </div>

                    <div className={s.support_text_mobile}>
                        Join us and discover the convenience and peace of mind that comes with choosing a trusted
                        financial partner.
                    </div>

                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default PaymentServiceProvider;
