import React, {useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import s from '../../../../styles/styles.module.css'
import KycBusiness from "./KycBusiness/KycBusiness";
import { motion } from 'framer-motion';
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {getPhoneListThunk} from "../../../../store/reducers/ActionCreators";

const Settings = () => {

    const user = useAppSelector(state => state.userReducer.user)
    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getPhoneListThunk(token))
    }, [dispatch, token])

    useEffect(() => {
        if (user?.userData?.accountType?.includes('business')) {
            return
        }
        navigate("/personal_account/personal/settings")
    },[navigate, user?.userData?.accountType])

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Tabs>
                <TabList className={s.tabstitle_block}>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>KYC</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Profile Settings</Tab>
                </TabList>

                <TabPanel>
                    <KycBusiness/>
                </TabPanel>
                <TabPanel>
                    <ProfileSettings/>
                </TabPanel>
            </Tabs>
        </motion.div>
    );
};

export default Settings;
