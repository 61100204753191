import React, {useState} from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {
    bankCodeListEGPBank,
    transferReasons
} from "../../../Constants/StaticData";
import PhoneCodesInput from "../../ui/PhoneCodesInput/PhoneCodesInput";
import {getPhoneCode} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";

interface FormikProps {
    values: any;
    errors: any;
    touched: any;
    handleChange: (e: React.ChangeEvent<any>) => void;

    recipientsBankCountry: any;
    updateParentCode?: (value: any) => any; // Function that accepts a string

}

const EgpBank: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            recipientsBankCountry,
                                            updateParentCode
                                        }) => {


    const [innerCode, setInnerCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    });


    if (getPhoneCode(recipientsBankCountry, null) !== innerCode) {
        setInnerCode(getPhoneCode(recipientsBankCountry, null))
        updateParentCode && updateParentCode(getPhoneCode(recipientsBankCountry, null))
    }


    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * STREET
                        </div>
                        <MyInput id={"street"}
                                 name={"street"}
                                 touched={touched.street}
                                 value={values.street}
                                 onChange={handleChange}
                                 isError={errors.street}
                        />
                        {errors.street && touched.street &&
                            <div className={s.error_message}>{errors.street}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * Phone number
                    </div>
                    <PhoneCodesInput
                        id='phoneNumber'
                        name='phoneNumber'
                        onChange={handleChange}
                        value={values.phoneNumber}
                        error={errors.phoneNumber}
                        touched={touched.phoneNumber}
                        selectable={false}
                        onlyCountries={["eg"]}
                    />
                    {errors.phoneNumber &&
                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK CODE
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"bankCode"}
                            name={"bankCode"}
                            touched={touched.bankCode}
                            isError={errors?.bankCode?.name}
                            items={bankCodeListEGPBank}
                        />
                        {errors.bankCode && touched.bankCode &&
                            <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK ACCOUNT
                    </div>
                    <MyInput id={"bankAccount"}
                             name={"bankAccount"}
                             touched={touched.bankAccount}
                             value={values.bankAccount}
                             onChange={handleChange}
                             isError={errors.bankAccount}
                    />
                    {errors.bankAccount && touched.bankAccount &&
                        <div className={s.error_message}>{errors.bankAccount}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * TRANSFER REASON
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"transferReason"}
                            name={"transferReason"}
                            touched={touched.transferReason}
                            isError={errors?.transferReason?.name}
                            items={transferReasons}
                        />
                        {errors.transferReason && touched.transferReason &&
                            <div
                                className={s.error_message}>{errors?.transferReason?.name}</div>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default EgpBank;
