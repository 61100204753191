import React, {ChangeEvent} from 'react';
// import './index.css'
import s from './CustomCheckbox.module.css'
import classnames from "classnames";

type PropsType = {
    handleChange: { (e: ChangeEvent<any>): void; <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void; }
    isChecked: boolean
    id?: string
    name?: string,
    title?: string
}

const CustomCheckbox = (props: PropsType) => {
    return (
        <label className={s.checkbox_label}>
            <input
                className={s.myCheckbox}
                type="checkbox"
                onChange={props.handleChange}
                id={props.id}
                name={props.name}
                checked={props.isChecked}
            />
            <span
                className={classnames(s.checkbox ,props.isChecked ? s.checkbox_active : "")}
                aria-hidden="true"
            />
            {props.title && <p className={s.checkboxTitle}>{props.title}</p>}
        </label>
    );
};

export default CustomCheckbox;
