import React, {Dispatch, SetStateAction} from 'react';
import s from "../CardsStepTwo/CardsStepTwo.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {deleteOldCardRequest} from "../../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";


type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}



const CardsStepFive = (props: PropsType) => {

    const token = useAppSelector(state => state.userReducer.user.userToken);
    const dispatch = useAppDispatch();

    const data = {
        'currency': 'EUR',
    }
    const handleClick = async () => {
        try {
            dispatch(deleteOldCardRequest(token, data));
            props.setCurrent(0);
        } catch (error) {
            console.error("Error deleting old card:", error);
        }
    };


    return (<>
        <div className={s.pageWrapper}>
            <div className={s.title}>Your Virtual Card Request has been Rejected</div>
            <div className={s.subtitle}>We encourage you to contact our support team for further assistance and clarification on this decision.
            </div>
            <div className={s.buttonWrapper}>
                <MyBtn
                    title={'New Card Request'}
                    large
                    onClick={handleClick}
                    type={"submit"}/>
            </div>
        </div>
    </>);
};

export default CardsStepFive;