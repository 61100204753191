import React, {useEffect, useState} from 'react';
import s from "./EmailsList.module.css";
import classnames from "classnames";
import MyBtn from "../ui/MyBtn/MyBtn";
import {ReactComponent as Lock} from "../../assets/newImg/personalAccount/lock.svg";
import {ReactComponent as Basket} from "../../assets/newImg/personalAccount/trash.svg";
import {ReactComponent as Back} from "../../assets/newImg/personalAccount/back.svg";
import plus from "../../assets/newImg/personalAccount/plusWhite.svg";
import Icon from "@ant-design/icons";
import CustomModal from "../ui/CustomModal/CustomModal";
import styles from "../ContactUsForm/ContactUsForm.module.css";
import MyInput from "../ui/MyInput/MyInput";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import {useFormik} from "formik";
import * as yup from "yup";
import {emailsListType} from "../../types/interfaces";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    deleteUserEmailThunk,
    getUserEmailsListThunk, getUserEmailVerifyCodeThunk,
    sendConfirmationCodeThunk,
    sendNewEmailThunk, setUserEmailAsMainThunk
} from "../../store/reducers/ActionCreators";
import {setDisabled} from "../../store/reducers/UserSlice";

type PropsType = {
    email: any
    emailsList: Array<emailsListType>
}

const validationSchema = yup.object({
    email: yup.string()
        .email("invalid format")
        .required('Required field'),
})

const EmailsList = (props: PropsType) => {

    const [isAddNewEmailPopupOpen, setIsAddNewEmailPopupOpen] = useState(false)
    const [isVerifyPopupShow, setIsVerifyPopupShow] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [IdOfVeifyingEmail, setIdOfVeifyingEmail] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [emailForVerify, setEmailForVerify] = useState('')
    const dispatch = useAppDispatch()
    const token : string = useAppSelector(state => state.userReducer.user.userToken)

    const formikEmails = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(sendNewEmailThunk(values, token))
                .then((res) => {
                    setIdOfVeifyingEmail(res)
                    setIsAddNewEmailPopupOpen(false)
                    setIsVerifyPopupShow(true)
                    setEmailForVerify(values.email)
                    formikHelpers.resetForm()
                }).catch((e) => {
                    if (e.response.data === "This email already exist") {
                        setErrorMessage("This email already exists. Please try another email.")
                    } else {
                        setErrorMessage('Something went wrong. Please try again later.')
                    }
                    setIsAddNewEmailPopupOpen(false)
                    setIsErrorModalOpen(true)
                });
        }
    })

    useEffect(() => {
        formikEmails.handleReset('')
        // eslint-disable-next-line
    }, [isAddNewEmailPopupOpen]);

    const formikVerify = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: (values, formikHelpers)  => {
            verifyEmail(IdOfVeifyingEmail)
            formikHelpers.resetForm()
        }
    })

    const addNewEmail = () => {
        setIsAddNewEmailPopupOpen(true)
    }

    const deleteEmail = (emailId: number) => {
        dispatch(deleteUserEmailThunk(emailId, token))
    }

    const startEmailVerify = (emailId: string) => {
        dispatch(getUserEmailVerifyCodeThunk(token, Number(emailId)))
        setIsVerifyPopupShow(true)
    }

    const verifyEmail = (emailId?: string | undefined) => {

        dispatch(sendConfirmationCodeThunk(emailId, formikVerify.values.code, token))
            .then((res) => {
                dispatch(getUserEmailsListThunk(token))
                setIsSuccessModalOpen(true)
            })
            .catch((e) => {
                if (e.response.data === "Invalid email verify code") {
                    setErrorMessage('Wrong confirmation code.')
                } else {
                    setErrorMessage('Something went wrong. Please try again later.')
                }
                setIsErrorModalOpen(true)
            })
            .finally(() => {
                setIsVerifyPopupShow(false)
                dispatch(setDisabled(false))
            })
    }

    const setEmailAsMain = (emailId: number) => {
        dispatch(setUserEmailAsMainThunk(emailId, token))
    }

    return (
        <div className={s.phone_numbers_block}>

            {/*error modal*/}

            <CustomModal
                setIsModalOpen={setIsErrorModalOpen}
                isModalOpen={isErrorModalOpen}
                type={"error"}
            >
                <div className={styles.popup_title}>
                    Failed!
                </div>
                <div className={styles.popup_subtitle}>
                    {errorMessage}
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            {/*success modal*/}

            <CustomModal
                setIsModalOpen={setIsSuccessModalOpen}
                isModalOpen={isSuccessModalOpen}
            >
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your email has been verified and added to your list.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            {/*verify email*/}

            <CustomModal
                setIsModalOpen={setIsVerifyPopupShow}
                isModalOpen={isVerifyPopupShow}
                type={"confirm"}
            >
                <div className={s.add_phone_popup_title}>
                    SMS authentication
                </div>
                <form onSubmit={formikVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.verify_subtitle}>
                            Mail with confirmation code sent to <span
                            className={s.email_for_verify}>{emailForVerify}</span> mailbox
                        </div>
                        <div className={s.input_label}>
                            confirmation code
                        </div>
                        <MyInput
                            value={formikVerify.values.code}
                            onChange={formikVerify.handleChange}
                            id={"code"}
                            name={"code"}
                            touched={formikVerify.touched.code}
                        />
                    </div>

                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => setIsVerifyPopupShow(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Verify'}
                            type={"submit"}
                        />
                    </div>
                </form>
            </CustomModal>

            {/*add new email*/}

            <CustomModal
                setIsModalOpen={setIsAddNewEmailPopupOpen}
                isModalOpen={isAddNewEmailPopupOpen}
                type={"confirm"}
            >
                <div className={s.add_phone_popup_title}>
                    Add Email
                </div>
                <form onSubmit={formikEmails.handleSubmit} className={s.from_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            Email
                        </div>
                        <MyInput
                            id='email'
                            name='email'
                            onChange={formikEmails.handleChange}
                            value={formikEmails.values.email}
                            isError={formikEmails.errors.email}
                            touched={formikEmails.touched.email}
                        />
                        {formikEmails.errors.email && <div className={s.error}>{formikEmails.errors.email}</div>}
                    </div>

                    <div className={s.buttonsWrapper}>
                        <div className={s.button_block}>
                            <TransparentButton
                                buttonType={'confirm'}
                                medium
                                title={'Cancel'}
                                onClick={() => setIsAddNewEmailPopupOpen(false)}
                                icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            />
                        </div>
                        <div className={s.button_block}>
                            <MyBtn
                                buttonType={'confirm'}
                                medium
                                title={'Add'}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </form>
            </CustomModal>

            <div className={s.list_title}>
                Email Addresses
            </div>
            <div className={s.accounts_list_header}>

                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Email address
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Verify
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Set main
                    </div>
                </div>
                <div className={s.list_block}>
                    <div className={s.list_block_title}>
                        Delete
                    </div>
                </div>
            </div>
            {
                props?.emailsList?.map((item: emailsListType, index: any) => {
                    return (
                        <div className={s.accounts_list} key={index}>

                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {item.email}
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isVerify
                                            ? <div>Verified</div>
                                            : <div className={s.need_to_verify} onClick={() => startEmailVerify(String(item.id))}>
                                                <Icon component={() => <Lock/>} alt="" rev={undefined}/>
                                                Verify
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    {
                                        item.isMain
                                            ? <div>Main</div>
                                            : <div className={s.set_as_main} onClick={() => setEmailAsMain(item.id)}>
                                                <div className={s.tick_svg}></div>
                                                Set as main
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div className={classnames(s.flex, item.isMain ? s.disabled : s.action)}
                                         onClick={() => !item.isMain && deleteEmail(item.id)}
                                    >
                                        <Icon component={() => <Basket/>} rev={undefined}/>
                                        <div className={item.isMain ? s.disabled : s.delete_text_active}>Delete</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <div className={s.button_wrapper}>
                <div className={s.button_block}>
                    <MyBtn
                        icon={plus}
                        title={'New email'}
                        onClick={() => addNewEmail()}
                        localDisable={props.emailsList.length === 5}
                    />
                </div>
            </div>

        </div>
    );
};

export default EmailsList;
