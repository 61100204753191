import React from 'react';
import EmailAutentication from "../../../components/Autentications/EmailAutentication/EmailAuntification";

const RegistrationAutentication = () => {
    return (
        <div>
            <EmailAutentication/>
        </div>
    );
};

export default RegistrationAutentication;
