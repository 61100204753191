import React, {Dispatch, SetStateAction} from 'react';
import s from './CustomModal.module.css'
import {motion} from 'framer-motion';
import classNames from 'classnames';

type ModalType = {
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean,
  children: any,
  type?: 'confirm' | 'error' | 'contact',
}

const CustomModal = ({setIsModalOpen, isModalOpen, children, type}: ModalType) => {
  return (
    <motion.div
      initial={{opacity: 0, scale: 1}}
      animate={{opacity: 1, scale: 1}}
      transition={{duration: 0.5}}
    >
      {isModalOpen
        ? <div className={s.wrapper}>
          <div className={classNames(s.content_wrapper, {
            [s.confirmModal]: type === 'confirm',
            [s.error]: type === 'error',
            [s.contact]: type === 'contact'
          })}>
            <div className={type === 'contact' ? s.short_content : s.content }>
              {children}
            </div>

          </div>
        </div>
        : <></>
      }
    </motion.div>
  );
};

export default CustomModal;
