import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import s from "../../../styles/styles.module.css";
import {motion} from "framer-motion";
import TwoFactor from "./TwoFactor/TwoFactor";
import Session from "./Session/Session";
import WithdrawalSettings from "./WithdrawalSettings/WithdrawalSettings";

const Security = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Tabs>
                <TabList className={s.tabstitle_block}>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Two-Factor Authentication</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Session</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Withdrawal Settings</Tab>
                </TabList>

                <TabPanel>
                    <TwoFactor/>
                </TabPanel>
                <TabPanel>
                    <Session/>
                </TabPanel>
                <TabPanel>
                    <WithdrawalSettings/>
                </TabPanel>
            </Tabs>
        </motion.div>
    );
};

export default Security;
