import React from 'react';
import s from "../LegalAndPrivacy.module.css";
import {brandConfig} from '../../../../config/config';

const SeventhPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                1. INTRODUCTION
            </div>

            <div className={s.point_text}>
                1.1. Acceptance of Terms. Welcome to {brandConfig.companyNameSimple}. These terms of service
                (the “<b>Terms</b>”) govern how you may use <a href={`${brandConfig.companyWebsite}`}>{brandConfig.companyWebsite}/</a>&nbsp;
                (collectively the “Site”) and constitute a binding legal agreement between
                us and you. You should read these Terms carefully before using the Site.
                This Site is operated by {brandConfig.companyName}. (“<b>{brandConfig.companyNameSimple.toUpperCase()}</b>”, “<b>us</b>”, “<b>we</b>”,
                “<b>our</b>”). By accessing or using this Site or otherwise indicating your consent,
                you agree to be bound by these Terms and the documents referred to in
                them. If you do not agree with or accept any of these Terms, you must
                cease using the Site immediately. If you have any questions about this Site,
                please contact {brandConfig.companyLegalEmailAddress}.
            </div>

            <div className={s.point_text}>
                1.2. Entities. If you are using or accessing our Site on behalf of an entity,
                then you affirm: (i) that you are an authorized representative or agent of
                that entity with the authority to bind such entity to these Terms; and (ii) that
                such entity accepts and is bound by these Terms. In such a circumstance,
                the words “you” and “your” as used in these Terms will refer to and apply to
                both that entity and you personally.
            </div>

            <div className={s.point_text}>
                1.3. Privacy Policy. These Terms include our Privacy Policy. Our Privacy
                Policy will be subject to these Terms in the event of any conflict or
                inconsistency.
            </div>

            <div className={s.point_text}>
                1.4. Additional Terms. We may supplement these Terms with additional
                terms (“<b>Additional Terms</b>”) relating to specific content, goods,
                opportunities, or services made available or supplied by us. You will have
                the opportunity to review these Additional Terms before accepting them. If
                you reject Additional Terms, certain content, goods, opportunities, or
                service might not be available to you.
            </div>

            <div className={s.point_text}>
                1.5. Trading of Digital Assets. All matters concerning the trading, deposit,
                withdrawal, and storage of digital assets through use of the Site or other
                {brandConfig.companyNameSimple} services are governed by the {brandConfig.companyNameSimple} User Agreement. You
                must read and accept the {brandConfig.companyNameSimple} User Agreement before you will be
                permitted to trade, deposit, withdrawal, or store digital assets through use
                of the Site or other {brandConfig.companyNameSimple} services. During the onboarding process for
                becoming a client of the {brandConfig.companyNameSimple} services, {brandConfig.companyNameSimple} will provide you
                with a copy of the {brandConfig.companyNameSimple} User Agreement for your review and
                acceptance. If you accept the {brandConfig.companyNameSimple} User Agreement, such agreement
                will be deemed to be Additional Terms and will form part of these Terms. In
                the event of any inconsistency between these Terms and the {brandConfig.companyNameSimple}
                User Agreement, the {brandConfig.companyNameSimple} User Agreement will govern to the extent of
                the inconsistency.
            </div>

            <div className={s.point_text}>
                1.6. Age. This Site may only be used by persons at least 18 years of age or
                the age of majority under applicable law to form a binding contract with
                {brandConfig.companyNameSimple} if such age is older than 18. If you do not meet this
                requirement, you must not use the Site.
            </div>

            <div className={s.point_text}>
                1.7. Accessibility. We seek to make this Site as accessible as possible. If
                you have any problems accessing this Site or the content contained on it,
                please contact us at {brandConfig.companyLegalEmailAddress}.
            </div>

            <div className={s.point_text}>
                1.8. Modifications. We reserve the right in our in our sole discretion to
                revise and update these Terms from time to time. Any and all such
                modifications are effective immediately upon posting and apply to all
                access to and continued use of the Site. You agree to periodically review
                these Terms in order to be aware of any such modifications and your
                continued use of the Site constitutes your acceptance of such
                modifications.
            </div>

            <div className={s.point_text}>
                1.9. Modification to Site. The Site, including its functionalities, features,
                pricing, information, and materials, may be changed, withdrawn, or
                terminated by {brandConfig.companyNameSimple} at any time in IBERBANCO’s sole discretion
                without notice. {brandConfig.companyNameSimple} will not be liable if for any reason all or any
                part of the Site is restricted to users or unavailable at any time or for any
                period.
            </div>

            <div className={s.point_text}>
                1.10. Notice of Binding Arbitration; Waiver of Class Action. <b>THESE TERMS
                CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 13
                WHICH PROVIDES THAT YOU WILL RESOLVE ALL DISPUTES
                THROUGH MANDATORY AND BINDING ARBITRATION, UNLESS YOU
                OPT OUT THROUGH THE MECHANISM PROVIDED IN THESE TERMS
                OR ARE IN A PROVINCE, STATE, OR COUNTRY IN WHICH THE
                COURTS WILL NOT PERMIT YOU TO CONSENT TO BINDING
                ARBITRATION. THIS MEANS THAT, IN THE EVENT OF A DISPUTE
                WITH {brandConfig.companyNameSimple}, YOU WILL NOT BE ABLE TO HAVE THAT
                DISPUTE RESOLVED BY A JUDGE OR A JURY. ADDITIONALLY, IN
                SECTION 13 OF THESE TERMS, YOU ALSO WAIVE YOUR RIGHT TO
                A JURY TRIAL AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN
                CLASS ACTIONS AGAINST {brandConfig.companyNameSimple}. PLEASE CAREFULLY READ
                SECTION 13.</b>
            </div>

            <div className={s.point_title}>
                2. REGISTRATION OBLIGATIONS
            </div>

            <div className={s.point_text}>
                2.1. Account Registration. To use certain areas of this Site, you must first
                complete the registration process to create an account ("<b>Account</b>") and
                select and register a unique user name and password (collectively,
                "<b>Credentials</b>"). Your Account and Credentials are specific to you and may
                not be shared with or transferred to any other person. You are solely
                responsible for maintaining the confidentiality of your Credentials and you
                will be held responsible for any harm caused by disclosing or resulting from
                any unauthorized use of your Credentials. You will not permit any other
                person to use your Account or Credentials, and you will immediately notify
                {brandConfig.companyNameSimple} if you know or suspect that your Account or Credentials have
                been used by any other person.
            </div>

            <div className={s.point_text}>
                2.2 Registration Data. During the registration process, you will provide true,
                accurate, current and complete information about yourself as prompted by
                the Site's registration form (such information being the "<b>Registration
                Data</b>"). You will also maintain and promptly update the Registration Data to
                keep it true, accurate, current and complete. If you provide any information
                that is untrue, inaccurate, not current or complete, or if {brandConfig.companyNameSimple} has
                reasonable grounds to suspect that such information is untrue, inaccurate,
                not current or complete, {brandConfig.companyNameSimple} has the right to suspend or terminate
                your account and refuse any and all current or future use of the Site (or any
                portion thereof) by you.
            </div>

            <div className={s.point_text}>
                2.3. Responsibility for Account. {brandConfig.companyNameSimple} may act upon any
                communication that is given through your Account or by using your
                Credentials. {brandConfig.companyNameSimple} is not required to verify the actual identity or
                authority of a person using your Account or Credentials, but {brandConfig.companyNameSimple}
                may in its discretion at any time require verification of the identity of a
                person seeking to access your Account and may deny access to and use of
                your Account if {brandConfig.companyNameSimple} is not satisfied with the verification. If
                {brandConfig.companyNameSimple}, in its discretion, considers your Account or Credentials to be
                unsecure or to have been used inappropriately, then {brandConfig.companyNameSimple} may
                immediately cancel the Account or Credentials without any notice to you.
                You may be required to change your Credentials from time to time.
            </div>

            <div className={s.point_title}>
                3. RESTRICTIONS ON USE
            </div>

            <div className={s.point_subtitle}>
                3.1. As a condition of your use of the Site, you agree:
            </div>

            <div className={s.point_text}>
                (a) not to use the Site for any purpose that is unlawful under applicable law,
                or prohibited by these Terms; <br/>
                (b) not to use the Site to distribute false, inaccurate, or misleading
                information;<br/>
                (c) not to impersonate or attempt to impersonate any person;<br/>
                (d) not to engage in any conduct that inhibits any person’s ability to use the
                Site;<br/>
                (e) not to defame or disparage anybody or make comments of an obscene,
                derogatory or offensive manner or otherwise use the Site or its content in a
                way that brings us or any third party into disrepute or causes us to be liable
                to any third party;<br/>
                (f) not to reverse engineer, decompile, copy, modify, distribute, transmit,
                license, sublicense, display, revise, perform, transfer, sell or otherwise
                make available to any third party or otherwise publish, deep-link, create
                derivative works from or exploit in any way the Site or its content or
                materials except as permitted by us under these Terms or as expressly
                provided under applicable law and/or under any Additional Terms;<br/>
                (g) not to data scrape, index, or data mine the Site;<br/>
                (h) not to use the Site to distribute viruses or malware or other similar
                harmful software code;<br/>
                (i) not to represent or suggest that we endorse any other business, product
                or service unless we have separately agreed to do so in writing; <br/>
                (j) that you are solely responsible for all costs and expenses you may incur
                in relation to your use of the Site and shall be solely responsible for
                keeping your password and other account details confidential.
            </div>

            <div className={s.point_text}>
                3.2. We reserve the right to prevent or suspend your access to the Site if
                you do not comply with any part of these terms and conditions or any
                applicable law.
            </div>

            <div className={s.point_title}>
                4. OWNERSHIP, USE AND INTELLECTUAL PROPERTY RIGHTS
            </div>

            <div className={s.point_text}>
                4.1 Reservation of Rights. This Site and all intellectual property rights in the
                Site (including without limitation any, text, graphics, logos, information or
                software, and any compilation (arrangement or assembly) or use thereof)
                are owned by us and/or our licensors. We and our licensors reserve all our
                intellectual property rights (which include without limitation all copyright,
                trademarks, domain names, design rights, database rights, patents and all
                other intellectual property rights of any kind) whether registered or
                unregistered anywhere in the world.
            </div>

            <div className={s.point_text}>
                4.2. No Circumvention. Nothing in these Terms grants you any rights in the
                Site other than as necessary to enable you to access the Site. You agree
                not to try to circumvent or delete any intellectual property notices contained
                on the Site and in particular in any digital rights or other security technology
                embedded or contained within any Site content.
            </div>

            <div className={s.point_text}>
                4.3. Trademarks. {brandConfig.companyNameSimple} and {brandConfig.companyName} are our trademarks.
                Other trademarks and trade names may also be used on this Site. The use
                or misuse of any trademarks or any other content on the Site except as
                provided in these terms and conditions is strictly prohibited. Nothing
                contained on the Site shall be construed as granting, by implication,
                estoppel or otherwise, any license or right to use any trademark without our
                prior written permission.
            </div>

            <div className={s.point_text}>
                4.4 Materials. {brandConfig.companyNameSimple} authorizes you to view, download and print a
                single copy of materials and content provided on this Site for your personal
                use only and only in connection with your use of the Site and products and
                services ordered from us. You may not remove any trademark, copyright or
                other proprietary notices from such copy nor modify the material or content
                in any way. Except as otherwise set out in these Terms, any copying or
                reproduction of this Site’s materials or content, in whole or in part, for
                commercial purposes or distribution, re-transmission, republication,
                modification, reverse engineering, sale or other exploitation of this Site or
                this Site materials or content without the prior written permission of
                {brandConfig.companyNameSimple} is strictly prohibited. We reserve the right to take such steps
                as we deem necessary, including legal action, to restrain such
                unauthorized and prohibited activity and we reserve the right to suspend or
                terminate your access to any part of this Site immediately, without prior
                notice, at its sole discretion. You are solely and fully responsible for all
                consequences, however remote, resulting from your use of this Site or its
                materials and content.
            </div>

            <div className={s.point_title}>
                5. SOFTWARE
            </div>

            <div className={s.point_text}>
                5.1. Software. Where any software is made available for downloading from
                the Site, this is our copyrighted work and/or that of our licensors. You may
                use such software only in accordance with the terms of the end user
                license agreement, if any, which accompanies the software and, subject
                thereto, in accordance with these Terms. All such software is made
                available for downloading solely for your internal use in a non-commercial
                manner (except as otherwise agreed in writing by {brandConfig.companyNameSimple}). Any
                reproduction or redistribution of software not in accordance with the end
                user license agreement and/or these Terms is expressly prohibited, and
                may result in severe civil and criminal penalties.
            </div>

            <div className={s.point_text}>
                5.2. Disclaimer of Warranties. Software made available by {brandConfig.companyNameSimple} is
                warranted only to the extent expressly provided in the terms of its
                applicable end user license agreement. We hereby disclaim all other
                warranties, conditions and other terms (whether express or implied) with
                regard to such software, including all implied warranties and conditions of
                satisfactory quality, freedom from defects, merchantability, non-infringement, and fitness for a
                particular purpose. In no event shall our
                aggregate liability in respect of any such software exceed the limitations of
                liability in the applicable end user license agreement, or in all other cases
                the amount you paid us for the relevant software.
            </div>

            <div className={s.point_title}>
                6. SUBMITTING INFORMATION AND CONTENT TO THE SITE
            </div>

            <div className={s.point_subtitle}>
                6.1 Your Content.
            </div>

            <div className={s.point_text}>
                (a) License. Portions of this Site may allow users to post and exchange
                information and content (such information and content, "User Content"),
                such as images, text, audio, and video, but {brandConfig.companyNameSimple} does not screen,
                edit or review any User Content before it is posted or transmitted. You
                retain the copyright and any other rights you already hold in your User
                Content. By submitting or otherwise posting User Content on our website,
                you grant {brandConfig.companyNameSimple} a worldwide, non-exclusive, perpetual, irrevocable,
                fully-paid up, royalty-free, assignable, transferable, and sub-licensable
                (through multiple tiers) license to exploit, use, access, store, reproduce,
                adapt, translate, publish, publicly perform, publicly display, modify, repost,
                rent out, sublicense, create derivative works from, and distribute your User
                Content, subject to these Terms, without any notice or compensation to you
                or any other person (the “User Content License”). Each time you submit or
                post User Content on the Site, you confirm, represent, and warrant to
                {brandConfig.companyNameSimple} that you have all the rights, power, and authority necessary to
                grant the User Content License. Please note that User Content on our Site
                does not necessarily reflect the views of {brandConfig.companyNameSimple}, and {brandConfig.companyNameSimple}
                disclaims all responsibility for any such User Content and for any losses or
                expenses resulting from their use or appearance on our Site. <br/>
                (b) Responsibility. You understand that all User Content, whether publicly
                posted or privately transmitted when posting, sharing, displaying, sending
                or submitting material using our Site, is the sole responsibility of the person
                from whom it originated. This means that you, and not {brandConfig.companyNameSimple}, are
                entirely responsible: (i) for all User Content that you upload, post, email,
                transmit, or otherwise make available via our Site; and (ii) for ensuring that
                all User Content is accurate, lawful, and does not include misleading
                information or infringe or violate anyone’s rights. {brandConfig.companyNameSimple} does not
                control the User Content posted via our Site and, therefore, does not
                guarantee the accuracy, integrity, or quality of any User Content. You
                therefore agree that you will not hold {brandConfig.companyNameSimple} responsible or liable for
                any inaccuracies or for any errors or omissions in any User Content, or for
                any loss, injury or damages of any kind incurred as a result of the use of or
                reliance upon any User Content posted, emailed, transmitted, or otherwise
                made available via our Site. <br/>
                (c) Removal of Your Content. You acknowledge that {brandConfig.companyNameSimple} does not
                pre-screen, monitor, or modify User Content, but that {brandConfig.companyNameSimple} has the
                right (but not the obligation) to refuse, remove, modify, or delete any User
                Content that is available via our Site that violates these Terms, or is
                otherwise objectionable, in our sole and arbitrary discretion. You
                acknowledge and expressly consent to {brandConfig.companyNameSimple}, accessing,
                preserving, and disclosing your Account information and User Content if
                required to do so by law or if in good faith {brandConfig.companyNameSimple} believes that such
                access, preservation or disclosure is reasonably necessary to: (a) comply
                with legal process; (b) enforce these Terms; (c) respond to claims that any
                User Content violates the rights of third-parties; (d) respond to your
                requests for customer service; or (e) protect the rights, property, or
                personal safety or security of {brandConfig.companyNameSimple}, or our affiliates, staff, users, or
                the public. <br/>
                (d) Derivative Content. {brandConfig.companyNameSimple} owns all intellectual property rights in
                the content we derive or create from User Content (“<b>Derivative Content</b>”).
            </div>

            <div className={s.point_text}>
                6.2. Feedback and Unwanted Submissions. We welcome any questions,
                comments or feedback you might have about the Site or these Terms
                ("Feedback"). Please refer to the Contact section of this Site for our contact
                information. That said, our Site is not a secure means of communication
                and any information or content you supply to us will not be kept
                confidential. For that reason, you should not submit or send to us any
                patentable ideas or patent applications, advertising or marketing
                suggestions, know-how, trade secrets, prototypes or any information,
                written or oral, which you regard as confidential or commercially sensitive
                or valuable (collectively referred to as “Unwanted Submissions”). While we
                value your feedback, you agree not to submit any Unwanted Submissions.
                We shall not be subject to any obligation of confidentiality nor be liable for
                any use and/or disclosure of such Unwanted Submissions. You agree we
                are free to use your Unwanted Submissions as we see fit without any
                obligation to you.
            </div>

            <div className={s.point_text}>
                6.3. License to Feedback and Submissions. If you provide Feedback, or
                Unwanted Submissions, you grant {brandConfig.companyNameSimple} a worldwide, non-exclusive, royalty-free, perpetual, irrevocable,
                transferable, and fully sub-licensable right to exploit, use, access, store, reproduce, adapt, translate,
                publish, publicly perform, publicly display, modify, repost rent out,
                sublicense, create derivative works from, and distribute from the Feedback
                or Unwanted Submissions in any way and for any purpose without
                providing any compensation to you or any other person. You also grant
                {brandConfig.companyNameSimple} the right to use the name you submit with the User Content or
                Feedback, if any, in connection with IBERBANCO’s rights hereunder.
            </div>

            <div className={s.point_text}>
                6.4. Your Representations; Waiver of Moral Rights. Each time you submit
                or post Feedback, Unwanted Submissions or User Content, you represent
                and warrant that any such Feedback, Unwanted Submissions, and User
                Content you supply to us is and shall be your own original work and has
                been lawfully provided to us and that you have all necessary consents to
                provide this to us and that we shall be entitled to disclose your name with
                any such Feedback, Unwanted Submissions, or User Content that we may
                choose to publish. Where you are the author of such content, you agree
                that you waive all moral rights you may have in any such Feedback,
                Unwanted Submissions, or User Content.
            </div>

            <div className={s.point_text}>
                6.5. Personal Information. You agree that any personal information you
                disclose to us may be used by us as described in our Privacy Policy.
            </div>

            <div className={s.point_text}>
                6.6. Infringing Content. As copyright holders ourselves, we respect
                copyright holders’ rights and will quickly respond to any claims of copyright
                infringement reported on the Site. If you find anything on the Site that you
                believe infringes your copyright or the copyrights of a person you are
                authorized to represent, please report the alleged infringement to us
                at {brandConfig.companyLegalEmailAddress}.
            </div>

            <div className={s.point_title}>
                7. ACCURACY OF INFORMATION AND AVAILABILITY OF THE SITE
            </div>

            <div className={s.point_text}>
                7.1. Reliance on Content. While we use reasonable efforts to include
                accurate and up-to-date information on the Site, we do not represent,
                warrant, or promise (whether expressly or by implication) that any,
                information, materials, or content are or remain available, accurate,
                complete and up to date, free from bugs, errors or omissions, or fit or
                suitable for any purpose. Any reliance you may place on the information,
                materials, or content made available on this Site is at your own risk and we
                may suspend or terminate operation of the Site at any time at our sole
                discretion. Content and materials on the Site are provided for your general
                informational purposes only.
            </div>

            <div className={s.point_text}>
                7.2. Advice Disclaimer. WITHOUT LIMITING SECTION 7.1,
                INFORMATION, MATERIALS, AND CONTENT ON THIS SITE ARE MADE
                AVAILABLE FOR INFORMATIONAL PURPOSES ONLY. THE
                INFORMATION, MATERIALS, AND CONTENT MADE AVAILABLE ON
                THIS SITE DOES NOT AND IS NOT INTENDED TO CONVEY
                INVESTMENT ADVICE, FINANCIAL ADVICE, ECONOMIC ADVICE,
                ACCOUNTING ADVICE, TAX ADVICE, LEGAL ADVICE, OR ANY OTHER
                TYPE OF PROFESSIONAL ADVICE. YOU SHOULD NOT RELY ON THE
                INFORMATION, MATERIALS, OR CONTENT ON THE SITE AS A
                SUBSTITUTE FOR, NOR DO THEY REPLACE, PROFESSIONAL
                ADVICE. YOU MUST OBTAIN PROFESSIONAL ADVICE FROM A
                QUALIFIED THIRD-PARTY BEFORE TAKING, OR REFRAINING FROM,
                ANY ACTION OR INACTION ON THE BASIS OF THE INFORMATION,
                MATERIALS, OR CONTENT ON OUR SITE. {brandConfig.companyNameSimple} IS NOT
                RESPONSIBLE OR LIABLE FOR ANY ACTIONS OR INACTION YOU
                TAKE PART AS A RESULT OF THE INFORMATION, MATERIALS, OR
                CONTENT THAT IS AVAILABLE ON THE SITE OR OTHERWISE
                SUPPLIED TO YOU BY US.
            </div>

            <div className={s.point_text}>
                7.3. Availability of Site. While we endeavor to keep the Site available, we
                do not represent, warrant, or guarantee in any way the Site’s continued
                availability at all times or uninterrupted use by you of the Site.
            </div>

            <div className={s.point_title}>
                8. HYPERLINKS AND THIRD-PARTY SITES
            </div>

            <div className={s.point_text}>
                8.1. Third Party Websites. The Site may contain hyperlinks or references to
                third party websites other than the Site. Any such hyperlinks or references
                are provided for your convenience only. We have no control over third party
                websites and accept no responsibility for any content, material, or
                information contained in them. The display of any hyperlink and reference
                to any third-party website does not constitute an endorsement of such third
                party’s website, products or services. Your use of a third-party site may be
                governed by the terms and conditions and privacy policy of that third party
                site.
            </div>

            <div className={s.point_text}>
                8.2. Third Party Content. This Site may include content provided by third
                parties, including from other users and third-party licensors. All statements
                and/or opinions expressed in any such third-party content are solely the
                opinions and the responsibility of the person providing those materials.
                Such materials do not necessarily reflect the opinion of {brandConfig.companyNameSimple}.
                {brandConfig.companyNameSimple} is not responsible or liable to you or any third party for the
                content, information, opinions, or accuracy of any third-party materials.
            </div>

            <div className={s.point_title}>
                9. INDEMNITY
            </div>

            <div className={s.point_text}>
                9.1. You will defend, indemnify and hold harmless {brandConfig.companyNameSimple} and the
                Indemnified Associates (defined below) from and against any and all
                losses, damages, costs, expenses (including legal fees), claims,
                complaints, demands, actions, suits, proceedings, obligations, fines,
                penalties, and liabilities (including settlement payments) arising from any
                third-party claim or action, resulting from, connected with, or relating to: (a)
                your use of this Site, its information, content, or materials, User Content, or
                the other products or services we provide (including those made available
                through the Site); or (b) your negligence, misconduct, or breach of these
                Terms. Notwithstanding the foregoing, {brandConfig.companyNameSimple} retains the right to
                participate in the defense of and settlement negotiations relating to any
                third party claim, complaint, demand, action, suit or proceeding with
                counsel of its own selection at its cost and expense.
                (The “<b>Indemnified Associates</b>” are IBERBANCO’ subcontractors and
                IBERBANCO’s and such subcontractors’ respective officers, directors,
                employees, agents, shareholders, parents, subsidiaries, successors, and
                permitted assigns)
            </div>

            <div className={s.point_title}>
                10. DISCLAIMERS
            </div>

            <div className={s.point_subtitle}>
                10.1. You understand and agree that:
            </div>

            <div className={s.point_text}>
                (a) USE OF THIS SITE AND ITS INFORMATION, CONTENTS, AND
                MATERIALS (INCLUDING, WITHOUT LIMITATION, USER CONTENT) IS
                AT YOUR SOLE RISK. THIS SITE AND ITS INFORMATION, CONTENTS,
                AND MATERIALS (INCLUDING, WITHOUT LIMITATION, USER
                CONTENT) ARE PROVIDED ON AN "AS IS", "AS AVAILABLE" BASIS.
                NEITHER {brandConfig.companyNameSimple} NOR ANY OF ITS EMPLOYEES, AGENTS,
                OFFICERS, DIRECTORS, THIRD PARTY SERVICE PROVIDERS,
                SUCCESSORS, OR ASSIGNS ({brandConfig.companyNameSimple} AND ITS EMPLOYEES,
                AGENTS, OFFICERS, DIRECTORS, THIRD PARTY SERVICE
                PROVIDERS, SUCCESSORS, AND ASSIGNS COLLECTIVELY, THE
                "{brandConfig.companyNameSimple} PARTIES") MAKE ANY WARRANTY OR CONDITION OF
                ANY KIND, WHETHER EXPRESS OR IMPLIED, REGARDING THIS SITE
                OR ITS INFORMATION, CONTENTS, OR MATERIALS (INCLUDING,
                WITHOUT LIMITATION, USER CONTENT) AND THE {brandConfig.companyNameSimple}
                PARTIES SPECIFICALLY DISCLAIM THE IMPLIED WARRANTIES AND
                CONDITIONS OF MERCHANTABLE QUALITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD PARTY
                RIGHTS, TO THE MAXIMUM EXTENT PERMITTED BY LAW. <br/>
                (b) THE {brandConfig.companyNameSimple} PARTIES MAKE NO WARRANTIES OR
                CONDITIONS REGARDING THE QUALITY, RELIABILITY, TIMELINESS
                OR SECURITY OF THE SITE OR THAT THE SITE WILL BE
                UNINTERRUPTED OR ERROR-FREE. THE {brandConfig.companyNameSimple} PARTIES
                ASSUME NO RESPONSIBILITY OR LIABILITY FOR THE DELETION OR
                FAILURE TO STORE OR ACCESS, OR TO STORE OR ACCESS
                PROPERLY, EMAIL MESSAGES AND ELECTRONIC FILES. YOU
                ASSUME THE ENTIRE RISK IN DOWNLOADING OR OTHERWISE
                ACCESSING ANY DATA, FILES, OR OTHER MATERIALS OBTAINED
                FROM THIRD PARTIES AS PART OF THE SITE, EVEN IF YOU HAVE
                PAID FOR VIRUS PROTECTION SERVICES. <br/>
                (c) THE ACCESS TO AND DOWNLOADING OF CONTENT AND
                MATERIAL FROM THIS SITE IS DONE AT YOUR OWN RISK.
                {brandConfig.companyNameSimple} MAKES REASONABLE EFFORTS TO ENSURE THAT THIS
                SITE IS VIRUS-FREE, BUT {brandConfig.companyNameSimple} DOES NOT AT ANY TIME
                GUARANTEE OR WARRANT THAT SUCH CONTENT OR MATERIALS
                ARE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER
                DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR IMPLEMENTING
                SAFEGUARDS TO PROTECT YOUR COMPUTER SYSTEM AND DATA
                AND YOU ARE RESPONSIBLE FOR THE ENTIRE COST OF ANY
                SERVICE, REPAIRS OR CORRECTIONS NECESSARY AS A RESULT
                OF THE USE OF THIS SITE.
            </div>

            <div className={s.point_title}>
                11. WARRANTIES AND LIMITATION OF LIABILITY
            </div>

            <div className={s.point_text}>
                11.1 Dollar Cap. WITHOUT LIMITING SECTION 11.2, TO THE EXTENT
                PERMITTED BY LAW, THE {brandConfig.companyNameSimple} PARTIES CUMULATIVE
                LIABILITY FOR CLAIMS ARISING OUT OF OR RELATED TO THESE
                TERMS, THE SITE, OR ITS INFORMATION, CONTENTS, OR
                MATERIALS WILL NOT EXCEED THE AMOUNT OF FEES YOU PAID
                {brandConfig.companyNameSimple} IN THE THREE-MONTH PERIOD IMMEDIATELY
                PRECEDING THE EVENT GIVING RISE TO THE CLAIM (OR IF YOU
                USED THE SERVICES ON A FREE BASIS, THEN THE {brandConfig.companyNameSimple}
                PARTIES’ LIABILITY WILL NOT EXCEED $10.00 CAD).
            </div>

            <div className={s.point_text}>
                11.2 Limitation of Liability. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, IN NO EVENT WILL THE {brandConfig.companyNameSimple} PARTIES BE
                LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL,
                EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES WHATSOEVER,
                INCLUDING ANY DAMAGES FOR BUSINESS INTERRUPTION, LOSS
                OF USE, LOSS OF DATA, LOSS OF REVENUE OR PROFIT, COST OF
                CAPITAL, LOSS OF BUSINESS OPPORTUNITY, LOSS OF GOODWILL,
                PERSONAL INJURY, LOSS OF LIFE, PAIN AND SUFFERING, OR
                EMOTIONAL DISTRESS, WHETHER ARISING OUT OF BREACH OF
                CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF
                PRIVACY, OR ANY OTHER THEORY OF LIABILITY OR OTHERWISE,
                REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE
                AND WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES.
            </div>

            <div className={s.point_title}>
                12. GOVERNING LAW
            </div>

            <div className={s.point_text}>
                12.1 Governing Law. The Site and these Terms is governed by the laws of
                the Province of Ontario and the federal laws of Canada applicable therein,
                without giving effect to any choice or conflict of law provision, principle or
                rule and notwithstanding your domicile, residence or physical location.
            </div>

            <div className={s.point_title}>
                13. DISPUTES & BINDING ARBITRATION
            </div>

            <div className={s.point_text}>
                13.1 Arbitration. If the courts in your province, state, or country will not
                permit you to consent to binding arbitration, then any legal suit, action, or
                proceeding arising out of or related to these Terms will be instituted
                exclusively in the courts of the Province of Ontario, Canada, and you
                irrevocably submit to the exclusive jurisdiction of such courts in any such
                suit, action, or proceeding. Otherwise, you agree that in the event of any
                dispute between you and {brandConfig.companyNameSimple} arising out of or relating to these
                Terms, the Site (including its information, contents, and materials), or the
                other {brandConfig.companyNameSimple} services, that you and {brandConfig.companyNameSimple} shall consult and
                negotiate with each other and, recognizing your mutual interests, attempt to
                reach a solution satisfactory to both you and {brandConfig.companyNameSimple}. If you and
                {brandConfig.companyNameSimple} do not reach settlement within a period of 60 days, then such
                dispute will be referred to and finally resolved by mandatory and binding
                arbitration administered by the Ontario International Arbitration Centre
                (“OIAC”) pursuant to its applicable Rules.
            </div>

            <div className={s.point_text}>
                13.2 The place of arbitration will be Ontario, Ontario, Canada. The number
                of arbitrators will be set to one unless otherwise required by the Rules. The
                language of the arbitration will be English. The existence and content of the
                arbitration proceedings, including documents submitted by the parties,
                correspondence to and from the OIAC, correspondence to and from the
                arbitrator, and orders and awards issued by the sole arbitrator, shall remain
                strictly confidential and shall not be disclosed to any third party (except for
                professional advisors) without the express written consent from the other
                party unless: (a) (i) the disclosure to the third party is reasonably required
                in the context of conducting the arbitration proceedings; and (ii) the third
                party agrees unconditionally in writing to be bound by the confidentiality
                obligation stipulated herein; or (b) such disclosure is required by applicable
                law.
            </div>

            <div className={s.point_text}>
                13.3 The parties agree that either party will have a right of appeal pursuant
                to the OIAC Rules with the grounds of appeal to be on a question of law or
                a question of mixed fact and law. Except for the appeal process under the
                OIAC Rules, the parties agree that they will not appeal any arbitration
                decision, or decision of an Appeal Tribunal, to any court.
            </div>

            <div className={s.point_text}>
                13.4 Opt-Out. You may opt out of the above arbitration clause by following
                the procedure described in this paragraph. If you opt out, then neither you
                nor we will be able to require the other to participate in arbitration for the
                purposes of resolving any dispute, claim or controversy between you and
                {brandConfig.companyNameSimple} arising out of or relating to these Terms, the Site (including its
                information, contents, and materials), or the other {brandConfig.companyNameSimple} services. To
                opt out, you must, within 30 days of accepting these Terms, deliver to
                {brandConfig.companyNameSimple} a clear written statement indicating that you wish to opt out of
                the arbitration provisions in these Terms. The statement must contain: <br/>
                (a) your name; <br/>
                (b) your mailing address; <br/>
                (c) your telephone number; <br/>
                (d) your email address; <br/>
                (e) your Account name on the {brandConfig.companyNameSimple} services (if any). The opt-out statement must be delivered to the following address: <br/>
            </div>

            <div className={s.point_text}>
                {brandConfig.companyName}. <br/>
                15TH FLOOR 4 ROBERT SPECK PARKWAY <br/>
                MISSISSAUGA, ON, CANADA L4Z1S1
            </div>

            <div className={s.point_text}>
                13.3 Waiver of Class Action and Jury Trial. You hereby waive and agree to
                waive any right you may have to commence or participate in any class
                action against {brandConfig.companyNameSimple} related to any claim and, where applicable,
                you also agree to opt out of any class proceedings against {brandConfig.companyNameSimple}.
                Where applicable, if a dispute arises between us and you, you hereby
                waive and agree to waive any right you may have to participate in a trial by
                jury with respect to that dispute.
            </div>

            <div className={s.point_title}>
                14. GENERAL
            </div>

            <div className={s.point_text}>
                14.1 Force Majeure. No delay, failure, or default on the part of
                {brandConfig.companyNameSimple} will constitute a breach of these Terms to the extent caused
                by: <br/>
                (a) acts of war, terrorism, invasion, riots or other acts of civil unrest; <br/>
                (b) hurricanes, earthquakes, pandemic, epidemic, flood, fire, tsunami, solar
                flare, electro-magnetic pulse, explosion, other acts of God or of nature; <br/>
                (c) strikes or other labor disputes; (d) national or regional emergency; <br/>
                (e) passage of Law or any action taken by a governmental or public authority,
                including imposing an embargo, workplace shutdown or staffing
                restrictions, health order, export or import restriction, quota or other
                restriction or prohibition, or any complete or partial government shutdown; <br/>
                (f) national or regional shortage of adequate power or telecommunications
                or transportation facilities; or (g) other causes beyond IBERBANCO’s
                reasonable control.
            </div>

            <div className={s.point_text}>
                14.2 Survival. Sections 1.1, 1.2, 1.4, 2.3, 4.1, 4.3, 5, 6, 7, 8, 9 ,10, 11, 12,
                13, and 14 and all other provisions of these Terms which must survive
                termination to fulfill their purpose, will survive termination or expiration of
                these Terms.
            </div>

            <div className={s.point_text}>
                14.3 No Third-Party Beneficiaries. <br/>
                (a) Except as set forth in Paragraph 14.3 <br/>
                (b), the parties do not confer any legal, equitable or other rights or remedies of any nature whatsoever under
                or by reason of these Terms upon any person other than the parties to
                these Terms and their respective successors and permitted assigns. <br/>
                (b) The parties hereby designate each of the Indemnified Associates as
                third-party beneficiaries of Sections 9 having the right to enforce that
                Section. The parties hereby designate each of the {brandConfig.companyNameSimple} Parties as
                third-party beneficiaries of Sections 10 and 11 having the right to enforce
                those Sections.
            </div>

            <div className={s.point_text}>
                14.4 Relationship. The relationship between the parties is that of
                independent contractors. Nothing contained in these Terms will be
                construed as creating any agency, partnership, joint venture, franchise, or
                other form of joint enterprise, employment or fiduciary relationship between
                the parties, and neither party will have authority to contract for or bind the
                other party in any manner whatsoever.
            </div>

            <div className={s.point_text}>
                14.5 No Waiver. The failure of {brandConfig.companyNameSimple} to assert a right hereunder or
                to insist upon compliance with any term or condition will not constitute a
                waiver of that right or excuse any subsequent non-performance of any such
                term or condition by you.
            </div>

            <div className={s.point_text}>
                14.6 Headings. The headings used in these Terms are included for
                convenience only and will not limit or otherwise affect these Terms.
            </div>

            <div className={s.point_text}>
                14.7 Severability. If any term or provision of these Terms is invalid, illegal
                or unenforceable in any jurisdiction, such invalidity, illegality or
                unenforceability shall not affect any other term or provision of these Terms
                or invalidate or render unenforceable such term or provision in any other
                jurisdiction.
            </div>

            <div className={s.point_text}>
                14.8 Assignment. We may assign these Terms in part or in their entirety,
                including our rights, interests, and obligations hereunder, without notice to
                you or your consent. These Terms are personal to you and you may not
                assign these Terms nor your rights, interests, or obligations under these
                Terms to any person without our express written consent.
            </div>

            <div className={s.point_text}>
                14.9 Entire Agreement. These Terms, together with our Privacy
                Policy, Risk Disclosure Statement, Additional Terms (if any), and any other
                agreement incorporated by reference, constitutes the sole and entire
                agreement between you and {brandConfig.companyNameSimple} regarding the subject matter
                herein and supersedes all prior and contemporaneous understandings,
                agreements, representations and warranties, both written and oral,
                regarding such subject matter.
            </div>

            <div className={s.point_text}>
                14.10 Language. The parties acknowledge that they have required that the
                Terms and all related documents be prepared in English. Les parties
                reconnaissent avoir exigé que les présentes modalités de vente et tous les
                documents connexes soient rédigés en anglais.
            </div>

            <div className={s.point_text}>
                14.11 Enurement. These Terms will enure to the benefit of and be binding
                upon the parties to these Terms and their respective successors, heirs, and
                permitted assigns.
            </div>

            <div className={s.point_text}>
                14.12 Equitable Relief. You hereby acknowledge and agree that any
                breach by you of these Terms would result in harm to {brandConfig.companyNameSimple}, and
                that {brandConfig.companyNameSimple} could not be adequately compensated for such harm by
                monetary award. Accordingly, you hereby agree that in the event of any
                such breach, in addition to all other remedies available to {brandConfig.companyNameSimple} at
                law or equity, {brandConfig.companyNameSimple} will be entitled as a matter of right, and without
                posting bond or proving damages, to, notwithstanding Section 13, apply to
                any court of competent jurisdiction for such equitable relief by way of
                restraining order, injunction, decree or otherwise as may be appropriate to
                ensure compliance by you with these Terms.
            </div>

            <div className={s.point_text}>
                14.13 Interpretation. For the purposes of these Terms: <br/>
                (a) the words “include,” “includes” and “including” will be deemed to be followed by the
                words “without limitation”; <br/>
                (b) the word “or” is not exclusive; <br/>
                (c) the words “herein,” “hereof,” “hereby,” “hereto” and “hereunder” refer to these Terms
                as a whole; <br/>
                (d) unless the context otherwise requires, references herein: <br/><br/>
                (i) to Articles, Sections, Schedules and Exhibits mean the Articles and
                Sections of, and Schedules and Exhibits attached to, these Terms, <br/>
                (ii) to an agreement, instrument or other document means such agreement,
                instrument or other document as amended, supplemented and modified
                from time to time to the extent permitted by the provisions thereof, <br/>
                (iii) to a statute means such statute as amended from time to time and includes
                any successor legislation thereto and any regulations promulgated
                thereunder; <br/><br/>
                (e) the Schedules referred to herein will be construed with, and
                as an integral part of, these Terms to the same extent as if they were set
                forth verbatim herein; <br/>
                (f) Section and Schedule headings do not affect the interpretation of these Terms; <br/>
                (g) words in the singular include the plural and those in the plural include the singular; <br/>
                (h) a reference to “writing” or “written” includes email, unless otherwise stated.
            </div>

            <div className={s.point_text}>
                14.14 Notice. We may give you notice at the email address (or if no email
                address, other contact information) you provide to {brandConfig.companyNameSimple} during the
                registration process or at your last known address. Except as otherwise
                stated herein, you may only give us notice by letter at:
            </div>

            <div className={s.point_text}>
                {brandConfig.companyName}. <br/>
                15TH FLOOR 4 ROBERT SPECK PARKWAY <br/>
                MISSISSAUGA, ON, CANADA L4Z1S1
            </div>

        </div>
    );
};

export default SeventhPanel;
