import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {bankCodeListKESBank} from "../../../Constants/StaticData";

interface FormikProps {
    values: {
        name?: string;
        city?: string;
        street?: string;
        bankAccount?: string;
        relationshipToSender?: string;
        bankCode?: {
            name: string,
            value: string
        };
    };
    errors: {
        name?: string;
        city?: string;
        street?: string;
        bankAccount?: string;
        relationshipToSender?: string;
        bankCode?: {
            name?: string,
            value?: string
        };
    };
    touched: {
        name?: boolean;
        city?: boolean;
        street?: boolean;
        bankAccount?: boolean;
        relationshipToSender?: boolean;
        bankCode?: {
            name?: boolean,
            value?: boolean
        };
    };
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const GhsBank: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                        }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * STREET
                    </div>
                    <MyInput id={"street"}
                             name={"street"}
                             touched={touched.street}
                             value={values.street}
                             onChange={handleChange}
                             isError={errors.street}
                    />
                    {errors.street && touched.street &&
                        <div className={s.error_message}>{errors.street}</div>}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        CITY
                    </div>
                    <MyInput id={"city"}
                             name={"city"}
                             touched={touched.city}
                             value={values.city}
                             onChange={handleChange}
                             isError={errors.city}
                    />
                    {errors.city && touched.city &&
                        <div className={s.error_message}>{errors.city}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK CODE
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"bankCode"}
                            name={"bankCode"}
                            touched={touched.bankCode}
                            isError={errors?.bankCode?.name}
                            items={bankCodeListKESBank}
                        />
                        {errors.bankCode && touched.bankCode &&
                            <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK ACCOUNT
                    </div>
                    <MyInput id={"bankAccount"}
                             name={"bankAccount"}
                             touched={touched.bankAccount}
                             value={values.bankAccount}
                             onChange={handleChange}
                             isError={errors.bankAccount}
                    />
                    {errors.bankAccount && touched.bankAccount &&
                        <div className={s.error_message}>{errors.bankAccount}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            RELATIONSHIP TO SENDER
                        </div>
                        <MyInput id={"relationshipToSender"}
                                 name={"relationshipToSender"}
                                 touched={touched.relationshipToSender}
                                 value={values.relationshipToSender}
                                 onChange={handleChange}
                                 isError={errors.relationshipToSender}
                        />
                        {errors.relationshipToSender && touched.relationshipToSender &&
                            <div
                                className={s.error_message}>{errors.relationshipToSender}</div>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GhsBank;
