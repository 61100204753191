import React from 'react';
import s from "../LegalAndPrivacy.module.css";
import {brandConfig} from '../../../../config/config';

const EighthPannel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                1. Introductory terms:
            </div>

            <div className={s.point_text}>
                The Merchant Acceptance Policy (the “Policy”) of {brandConfig.companyName}. (the “Company”) defines the criteria for
                accepting new clients and stipulates the client categorization criteria which shall be adhered to by the
                Company.
            </div>

            <div className={s.point_title}>
                2. Objectives of the Policy:
            </div>

            <div className={s.point_text}>
                The objectives of this Policy are as follows:
                <ul>
                    <li>To establish explicit criteria for the acceptance of the clients.</li>
                    <li>To establish procedures to verify the identity of the clients.</li>
                    <li>To reduce legal, reputational or AML risks.</li>
                </ul>
                This Policy is to be read in conjunction with any related operational guidelines and policies which may
                be issued from time to time.
            </div>

            <div className={s.point_title}>
                3. General Principles of the Policy
            </div>

            <div className={s.point_text}>
                The General Principles of the Policy are the following:
                <ul>
                    <li>The Company shall classify clients into various risk categories and based on the risk
                        perception decide on the acceptance criteria for each category of client.
                    </li>
                    <li>
                        Where the client is a new client, an account can be activated only after the relevant due
                        diligence and identification measures and procedures have been conducted.
                    </li>
                    <li>
                        All documents and data required to be obtained from the client must be collected before
                        accepting a new Client.
                    </li>
                    <li>
                        No client shall be accepted in anonymous or fictitious names.
                    </li>
                </ul>
            </div>

            <div className={s.point_title}>
                4. Criteria for accepting new clients
            </div>

            <div className={s.point_text}>
                The Company will take a holistic view of the risks factors it have identified that, together, will
                determine
                the level of risk associated with a business relationship or occasional transaction.
            </div>

            <div className={s.point_text}>
                The basic steps in conducting a ML/TF/PF risk assessment consist of:
                <ul>
                    <li>
                        Identifying the inherent ML/TF/PF risks in relation to: <br/>
                        Customers (including beneficial owners) <br/>
                        Country or geographic risk factors <br/>
                        Products and services that the Company offers and delivery channel of the products and
                        services <br/>
                    </li>
                    <li>Assessing the identified ML/TF/PF Risks</li>
                    <li>Evaluating the effectiveness of the controls designed to mitigate the identified ML/TF/PF
                        risks
                    </li>
                    <li>Determination of Residual Risk</li>
                    <li>Documentation of the Risk Assessment results</li>
                    <li>Monitoring/ Reviewing risks and updating the Risk Assessment on an on-going basis</li>
                </ul>
            </div>

            <div className={s.point_text}>
                The Company evaluates each client for possible participation in financial crimes and assigns a certain
                rating to it. Depending upon difficulty, there are 3 standard types of risk ratings:
                <ul>
                    <li>
                        Low Risk (Simplified due Diligence)
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Simplified due diligence is the lowest level of verification. We use Simplified due Diligence when there
                is little chance or risk that customers engage in money laundering or terrorist financing.
                <ul>
                    <li>
                        Medium (Normal) Risk (Customer due Diligence)
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Customer due diligence is a kind of basic analysis of the client. The Company verifies a customer’s
                identity to a sufficient level of confidence.
                <ul>
                    <li>
                        High Risk (Enhanced due Diligence)
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Enhanced due Diligence means increased control over customers who are more likely to participate in
                financial
                crimes due to their nature of business or operations.
            </div>

            <div className={s.point_subtitle}>
                Custome risk factors
            </div>

            <div className={s.point_text}>
                High Risk
                <ul>
                    <li>
                        The business relationship is conducted in unusual circumstances (e.g. significant unexplained
                        geographic distance between the financial institution and the customer).
                    </li>
                    <li>
                        A new business with very little history.
                    </li>
                    <li>
                        High-net-worth individual.
                    </li>
                    <li>
                        Political Exposed Persons ("PEP"), including customers who are relatives of PEPs or entities
                        whose UBOs is a PEP.
                    </li>
                    <li>
                        A customer with a dubious reputation as per public information available.
                    </li>
                    <li>
                        Companies that have nominee shareholders or shares in bearer form.
                    </li>
                    <li>
                        The ownership structure of the company appears unusual or excessively complex given the
                        nature of the company’s business.
                    </li>
                    <li>
                        Unusually large transactions and unusual transaction patterns that do not have a reasonable,
                        clear economic or lawful purpose or that are not characteristic of the given business specifics.
                    </li>
                    <li>
                        The customer is a legal person or a legal arrangement, which is engaged in holding personal
                        assets.
                    </li>
                    <li>
                        The customer is a cash-intensive business.
                    </li>
                    <li>
                        Clients from countries which inadequately apply FATF’s recommendations.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Medium (Normal) Risk
                <ul>
                    <li>
                        Explained geographic distance between the financial institution and the customer.
                    </li>
                    <li>
                        A moderate number of funds transfers.
                    </li>
                    <li>
                        The company with moderate rate of ownership complicity structure.
                    </li>
                    <li>
                        Moderate client base stability.
                    </li>
                    <li>
                        A new business with sufficient level history.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Low Risk
                <ul>
                    <li>
                        Stable, known customer.
                    </li>
                    <li>
                        Financial institutions – where they are subject to requirements to combat money laundering and
                        terrorist financing consistent with the FATF Recommendations, have effectively implemented those
                        requirements, and are effectively supervised or monitored in accordance with the FATF
                        Recommendations to ensure compliance with those requirements.
                    </li>
                    <li>
                        The customer is a company listed on a regulated market, which is subject to disclosure
                        obligations that establish requirements for ensuring sufficient transparency regarding the
                        beneficial owner.
                    </li>
                    <li>
                        Public administrations or enterprises.
                    </li>
                </ul>
            </div>

            <div className={s.point_subtitle}>
                Country or geographic risk factors
            </div>

            <div className={s.point_text}>
                High Risk
                <ul>
                    <li>
                        Residents of the countries identified by credible sources, such as mutual evaluation or
                        detailed assessment reports or published follow-up reports, as not having adequate AML/CFT
                        systems.
                    </li>
                    <li>
                        Residents of the countries subject to sanctions, embargos or similar measures.
                    </li>
                    <li>
                        Residents of the countries identified by credible sources as having significant levels of
                        corruption or other criminal activity.
                    </li>
                    <li>
                        Residents of the countries or geographic areas identified by credible sources as providing
                        funding or support for terrorist activities, or that have designated terrorist organizations
                        operating within their country.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Medium (Normal) Risk
                <ul>
                    <li>
                        Residents of the moderate risk countries.
                    </li>
                    <li>
                        Residents of the third countries with moderate effective mechanisms to combat money laundering
                        and financing of terrorism.
                    </li>
                    <li>
                        Residents of the third countries, which credible sources have identified as countries with a
                        moderate extent of corruption or other criminal activity.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Low Risk
                <ul>
                    <li>
                        Residents of the countries with effective mechanisms to combat money laundering and financing
                        of terrorism.
                    </li>
                    <li>
                        Residents of the third countries, which credible sources have identified as countries with a
                        limited extent of corruption or other criminal activity.
                    </li>
                    <li>
                        Residents of the third countries which, on the basis of credible sources such as mutual
                        evaluations, reports on detailed assessment or publicly available follow-up reports, have
                        requirements to combat money laundering and financing of terrorism which are consistent with the
                        FATF recommendations and which implement these requirements in an efficient manner.
                    </li>
                    <li>
                        The customer is a legal person governed by public law established in the country of our company
                        residency.
                    </li>
                </ul>
            </div>

            <div className={s.point_subtitle}>
                Product, service, transaction or delivery channel risk factors
            </div>

            <div className={s.point_text}>
                High Risk
                <ul>
                    <li>
                        Private banking.
                    </li>
                    <li>
                        Anonymous transactions (which may include cash).
                    </li>
                    <li>
                        Non-face-to-face business relationships or transactions.
                    </li>
                    <li>
                        Payment received from unknown or un-associated third parties.
                    </li>
                    <li>
                        Unusually large transactions and unusual transaction patterns that do not have a reasonable,
                        clear economic or lawful purpose or that are not characteristic of the given business specifics.
                    </li>
                    <li>
                        Suddenly Active.
                    </li>
                    <li>
                        New products and new business practices, including new delivery mechanism, and the use of
                        new or developing technologies for both new and pre-existing products.
                    </li>
                    <li>
                        A transaction related to oil, arms, precious metals, precious metal products or tobacco products.
                    </li>
                    <li>
                        A transaction related to ivory or protected species.
                    </li>
                    <li>
                        Casinos or online gaming.
                    </li>
                    <li>
                        Pharmaceuticals and online drug providers.
                    </li>
                    <li>
                        Tobacco/E-cigarettes/ cannabis products.
                    </li>
                    <li>
                        Telemarketing sales.
                    </li>
                    <li>
                        Adult entertainment and dating services.
                    </li>
                    <li>
                        Airlines, accommodations, and ticketing agents.
                    </li>
                    <li>
                        Subscription services (magazines, collectibles, etc.).
                    </li>
                    <li>
                        Financial counseling/credit repair/debt reduction.
                    </li>
                    <li>
                        High-value businesses.
                    </li>
                    <li>
                        Any other Clients whose nature entail a higher risk of money laundering or terrorist financing,
                        incl. crypto currency exchange
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Medium (Normal) Risk
                <ul>
                    <li>
                        Alternative Investment.
                    </li>
                    <li>
                        Trade/Export Finance.
                    </li>
                    <li>
                        Only non-face-to-face via intermediary.
                    </li>
                    <li>
                        Brokerage (could be considered as high-risk activity).
                    </li>
                    <li>
                        Commercial Banking (could be considered as high-risk activity).
                    </li>
                    <li>
                        Credit & Other Card Banking.
                    </li>
                    <li>
                        Investment Banking.
                    </li>
                    <li>
                        Retail Banking (could be considered as high-risk activity).
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Low Risk
                <ul>
                    <li>
                        Financial products or services that provide appropriately defined and limited services to
                        certain types of customers, so as to increase access for financial inclusion purposes.
                    </li>
                    <li>
                        Products where the risks of money laundering and terrorist financing are managed by other
                        factors such as monetary limits or transparency-enhancing measures.
                    </li>
                    <li>
                        Basic payment services relating to a liability account
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                The list is not exhaustive and as such the Company may consider other pertinent risk factors applicable
                to the nature, size and complexity of its business.
            </div>

            <div className={s.point_text}>
                The list is not exhaustive and as such the Company may consider other pertinent risk factors applicable
                to the nature, size and complexity of its business.
            </div>

            <div className={s.point_title}>
                5. Accepting new customer
            </div>

            <div className={s.point_text}>
                The Company concludes with its customers based on the applicable law of Canada that are associated with
                the business activity of the Company, the responsible employees must act with the highest degree of care
                and caution in customer identification and identity verification. This applies also to the process of
                collecting necessary data, relevant information, and mandatory documentation.
            </div>

            <div className={s.point_text}>
                The Company establishes business relationship and executes transactions only upon identifying the client
                and verifying his or her identity (hereinafter: identification) and client due diligence. The client
                on-boarding procedure has to be conducted with the utmost care and vigilance. The on-boarding procedure
                shall follow the rules on the identification and due diligence, which consist also of the collection of
                information, data and documents.
            </div>

            <div className={s.point_text}>
                The Company reserves the right to copy the personal documents based on which the customer was identified
                and had their identity verified.
            </div>

            <div className={s.point_text}>
                While performing the identification and client due diligence process, the Company may request the client
                to provide also additional documentation in order to ensure a better knowledge of the client. Shall the
                Company request the client to provide additional documentation and he or she fails to do so, the Company
                may reject the establishment of the business relationship; in case of existing business relationships,
                the Company may terminate the business relationship unilaterally.
            </div>

            <div className={s.point_text}>
                We can use any of the 5 methods described below to identify a person:
                <ul>
                    <li>
                        Government-issued photo identification method
                    </li>
                    <li>
                        Credit file method
                    </li>
                    <li>
                        Dual-process method
                    </li>
                    <li>
                        Affiliate or member method
                    </li>
                    <li>
                        Reliance method
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                The Company may collect the following data when establishing business relationships with natural
                persons:
                <ul>
                    <li>
                        name
                    </li>
                    <li>
                        permanent and temporary address
                    </li>
                    <li>
                        nationality
                    </li>
                    <li>
                        date and place of birth
                    </li>
                    <li>
                        political exposure status
                    </li>
                    <li>
                        tax identification number
                    </li>
                    <li>
                        number, type and issuer of the personal identification document
                    </li>
                    <li>
                        information on the validity of the personal identification document
                    </li>
                    <li>
                        the jurisdiction (province or state) and country of issue of the document
                    </li>
                    <li>
                        the expiry date of the document, if available
                    </li>
                    <li>
                        information on the purpose of the business relationship and the foreseen nature of the business
                        relationship
                    </li>
                    <li>
                        information on the status of the natural person or business activity of his or her employer;
                    </li>
                    <li>
                        other information according to Company’s policies and applicable law
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Before establishing a business relationship with a legal person or other persons the Company may collect
                the following data:
                <ul>
                    <li>
                        excerpt from the legal register or other comparable register;
                    </li>
                    <li>
                        entity’s name;
                    </li>
                    <li>
                        address;
                    </li>
                    <li>
                        place of seat;
                    </li>
                    <li>
                        registration number;
                    </li>
                    <li>
                        tax identification number
                    </li>
                    <li>
                        information on the business activity of the legal person;
                    </li>
                    <li>
                        information on the purpose and foreseen nature of a business relationship;
                    </li>
                    <li>
                        data on the ultimate beneficial owner,
                    </li>
                    <li>
                        other information according to Company’s policies and applicable law.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                The Company may collect the following data on the legal representative, the authorised person and the
                ultimate beneficial owner of the legal person:
                <ul>
                    <li>
                        personal name,
                    </li>
                    <li>
                        permanent and temporary address,
                    </li>
                    <li>
                        nationality,
                    </li>
                    <li>
                        date and place of birth,
                    </li>
                    <li>
                        political exposure status,
                    </li>
                    <li>
                        tax identification number
                    </li>
                    <li>
                        number, type and name of the issuer of the personal identification document,
                    </li>
                    <li>
                        information on the validity of the personal identification document,
                    </li>
                    <li>
                        the jurisdiction (province or state) and country of issue of the document,
                    </li>
                    <li>
                        the expiry date of the document, if available,
                    </li>
                    <li>
                        other information according to Company’s policies and applicable law.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                The Company may collect other information about the clients with accordance to its policies and
                applicable law.
            </div>

            <div className={s.point_title}>
                6. Further obligations for client identifications and due diligence procedures
            </div>

            <div className={s.point_text}>
                In addition to the principles described hereinabove, the Company shall:
                <ul>
                    <li>
                        Ensure that the client identification records remain completely updated with all relevant
                        identification data and information throughout the business relationshipю
                    </li>
                    <li>
                        Examine and check, on a regular basis, the validity and adequacy of the client identification
                        data and information it maintains, especially those concerning high risk clients.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Despite the obligations described above and while taking into consideration the level of risk, if at any
                time during the business relationship, the Company becomes aware that reliable or adequate data and
                information are missing from the identity and the economic profile of the client, then the Company shall
                take all necessary action, by applying the client identification and due diligence procedures, to
                collect the missing data and information, the soonest possible, so as to identify the Client and update
                and complete the client’s economic profile.
            </div>

            <div className={s.point_text}>
                In addition to the obligations set forth above, the Company shall check the adequacy of the data and
                information of the client’s identity, whenever one of the following events or incidents occurs:
                <ul>
                    <li>
                        an important transaction takes place which appears to be unusual and/or significant compared
                        to the normal pattern of transactions of the client;
                    </li>
                    <li>
                        a material change in the client’s legal status and situation occurs, such as: change of
                        directors/secretary; change of registered shareholders and/or beneficial owners; change of
                        registered office; change of corporate name and/or trading name; change of the principal trading
                        partners and/or undertaking of major new business activities etc.;
                    </li>
                    <li>
                        a change in the persons that are authorized to operate the account;
                    </li>
                    <li>
                        application for the provision of new services;
                    </li>
                    <li>
                        other events according to Company’s policy and applicable law.
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default EighthPannel;
