import React, {useEffect, useState} from 'react';
import CardsStepOne from "./CardsStepOne/CardsStepOne";
import CardsStepThree from "./CardsStepThree/CardsStepThree";
import CardsStepFour from "./CardsStepFour/CardsStepFour";
import CardsStepTwo from "./CardsStepTwo/CardsStepTwo";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCardsInfo} from "../../../store/reducers/ActionCreators";
import CardsStepFive from './CardsStepFive/CardsStepFive';

const Cards = () => {
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const userCards: any[any] = useAppSelector(store => store.userReducer.cardInfo)

    useEffect(() => {
        dispatch(getCardsInfo(userToken))
    }, [dispatch, userToken])

    const hasExistentCard = userCards?.length >= 1;
    // const hasCardNumber = (userCards.length >= 1) && !!userCards[0].san;
    const hasCardNumber = userCards?.length >= 1 && userCards.some((card: any) => !!card?.san);
    const isRejected = userCards?.length >= 1 && userCards.every((card: any) => card.status === 9);
    
    const [current, setCurrent] = useState(
        isRejected ? 4 :
            hasCardNumber ? 2 :
                hasExistentCard ? 1 :
                    0
    );

    const [selectedCard, setSelectedCard]: any[any] = useState([]);

    const steps = [{
        content: <CardsStepOne setCurrent={setCurrent}
                               current={current}
        />
    }, {
        content: <CardsStepTwo setCurrent={setCurrent}
                               current={current}
        />
    }, {
        content: <CardsStepThree setCurrent={setCurrent}
                                 current={current}
                                 setSelectedCard={setSelectedCard}
        />
    }, {
        content: <CardsStepFour setCurrent={setCurrent}
                                current={current}
                                selectedCard={selectedCard}
        />
    }, {
        content: <CardsStepFive setCurrent={setCurrent}
                                current={current}
        />
    },];

    return (<div>{steps[current].content}</div>);
};

export default Cards;