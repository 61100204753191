import React from 'react';
import s from './ServicesHeaderBlock.module.css'
import MyBtn from "../ui/MyBtn/MyBtn";
import {useNavigate} from "react-router-dom";

type PropsType = {
    title: string,
    imageSrc: string
}

const ServicesHeaderBlock = (props: PropsType) => {

    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>
            <div className={s.title_block}>
                <div className={s.title}>
                    Services
                </div>
                <div className={s.subtitle}>
                    {props.title}
                </div>
                <div className={s.button_wrapper}>
                    <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                </div>
            </div>
            <div className={s.image_block}>
                <img src={props.imageSrc} alt=""/>
            </div>
        </div>
    );
};

export default ServicesHeaderBlock;
