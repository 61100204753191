import React, {Dispatch, SetStateAction, useState} from 'react';
import {useField, useFormikContext} from "formik";
import s from "./CustomDropdownForAccounts.module.css";
import selectArrow from "../../../assets/newImg/select_arrow.svg";
import classnames from "classnames";

type PropsType = {
    items: any
    isError?: string | undefined
    setCountryError?: any
    placeholder?: string
    id: string
    name?: any
    touched?: any
    customId?: string
    setCurrency?: Dispatch<SetStateAction<string>>
    disableZeroBalance?: boolean
}

const CustomDropdownForAccounts = (props: PropsType) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const {setFieldValue} = useFormikContext();
    const [field] = useField<string>(props.name);

    if (isDropdownVisible) {
        const myPopup: any = document.querySelector(`#${props.id}`);
        document.addEventListener('click', (e) => {
            const withinBoundaries = e.composedPath().includes(myPopup);

            if (!isDropdownVisible) return

            if (!withinBoundaries) {
                setIsDropdownVisible(false)
            }
        })
    }

    return (
        <div className={s.phoneInputWrapper} id={props.id}>
            <div className={s.mySelect} onClick={(e) => {
                e.stopPropagation()
                setIsDropdownVisible(prevState => !prevState)
            }}>
                <div className={s.selectedBlock}>
                    <img className={isDropdownVisible ? s.arrowReverse : s.arrow} width={9} height={5}
                         src={selectArrow} alt=""/>
                </div>
                <div className={isDropdownVisible ? s.optionsWrapper : s.none}>
                    {
                        props?.items?.map((item: any, index: number) => (
                            <div
                                className={classnames(s.option, props.disableZeroBalance && item.balance === 0 && s.disabledOption)}
                                key={index}
                                onClick={() => {
                                    if ((props.disableZeroBalance && item.balance !== 0) || !props.disableZeroBalance) {
                                        if (props.name != null) {
                                            setFieldValue(props.name, item.number)
                                            if (props.setCurrency) {
                                                props.setCurrency(item.currency)
                                            }
                                        }
                                    }
                                }}
                            >
                                <div className={s.countryName}>
                                    {item.number} {item.balance} {item.currency}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <input
                className={classnames(s.my_input, props.isError && props.touched && s.error)}
                id={props.id}
                name={props.name}
                type="text"
                defaultValue={field.value}
                placeholder={props.placeholder}
            />
        </div>
    );
};

export default CustomDropdownForAccounts;
