import React, {Dispatch, SetStateAction, useEffect} from 'react';
import s from "./KycPersonalStepOne.module.css";
import personalKyc from "../../../../../../assets/newImg/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import classnames from "classnames";
import {deleteKycThunk, getKycListThunk} from "../../../../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {motion} from 'framer-motion';

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const KycPersonalStepOne = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const newKycList = useAppSelector(state => state.userReducer.user.kycList)

    useEffect(() => {
        dispatch(getKycListThunk(token))
    }, [dispatch, token])

    const deleteKyc = (id: any) => {
        dispatch(deleteKycThunk(token, id))
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            {newKycList?.length > 0
                ? <></>
                : <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>KYC for Personal Account</div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={"Verification"} onClick={() => props.setCurrent(1)}/>
                    </div>
                </div>
            }

            {newKycList?.length > 0 &&
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>
                        Questionnaire List
                    </div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div className={s.header_value}>
                                Owner
                            </div>
                            <div className={s.header_value}>
                                State
                            </div>
                            {   // @ts-ignore
                                newKycList[0].status !== "Confirmed" &&
                                    <div className={s.header_value}>
                                        Action
                                    </div>
                            }
                        </div>
                        <div className={s.table_body}>
                            {
                                newKycList?.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className={s.table_row}>
                                            <div className={s.row_value}>
                                                <div className={s.name}>
                                                    {item.owner}
                                                </div>
                                            </div>
                                            <div className={s.row_value}>
                                                {item.status === 'Rejected' &&
                                                    <div className={s.rejected}>Rejected</div>}
                                                {item.status === 'Confirmed' &&
                                                    <div className={s.confirmed}>Confirmed</div>}
                                                {item.status === 'Pending' && <div className={s.pending}>Pending</div>}
                                            </div>

                                            {
                                                item.status === 'Rejected'
                                                && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                    <div className={classnames(s.flex, s.action)}
                                                         onClick={() => props.setCurrent(2)}>
                                                        <div className={s.edit_active}></div>
                                                        <div className={s.edit_text_active}>Edit</div>
                                                    </div>
                                                    <div className={classnames(s.flex, s.action)}
                                                         onClick={() => deleteKyc(item.id)}>
                                                        <div className={s.delete_active}></div>
                                                        <div className={s.delete_text_active}>Delete</div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                item.status === 'Confirmed'
                                                && <div></div>
                                            }

                                            {
                                                item.status === 'Pending'
                                                && <div className={classnames(s.row_value, s.flex)}>
                                                    <div className={classnames(s.flex, s.action)}
                                                         onClick={() => deleteKyc(item.id)}>
                                                        <div className={s.delete_active}></div>
                                                        <div className={s.delete_text_active}>Delete</div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={s.questionnaire_table_mobile}>

                        {
                            newKycList?.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className={s.row_mobile}>
                                            <div className={s.block}>
                                                <div className={s.label}>
                                                    OWNER
                                                </div>
                                                <div className={s.name}>
                                                    {item.owner}
                                                </div>
                                            </div>
                                            <div className={s.block}>
                                                <div className={s.label}>
                                                    State
                                                </div>
                                                <div className={s.row_value}>
                                                    {item.status === 'Rejected' &&
                                                        <div className={s.rejected}>Rejected</div>}
                                                    {item.status === 'Confirmed' &&
                                                        <div className={s.confirmed}>Confirmed</div>}
                                                    {item.status === 'Pending' &&
                                                        <div className={s.pending}>Pending</div>}
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            item.status === 'Rejected'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => props.setCurrent(2)}>
                                                    <div className={s.edit_active}></div>
                                                    <div className={s.edit_text_active}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => deleteKyc(item.id)}>
                                                    <div className={s.delete_active}></div>
                                                    <div className={s.delete_text_active}>Delete</div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.status === 'Confirmed'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.edit}></div>
                                                    <div className={s.edit_text_inactive}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.delete}></div>
                                                    <div className={s.delete_text_inactive}>Delete</div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.status === 'Pending'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.edit}></div>
                                                    <div className={s.edit_text_inactive}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => deleteKyc(item.id)}>
                                                    <div className={s.delete_active}></div>
                                                    <div className={s.delete_text_active}>Delete</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            }

        </motion.div>
    );
};

export default KycPersonalStepOne;
