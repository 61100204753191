import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./CardTransactions.module.css";
import {ConfigProvider, DatePicker} from "antd";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {myDate} from "../../../helpers/CalculateDate";
import Table, {ColumnsType} from "antd/es/table";
import {getCardTransactionList} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {Loader} from "../../Loader/Loader";
import CustomDropdown from '../../ui/CustomDropdown/CustomDropdown';

dayjs.extend(weekday)
dayjs.extend(localeData)

const dateFormat = 'MMMM YYYY';

const columns: ColumnsType<any> = [{
    title: 'date', dataIndex: 'date',
}, {
    title: 'Description', dataIndex: 'description',
}, {
    title: 'amount', dataIndex: 'amount', align: "right"
}, {
    title: 'Currency', dataIndex: 'currency', align: "right"
}];


const CardTransactions = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })

    const formattedDate = dayjs(date.from).format('MM-YYYY');
    const [month, year] = formattedDate.split("-");

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "46%" : 220,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const transactions = useAppSelector(store => store.cardTransactionsReducer.cardTransactionsData)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const userCards: any[any] = useAppSelector(store => store.userReducer.cardInfo)
    const [selectedCard, setSelectedCard] = useState('');
    const cardNumbers: any[''] = userCards.map((card: any) => card.cardNumber);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const cardSanLookup = userCards.reduce((lookup: any, card: any) => {
        lookup[card.cardNumber] = card.san;
        return lookup;
    }, {});

    const getSanByCardNumber = (cardNumber: any) => (!cardNumber || String(cardNumber).toLowerCase() === 'all') ? 'all' : cardSanLookup[cardNumber];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(getCardTransactionList(userToken, getSanByCardNumber(selectedCard), year, month))
    }, [year, month, dispatch, userToken, selectedCard])
    /* eslint-enable react-hooks/exhaustive-deps */

    const tableData = transactions?.map((item: any) => {
        return {
            key: item.Date, date: item.Date, amount: item.Amount, description: item.Desciption, currency: item.Currency
        };
    });


    return (<>
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: 'var(--primary-color)', fontWeightStrong: 600, colorLink: 'red'
                    }
                }, token: {
                    colorPrimary: 'var(--primary-color)', colorBorder: '#EEEFEF', fontFamily: 'Inter',
                },
            }}
        >
            <Formik
                initialValues={{
                    from: '',
                    card: cardNumbers.length === 1 ? cardNumbers[0] : selectedCard
                }}
                onSubmit={(values) => {
                    dispatch(setDisabled(true))
                    dispatch(getCardTransactionList(userToken, getSanByCardNumber(values.card), year, month))
                        .then((res) => {
                            console.log('success', res)
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            dispatch(setDisabled(false))
                        })

                }}
            >
                {({
                      values, handleChange, handleBlur, handleSubmit
                  }) => (<form className={s.form} onSubmit={handleSubmit}>
                    <div className={s.periodRowContainer}>
                        <div className={s.periodRow}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Period</p>
                                <DatePicker
                                    suffixIcon={null}
                                    style={dateStyle}
                                    picker="month"
                                    value={dayjs(date.from)}
                                    format={dateFormat}
                                    onChange={(_, dateString) => {
                                        if (!dateString) {
                                            setDate({ ...date, from: new Date().toDateString() })
                                        } else {
                                            setDate({ ...date, from: dateString })
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className={s.periodRow}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Card</p>
                                <CustomDropdown
                                    items={['ALL', ...cardNumbers]}
                                    id={"card"}
                                    name={"card"}
                                    disable={cardNumbers.length === 1}
                                    placeholder={cardNumbers.length === 1 ? cardNumbers[0] : ''}
                                    onChange={(val) => val && setSelectedCard(val)}
                                />
                            </div>
                        </div>
                    </div>
                </form>)}
            </Formik>
        </ConfigProvider>

        <div className={s.tableWrapper}>
            {isLoading ? <Loader/> : isMobile ? <div className={s.transactionsListMobile}>
                {transactions?.map((item: any) => <div key={item.date}
                                                      className={s.transfer}>

                    <div className={s.mobileTransferRow}>
                        <div className={s.mobileTransferBlock}>
                            <div className={s.blockTitle}><span>Date</span></div>
                            <p className={s.dateCellP}>{item.Date}</p>
                        </div>
                    </div>

                    <div className={s.mobileTransferRow}>
                        <div className={s.mobileTransferBlock}>
                            <div className={s.blockTitle}><span>Description</span></div>
                            <p className={s.dateCellP}>{item.Desciption}</p>
                        </div>
                    </div>

                    <div className={s.mobileTransferRow}>
                        <div className={s.mobileTransferBlock}>
                            <div className={s.blockTitle}><span>Amount</span></div>
                            <p>
                                {item.Amount + ' ' + item.Currency}
                            </p>
                        </div>
                    </div>

                </div>)}

            </div> : <ConfigProvider theme={{
                components: {
                    Pagination: {
                        colorPrimary: '#FFFFFF',
                        colorPrimaryHover: '#FFFFFF',
                        colorBgContainer: 'var(--primary-color)',
                        colorLink: '#FFFFFF'
                    },
                },
            }}>
                <Table
                    bordered={false}
                    style={{marginTop: 48}}
                    rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                    className={s.statementTable}
                    columns={columns}
                    pagination={{position: ['bottomCenter'], defaultPageSize: 15}}
                    dataSource={tableData}
                />
            </ConfigProvider>}
        </div>
    </>);
};

export default CardTransactions;