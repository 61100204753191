import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";

interface FormikProps {
    values: {
        name?: string;
        recipientsIban?: string;
    };
    errors: {
        name?: string;
        recipientsIban?: string;
    };
    touched: {
        name?: boolean;
        recipientsIban?: boolean;
    };
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const EurBank: React.FC<FormikProps> = ({
                                                                 values,
                                                                 errors,
                                                                 touched,
                                                                 handleChange,
                                                             }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>Recipient’s Details</div>
            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* COMPANY NAME</div>
                    <MyInput
                        id="name"
                        name="name"
                        touched={touched.name}
                        value={values.name}
                        onChange={handleChange}
                        isError={errors.name}
                    />
                    {errors.name && touched.name && <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* IBAN</div>
                    <MyInput
                        id="recipientsIban"
                        name="recipientsIban"
                        touched={touched.recipientsIban}
                        value={values.recipientsIban}
                        onChange={handleChange}
                        isError={errors.recipientsIban}
                    />
                    {errors.recipientsIban && touched.recipientsIban && (
                        <div className={s.error_message}>{errors.recipientsIban}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EurBank;
