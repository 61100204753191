import { IPhoneNumber } from "../types/interfaces";

export const identityCardTypes = ["Passport", "National ID", "Driver’s License"];
export const dateFormat = 'YYYY-MM-DD';
export const getPhoneNumbersArr = (initPhoneNumbers: any): string[] => {
    const phonesArr: string[] | null = [];
    initPhoneNumbers?.forEach((item: IPhoneNumber) => {
        if (item.phoneNumber != null) {
            phonesArr.push(item.phoneNumber);
        }
    });
    return phonesArr;
}