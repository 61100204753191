import icon from "../assets/newImg/Logo.svg";
import logo from "../assets/newImg/personalAccount/logo.svg";

interface BrandTheme {
    brandIcon: string;
    brandLogo: string;
    brandLogoWidth: string;
    brandLogoHeight: string;
    brandLogoAlt: string;
}

export const brandConfig: any = {
    companyName: process.env.REACT_APP_COMPANY_NAME, // Company Full Name
    companyNameSimple: process.env.REACT_APP_COMPANY_NAME_SIMPLE, // Company Name without type (Example: Iberbanco LTD ---> Iberbanco)
    companyWebsite: process.env.REACT_APP_COMPANY_WEBSITE, // Company Website URL
    companyMainEmailAddress: process.env.REACT_APP_COMPANY_MAIN_EMAIL_ADDRESS, // Company Main Email Address
    companyInfoEmailAddress: process.env.REACT_APP_COMPANY_INFO_EMAIL_ADDRESS, // Company Info Email Address
    companyComplianceEmailAddress: process.env.REACT_APP_COMPANY_COMPLIANCE_EMAIL_ADDRESS, // Company Compliance Email Address
    companySupportEmailAddress: process.env.REACT_APP_COMPANY_SUPPORT_EMAIL_ADDRESS, // Company Support Email Address
    companyLegalEmailAddress: process.env.REACT_APP_COMPANY_LEGAL_EMAIL_ADDRESS // Company Legal Email Address
};

export const brandLinks: any = {
    facebook: process.env.REACT_APP_FACEBOOK_URL, // Company Facebook Address
    linkedin: process.env.REACT_APP_LINKEDIN_URL, // Company Linkedin Address
    whatsapp: process.env.REACT_APP_WHATSAPP_URL, // Company Whatsapp Address

};

export const companyPolicies = [
    {
      label: 'Legal & Privacy',
      href: process.env.REACT_APP_LEGAL_AND_PRIVACY // Company Legal and Privacy Link
    },
    {
      label: 'Terms of Use',
      href: process.env.REACT_APP_TERMS_OF_USE // Company Terms of Use Link
    },
    {
      label: 'Privacy Policy',
      href: process.env.REACT_APP_PRIVACY_POLICY // Company Privacy Policy Link
    }
].filter(policy => policy.href);

export const brandTheme: BrandTheme = {
    brandIcon: icon,
    brandLogo: logo,
    brandLogoWidth: 'auto',
    brandLogoHeight: '40px',
    brandLogoAlt: 'Brand Logo',
};

export const appConfig: any = {
    reCaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
};
export const siteConfig: any = {
    maxCardTopUpLimit: 5000, // The maximum allowed amount of currency for card Top-Up.
};

export default brandConfig;
