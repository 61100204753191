import React from 'react';
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import s from "./AgentProgram.module.css";
import styles from "../../../styles/styles.module.css";
import {motion} from 'framer-motion';
import {brandConfig} from '../../../config/config';

const AgentProgram = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.wrapper}>
                <div className={styles.container}>

                    <div className={s.main_block}>
                        In addition to our main services and products, we offer an <span className={s.color}>Agent Program</span> to the interested parties who can attract more customers to {brandConfig.companyNameSimple}
                    </div>

                    <div className={s.description_wrapper}>
                        <div className={s.description}>
                            We are signing an Agent agreement and sharing the revenue generated from the attracted customer with the Agent
                        </div>
                    </div>

                    <ContactUsForm/>
                </div>
            </div>
        </motion.div>
    );
};

export default AgentProgram;
