import React, { useState } from "react";
import KycBusinessStepOne from "./KycBusinessStepOne/KycBusinessStepOne";
import GenerateKycForm from "../../../../../components/GenerateKycForm/GenerateKycForm";

const KycBusiness = () => {
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            content: (
                <KycBusinessStepOne setCurrent={setCurrent} current={current} />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="business"
                    action="create"
                    setCurrent={setCurrent}
                    title={"KYC for Business Accounts"}
                />
            ),
        },
        {
            content: (
                <GenerateKycForm
                    type="business"
                    action="edit"
                    setCurrent={setCurrent}
                    title={"Edit KYC Business Accounts"}
                />
            ),
        },
    ];

    return <div>{steps[current].content}</div>;
};

export default KycBusiness;
