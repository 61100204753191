import React, {useEffect, useState} from 'react';
import s from './App.module.css';
import './styles/globals.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import PersonalAccount from "./pages/PersonalAccount/PersonalAccount";
import Accounts from "./pages/PersonalAccount/Accounts/Accounts";
import Transfers from "./pages/PersonalAccount/Transfers/Transfers";
import Currency from "./pages/PersonalAccount/Currency/Currency";
import LoginizationSteps from "./pages/LoginizationSteps/LoginizationSteps";
import Templates from "./pages/PersonalAccount/Templates/Templates";
import SettingsPersonal from "./pages/PersonalAccount/SettingsPersonal/Settings/Settings";
import SettingsBusiness from "./pages/PersonalAccount/SettingsBusiness/Settings/Settings";
import Security from "./pages/PersonalAccount/Security/Security";
import CreateTemplate from "./pages/PersonalAccount/Templates/CreateTemplate/CreateTemplate";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {userSlice} from "./store/reducers/UserSlice";
import Home from "./pages/PublicPages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {publicRoutes, ServicesPublicRoutes} from "./Constants/PublicRoutes";
import LegalAndPrivacy from "./pages/PublicPages/LegalAndPrivacy/LegalAndPrivacy";
import {LEGAL_AND_PRIVACY} from "./Constants/RoutesConstants";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import PersonalAccountHeader from "./components/PersonalAccountHeader/PersonalAccountHeader";
import RegistrationSteps from "./pages/RegistrationSteps/RegistrationSteps";
import Cards from "./pages/PersonalAccount/Cards/Cards";
import { brandConfig } from './config/config';
import Dashboard from './pages/PersonalAccount/Dashboard/Dashboard';

function App() {
    document.title = brandConfig.companyName.toUpperCase();
    const {pathname} = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const dispatch = useAppDispatch()
    const setDisabled = userSlice.actions.setDisabled
    const user = useAppSelector(state => state.userReducer.user.userToken)

    useEffect(() => {
        dispatch(setDisabled(false))
        localStorage.clear()
    }, [dispatch, setDisabled])

    useEffect(() => {
        if (pathname.includes("personal_account")) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, [pathname])

    const ProtectedRoute = (props: any) => {
        if (!props.user) {
            return <Navigate to={props.redirectPath} replace/>;
        }
        return props.children;
    };

    return (
        <div className={s.app}>

            <div className={s.desktop_header}>
                <Header/>
            </div>

            {
                isLoggedIn ? <div className={s.mobile_header}>
                    <PersonalAccountHeader/>
                </div> : <div className={s.mobile_header}>
                    <MobileHeader/>
                </div>
            }

            <Routes>
                <Route 
                    path="/" 
                    element={isLoggedIn ? <Navigate to="/personal_account/dashboard" replace /> : <Home />} 
                />
                {
                    publicRoutes.map((route: any) => {
                        return (
                            <Route key={route.path} path={route.path} element={route.element}/>
                        )
                    })
                }

                <Route path={LEGAL_AND_PRIVACY} element={<LegalAndPrivacy/>}/>

                {
                    ServicesPublicRoutes.map((route: any) => {
                        return (
                            <Route key={route.path} path={route.path} element={route.element}/>
                        )
                    })
                }
                <Route path={'/login'} element={<LoginizationSteps/>}/>
                <Route path={'/sign_up'} element={<RegistrationSteps/>}/>

                <Route path={'/personal_account'} element={
                    <ProtectedRoute redirectPath={'/login'} user={user}>
                        <PersonalAccount/>
                     </ProtectedRoute>
                }>
                    <Route path={"dashboard"} element={<Dashboard/>}/>
                    <Route path={"accounts"} element={<Accounts/>}/>
                    <Route path={'templates'}>
                        <Route index element={<Templates/>}/>
                        <Route path={"new-template/:id/:type"} element={<CreateTemplate/>}/>
                    </Route>
                    <Route path={'cards'} element={<Cards/>}>
                    </Route>
                    <Route path={'transfers'} element={<Transfers/>}/>
                    <Route path={'currency'} element={<Currency/>}/>
                    <Route path={'security'} element={<Security/>}/>

                    <Route path={'personal'}>
                        <Route path={'settings'} element={<SettingsPersonal/>}/>
                    </Route>

                    <Route path={'business'}>
                        <Route path={'settings'} element={<SettingsBusiness/>}/>
                    </Route>
                </Route>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
