import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {bankAccountTypesUS} from "../../../Constants/StaticData";

interface FormikProps {
    values: {
        name?: string;
        bankAccount?: string;
        bankAccountType?: {
            name: string,
            value: string
        };
        recipientsBank?: string;
        routingNumber?: string;
        recipientsSwiftBic?: string;

    };
    errors: {
        name?: string;
        bankAccount?: string;
        bankAccountType?: {
            name?: string,
            value?: string
        };
        recipientsBank?: string;
        routingNumber?: string;
        recipientsSwiftBic?: string;

    };
    touched: {
        name?: boolean;
        bankAccount?: boolean;
        bankAccountType?: {
            name?: boolean,
            value?: boolean
        };
        recipientsBank?: boolean;
        routingNumber?: boolean;
        recipientsSwiftBic?: boolean;

    };
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const UsdBank: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                        }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK ACCOUNT
                        </div>
                        <MyInput id={"bankAccount"}
                                 name={"bankAccount"}
                                 touched={touched.bankAccount}
                                 value={values.bankAccount}
                                 onChange={handleChange}
                                 isError={errors.bankAccount}
                        />
                        {errors.bankAccount && touched.bankAccount &&
                            <div className={s.error_message}>{errors.bankAccount}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK ACCOUNT TYPE
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"bankAccountType"}
                            name={"bankAccountType"}
                            touched={touched.bankAccountType}
                            isError={errors?.bankAccountType?.name}
                            items={bankAccountTypesUS}
                        />
                        {errors.bankAccountType && touched.bankAccountType &&
                            <div
                                className={s.error_message}>{errors?.bankAccountType?.name}</div>}
                    </div>
                </div>

                <div className={s.input_block}>

                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK NAME
                        </div>
                        <MyInput id={"recipientsBank"}
                                 name={"recipientsBank"}
                                 touched={touched.recipientsBank}
                                 value={values.recipientsBank}
                                 onChange={handleChange}
                                 isError={errors.recipientsBank}
                        />
                        {errors.recipientsBank && touched.recipientsBank &&
                            <div className={s.error_message}>{errors.recipientsBank}</div>}
                    </div>

                </div>
            </div>


            <div className={s.row}>
                <div className={s.input_block}>

                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * ROUTING NUMBER
                        </div>
                        <MyInput id={"routingNumber"}
                                 name={"routingNumber"}
                                 touched={touched.routingNumber}
                                 value={values.routingNumber}
                                 onChange={handleChange}
                                 isError={errors.routingNumber}
                                 placeholder={"9 digits"}
                        />
                        {errors.routingNumber && touched.routingNumber &&
                            <div className={s.error_message}>{errors.routingNumber}</div>}
                    </div>

                </div>

                <div className={s.input_block}>

                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * SWIFT CODE
                        </div>
                        <MyInput id={"recipientsSwiftBic"}
                                 name={"recipientsSwiftBic"}
                                 touched={touched.recipientsSwiftBic}
                                 value={values.recipientsSwiftBic}
                                 onChange={handleChange}
                                 isError={errors.recipientsSwiftBic}
                        />
                        {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                            <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                    </div>

                </div>
            </div>

        </div>
    );
};

export default UsdBank;
