import React from 'react';
import {Select} from 'antd';
import s from './SelectWithSearch.module.css'
import {useField, useFormikContext} from "formik";
import classnames from "classnames";
import './index.css'

type PropsType = {
    items: string[]
    isError?: string | undefined
    setCountryError?: any
    placeholder?: string
    id?: string
    name?: any
    touched?: any
    customId?: string
    setItem?: any
    onChange?: (selectedValue: string) => void;
    resetAnotherField?: any,
}

const SelectWithSearch = (props: PropsType ) => {

    const {setFieldValue} = useFormikContext();
    const [field] = useField<string>(props.name);

    const onChange = (value: string) => {
        if (props.resetAnotherField) {
            setFieldValue('paymentTypeByRegionForm', '');
        }
        setFieldValue(field.name, value);
    };
    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const modifyItems = props?.items?.map((country) => {
        return {value: country, label: country}
    })

    return (
        <div className={s.select_wrapper}>
            <Select
                value={field.value}
                showSearch
                placeholder=""
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={modifyItems}
                className={classnames(s.my_input ,props.touched && props.isError && s.error_border)}
                popupClassName={s.my_dropdown}
            />
        </div>
    )
};

export default SelectWithSearch;
